import { Button, IconButton, InputAdornment, Stack } from "@mui/material";
import React from "react";
import InputField from "../custom-inputs/input-field";
import { Close, Search } from "@mui/icons-material";

const SearchInput = ({
  value,
  onChange,
  setSearchCalled,
  onRequestSearch,
  onRequestClear,
}) => {
  return (
    <Stack direction="row" gap={2} alignItems={"center"}>
      <InputField
        label="Search"
        type="text"
        value={value}
        onChange={onChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                size="small"
                sx={{ opacity: !!value ? 1 : 0 }}
                color="error"
                onClick={onRequestClear}
              >
                <Close />
              </IconButton>
              <IconButton
                size="small"
                color="primary"
                onClick={onRequestSearch}
              >
                <Search />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
};

export default SearchInput;
