import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Stack,
  Button,
  Divider,
} from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";

import { InputField } from "../../../../component";
import { handleLoader, setToast } from "../../../../store/reducer";
import errorsSetter from "../../../../helpers/error-setter";
import ApiManager from "../../../../services/api-manager";
import usePageTitle from "../../../../hooks/use-page-title";
import Utils from "../../../../utils/utils";

const SpCreateCurrency = () => {
  const currencyID = useParams()?.id;
  usePageTitle(currencyID ? "Update" : "Add" + " Currency");
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const goBack = () => navigate("/admin/currency");
  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));

  useEffect(() => {
    async function init() {
      if (currencyID) {
        try {
          dispatch(handleLoader(true));
          let { data } = await ApiManager(
            "get",
            `superAdmin/currencies/${currencyID}`,
          );
          setFormData({
            currency_name: data?.currency_name,
            currency_code: data?.currency_code,
            currency_symbol: data?.currency_symbol,
            status: data?.status,
          });
        } catch (error) {
          showMessage("error", error?.response?.data?.error?.message);
        } finally {
          dispatch(handleLoader(false));
        }
      }
    }
    init();
  }, [currencyID]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const validator = () => {
    let flag = false;
    let error = {};
    if (!Utils.hasOnlyNonNumericCharacters(formData.currency_name)) {
      error.currency_name = "Currency name must only contain alphabets";
      flag = true;
    }
    if (!Utils.hasOnlyNonNumericCharacters(formData.currency_code)) {
      error.currency_code = "Currency code must only contain alphabets";
      flag = true;
    }
    setFormErrors((prev) => ({ ...prev, ...error }));
    return flag;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validator()) return;
    try {
      dispatch(handleLoader(true));
      let _url = currencyID
        ? `superAdmin/currencies/${currencyID}`
        : "superAdmin/currencies";
      let fd = { ...formData };
      if (!!currencyID) {
        fd._method = "patch";
        fd.currency_id = currencyID;
      }
      await ApiManager("post", _url, fd);
      showMessage(
        "success",
        `Currency ${!!currencyID ? "updated" : "added"} successfully.`,
      );
      goBack();
    } catch (error) {
      if (error?.response?.status === 422) {
        setFormErrors(errorsSetter(error));
      } else {
        showMessage("error", error?.response?.data?.error?.message);
      }
    } finally {
      dispatch(handleLoader(false));
    }
  };

  const formInputs = () => {
    return (
      <Grid container columnSpacing={3} mt={2} rowSpacing={2}>
        <Grid item sm={4} xs={12}>
          <InputField
            label="Name"
            name="currency_name"
            required
            error={formErrors?.currency_name}
            value={formData?.currency_name}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <InputField
            label="Code"
            name="currency_code"
            required
            error={formErrors?.currency_code}
            value={formData?.currency_code}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <InputField
            label="Symbol"
            name="currency_symbol"
            required
            error={formErrors?.currency_symbol}
            value={formData?.currency_symbol}
            onChange={handleInputChange}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Box m={{ sm: 2 }}>
      <Box
        component={Paper}
        elevation={6}
        sx={{
          p: 2,
        }}
      >
        <Box
          component="form"
          autoCapitalize="off"
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <Typography variant="h5">
            {currencyID ? "Update" : "Add"} Currency
          </Typography>
          {formInputs()}
          <Box mt={2}>
            <Divider />
          </Box>
          <Stack direction="row" gap={2} mt={2}>
            <Button
              variant="contained"
              sx={{
                color: "white",
              }}
              type="submit"
            >
              Submit
            </Button>
            <Button variant="contained" color="error" onClick={goBack}>
              Cancel
            </Button>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default SpCreateCurrency;
