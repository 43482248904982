import React, { useEffect, useState } from "react";
import { Box, Typography, TableCell, Fab } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import {
  StyledTableCell,
  StyledTableRow,
  TableWrapper,
} from "../../../../component";
import ApiManager from "../../../../services/api-manager";
import usePageTitle from "../../../../hooks/use-page-title";
import Utils from "../../../../utils/utils";

const AttendeeTopupReport = () => {
  usePageTitle("Attendee Topup Report");
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState(null);
  const { id, ticketID } = useParams();
  const navigate = useNavigate();
  const { user, loggedEvent } = useSelector((state) => state.storeReducer);
  const _role = user?.role === "super_admin" ? "superAdmin" : "organizer";
  const _symbol = loggedEvent.currencySymbol;

  useEffect(() => {
    getData();
  }, [id]);

  const getData = async (_search = "") => {
    try {
      setIsLoading(true);
      let { data } = await ApiManager(
        "get",
        `${_role}/attendee-topups/${ticketID}`,
      );
      console.log("showing data: ", data);
      setRecords(data);
    } catch (error) {
      console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Box>
        <Fab
          size="small"
          onClick={() =>
            navigate(`/event/${id}/management/lead-capture/attendee-report`)
          }
        >
          <ArrowBackIcon />
        </Fab>
        <Typography variant="h4" color="initial" fontWeight={"bold"} m={2}>
          Top-ups
        </Typography>

        <TableWrapper
          spanTd={thLabels.length}
          isLoading={isLoading}
          isContent={records?.length || false}
          thContent={
            <>
              {thLabels.map((v) => (
                <TableCell key={v}>{v}</TableCell>
              ))}
            </>
          }
        >
          {records?.map((item, i) => (
            <StyledTableRow key={i}>
              <StyledTableCell>{item?.top_up_id}</StyledTableCell>
              <StyledTableCell>
                {item?.device_info?.name || "-"}
              </StyledTableCell>
              <StyledTableCell>
                {item?.device_info?.email || "-"}
              </StyledTableCell>
              <StyledTableCell>
                {_symbol}
                {Utils.formatWithSuffix(item?.amount)}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableWrapper>
      </Box>
    </div>
  );
};

const thLabels = ["ID", "Name", "Email", "Amount"];

export default AttendeeTopupReport;
