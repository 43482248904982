import axios from "axios";

export default function ApiManager(
  method,
  path,
  params = {},
  _header = { "Content-Type": "application/json" },
) {
  const URL = process.env.REACT_APP_API_KEY;
  const TOKEN = localStorage.getItem(process.env.REACT_APP_TOKEN) || "";
  let HEADER = { headers: { ..._header } };
  if (TOKEN) {
    HEADER = { headers: { Authorization: `Bearer ${TOKEN}`, ..._header } };
  }
  try {
    return new Promise(function (myResolve, myReject) {
      if (method === "post" || method === "patch") {
        axios[method](URL + path, params, HEADER)
          .then((response) => {
            myResolve(response);
          })
          .catch((err) => {
            myReject(err);
          });
        return;
      }
      axios[method](URL + path, HEADER)
        .then((response) => {
          myResolve(response);
        })
        .catch((err) => {
          myReject(err);
        });
    });
  } catch (err) {
    console.log("axios.js >> TOKEN_NOT_FOUND-----------", err);
  }
}
