import React, { useEffect } from "react";
import { Box, Paper, Fab, Typography, Grid, Divider } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { handleLoader, setToast } from "../../../../store/reducer";
import ApiManager from "../../../../services/api-manager";
import usePageTitle from "../../../../hooks/use-page-title";
import moment from "moment";

const SpViewDemoRequest = () => {
  usePageTitle("View Email");
  const { presentationID } = useParams();
  const [record, setRecord] = React.useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));

  useEffect(() => {
    async function init() {
      if (presentationID) {
        try {
          dispatch(handleLoader(true));
          let { data } = await ApiManager(
            "get",
            `superAdmin/request-demos/${presentationID}`,
          );
          setRecord(data);
          if (!data?.read) {
            await ApiManager(
              "post",
              `superAdmin/update-request-demos/${presentationID}`,
              {},
            );
          }
        } catch (error) {
          showMessage("error", error?.response?.data?.error?.message);
        } finally {
          dispatch(handleLoader(false));
        }
      }
    }
    init();
  }, [presentationID]);

  return (
    <Box m={{ sm: 2 }}>
      <Box
        component={Paper}
        elevation={6}
        sx={{
          p: 2,
        }}
      >
        <Fab onClick={() => navigate("/admin/demo-requests")} size="small">
          <ArrowBackIcon />
        </Fab>
        <br />
        <br />
        <Box>
          <Grid container spacing={3}>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Typography sx={styles.hd}>First name</Typography>
              <Typography sx={styles.text}>{record?.first_name}</Typography>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Typography sx={styles.hd}>Last name</Typography>
              <Typography sx={styles.text}>
                {record?.last_name || "[Last name]"}
              </Typography>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Typography sx={styles.hd}>Email</Typography>
              <Typography sx={styles.text}>
                {record?.email || "[email]"}
              </Typography>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Typography sx={styles.hd}>Company name</Typography>
              <Typography sx={styles.text}>
                {record?.company_name || "[Company name]"}
              </Typography>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Typography sx={styles.hd}>Phone number</Typography>
              <Typography sx={styles.text}>
                {record?.phone_number || "[Phone number]"}
              </Typography>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Typography sx={styles.hd}>Country</Typography>
              <Typography sx={styles.text}>
                {record?.country || "[country]"}
              </Typography>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Typography sx={styles.hd}>Estimated attendees</Typography>
              <Typography sx={styles.text}>
                {record?.estimated_attendees || "[Estimated attendees]"}
              </Typography>
            </Grid>

            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Typography sx={styles.hd}>Cashless payment</Typography>
              <Typography sx={styles.text}>
                {record?.cashless_payment ? "Required" : "Not Required"}
              </Typography>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Typography sx={styles.hd}>Access control</Typography>
              <Typography sx={styles.text}>
                {record?.access_control ? "Required" : "Not Required"}
              </Typography>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Typography sx={styles.hd}>Tickets</Typography>
              <Typography sx={styles.text}>
                {record?.tickets ? "Required" : "Not Required"}
              </Typography>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Typography sx={styles.hd}>Request at</Typography>
              <Typography sx={styles.text}>
                {moment(record?.created_at).format(
                  process.env.REACT_APP_DATETIME_FORMAT,
                ) || "[Request at]"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Typography sx={styles.hd}>Problem message</Typography>
              <Typography sx={styles.text}>
                {record?.problem_message || "[Problem message]"}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default SpViewDemoRequest;

const styles = {
  hd: {
    fontSize: 18,
    fontWeight: "bold",
    mb: 0.5,
  },
  text: {
    fontSize: 16,
    mb: 2,
  },
};
