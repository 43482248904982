import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Stack,
  Button,
  Divider,
} from "@mui/material";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";

import { InputField, SelectBox } from "../../../../component";
import { handleLoader, setToast } from "../../../../store/reducer";
import errorsSetter from "../../../../helpers/error-setter";
import ApiManager from "../../../../services/api-manager";
import usePageTitle from "../../../../hooks/use-page-title";

const LeadCaptureSetting = () => {
  usePageTitle("Lead Capture Setting");
  const { id } = useParams();
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const dispatch = useDispatch();
  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));

  useEffect(() => {
    async function init() {
      if (id) {
        try {
          dispatch(handleLoader(true));
          let { data } = await ApiManager(
            "get",
            `organizer/lead-capture-settings?event_id=${id}`,
          );
          setFormData({ ...data });
        } catch (error) {
          showMessage("error", error?.response?.data?.error?.message);
        } finally {
          dispatch(handleLoader(false));
        }
      }
    }
    init();
    // eslint-disable-next-line
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      dispatch(handleLoader(true));
      setFormErrors({});
      await ApiManager("post", "organizer/lead-capture-settings", {
        ...formData,
        event: id,
      });
      showMessage("success", "Settings updated successfully.");
    } catch (error) {
      if (error?.response?.status === 422) {
        setFormErrors(errorsSetter(error));
      } else {
        showMessage("error", error?.response?.data?.error?.message);
      }
    } finally {
      dispatch(handleLoader(false));
    }
  };

  const formInputs = () => {
    return (
      <Grid container columnSpacing={3} mt={2} rowSpacing={2}>
        <Grid item sm={4} xs={12}>
          <Box>
            <SelectBox
              items={_opt}
              fullWidth
              label="Exhibitor lead capture enable"
              name="exhibitor_status"
              required
              error={formErrors?.exhibitor_status}
              value={formData?.exhibitor_status}
              onChange={handleInputChange}
            />
          </Box>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Box>
            <SelectBox
              items={_opt}
              fullWidth
              label="Attendee connect enable"
              name="attendee_connect_status"
              required
              error={formErrors?.attendee_connect_status}
              value={formData?.attendee_connect_status}
              onChange={handleInputChange}
            />
          </Box>
        </Grid>
        <Grid item sm={4} xs={12}>
          <InputField
            label="Lead capture setting pin"
            name="pin"
            required
            error={formErrors?.pin}
            value={formData?.pin}
            onChange={handleInputChange}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Box m={{ sm: 2 }}>
      <Box
        component={Paper}
        elevation={6}
        sx={{
          p: 2,
        }}
      >
        <Box
          component="form"
          autoCapitalize="off"
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <Typography variant="h5" mb={2}>
            Lead Capture Setting
          </Typography>
          <Typography variant="body1">
            Lead capture/Attendee connect will allow you to provide a way for
            your attendee & exhibitor at your events. The following setting will
            outline what if any lead capture setting if you will support{" "}
          </Typography>
          {formInputs()}
          <Divider
            sx={{
              mb: 2,
              mt: 3,
            }}
          />
          <Stack direction="row" gap={2} mt={2}>
            <Button
              variant="contained"
              sx={{
                color: "white",
              }}
              type="submit"
            >
              Save
            </Button>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default LeadCaptureSetting;

const _opt = [
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Disable",
    value: "inactive",
  },
];
