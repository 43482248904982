import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Stack,
  Button,
  Divider,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { InputField } from "../../../../../component";
import usePageTitle from "../../../../../hooks/use-page-title";
import { handleLoader, setToast } from "../../../../../store/reducer";
import ApiManager from "../../../../../services/api-manager";
import errorsSetter from "../../../../../helpers/error-setter";

const AddVendor = () => {
  const params = useParams();
  const { id } = useParams();
  const vendorID = params?.vendorID;
  usePageTitle("Add Vendor");

  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({
    allow_manual_items: false,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));
  const _path = `/event/${id}/management/cashless/vendors`;

  useEffect(() => {
    async function init() {
      if (vendorID) {
        try {
          dispatch(handleLoader(true));
          let { data } = await ApiManager(
            "get",
            `organizer/vendors/${vendorID}`,
          );
          setFormData({ ...data });
        } catch (error) {
          showMessage("error", error?.response?.data?.error?.message);
        } finally {
          dispatch(handleLoader(false));
        }
      }
    }
    init();
  }, [vendorID]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormErrors({});
    try {
      dispatch(handleLoader(true));
      let _url = vendorID
        ? `organizer/vendors/${vendorID}`
        : "organizer/vendors";
      let _fd = {
        ...formData,
        event: id,
      };
      if (vendorID) {
        _fd._method = "patch";
      }
      await ApiManager("post", _url, _fd);
      if (vendorID) {
        showMessage("success", "Vendor updated successfully.");
      } else {
        showMessage("success", "Vendor created successfully.");
      }
      navigate(_path);
    } catch (error) {
      if (error?.response?.status === 422) {
        setFormErrors(errorsSetter(error));
      } else {
        navigate(_path);
        showMessage("error", error?.response?.data?.error?.message);
      }
    } finally {
      dispatch(handleLoader(false));
    }
  };

  return (
    <Box m={{ sm: 2 }}>
      <Box component={Paper} elevation={6} p={2}>
        <Box
          component="form"
          autoComplete="off"
          onSubmit={handleSubmit}
          autoCapitalize="off"
        >
          <Typography variant="h5" mb={2}>
            {vendorID ? "Update" : "Create"} Vendor
          </Typography>
          <Typography variant="body1">
            Vendor {formData?.name && "- " + formData?.name}
          </Typography>

          <Grid container columnSpacing={3} mt={2} rowSpacing={2}>
            <Grid item lg={3} sm={6} md={4} xs={12}>
              <Box>
                <InputField
                  label="Name"
                  size="small"
                  name="name"
                  required
                  error={formErrors?.name}
                  value={formData?.name}
                  onChange={handleInputChange}
                />
              </Box>
            </Grid>
            <Grid item lg={3} sm={6} md={4} xs={12}>
              <Box>
                <InputField
                  label="Email"
                  size="small"
                  name="email"
                  type="email"
                  required
                  error={formErrors?.email}
                  value={formData?.email}
                  onChange={handleInputChange}
                />
              </Box>
            </Grid>
            <Grid item lg={3} sm={6} md={4} xs={12}>
              <InputField
                label="Tax number"
                size="small"
                name="tax_number"
                type="number"
                required
                error={formErrors?.tax_number}
                value={formData?.tax_number}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item lg={3} sm={6} md={4} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="allow_tip"
                    checked={!!formData?.allow_tip}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        allow_tip: e.target.checked,
                      }))
                    }
                  />
                }
                label="Allow Tip"
                sx={{
                  my: 0,
                }}
              />
            </Grid>
          </Grid>
          <Divider
            sx={{
              my: 2,
            }}
          />
          <Stack direction="row" gap={2} mt={2}>
            <Button
              variant="contained"
              type="submit"
              sx={{
                color: "white",
              }}
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => navigate(_path)}
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default AddVendor;
