import React, { useState, useEffect, Fragment } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Stack,
  Button,
  Divider,
} from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";

import { InputField } from "../../../../component";
import { handleLoader, setToast } from "../../../../store/reducer";
import errorsSetter from "../../../../helpers/error-setter";
import ApiManager from "../../../../services/api-manager";
import usePageTitle from "../../../../hooks/use-page-title";

const SpCreateVenue = () => {
  const { id: venueID = null } = useParams();
  usePageTitle(venueID ? "Update" : "Add" + " Venue");
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isCurrenciesLoading, setIsCurrenciesLoading] = useState(true);
  const [currencies, setCurrencies] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const goBack = () => navigate("/admin/venue");
  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));

  useEffect(() => {
    async function init() {
      if (venueID) {
        try {
          dispatch(handleLoader(true));
          let { data } = await ApiManager(
            "get",
            `superAdmin/venues/${venueID}`,
          );
          setFormData({
            name: data?.name,
            seating_capacity: data?.seating_capacity,
          });
          getCurrenciesFromServer(data?.currencies);
        } catch (error) {
          console.log(
            "🚀 ~ file: sp-create-venue.js:49 ~ init ~ error:",
            error,
          );
          showMessage("error", error?.response?.data?.error?.message);
        } finally {
          dispatch(handleLoader(false));
        }
      } else {
        getCurrenciesFromServer();
      }
    }
    init();
  }, [venueID]);

  const getCurrenciesFromServer = async (currenciesData = []) => {
    try {
      let { data } = await ApiManager("get", "superAdmin/currencies");
      console.log(
        "🚀 ~ file: sp-create-venue.js:66 ~ getCurrenciesFromServer ~ data:",
        data,
      );
      if (!!data && data?.currencies?.length > 0) {
        let arr = data?.currencies?.map((item) => ({
          currency_id: item?.currency_id,
          label: item?.currency_name + " " + item.currency_symbol,
          price: 0,
        }));
        if (currenciesData.length) {
          arr = arr.map((item2) => {
            const matchedItem = currenciesData.find(
              (item1) => item1.currency_id === item2.currency_id,
            );
            if (matchedItem) {
              item2.price = matchedItem.price;
            }
            return item2;
          });
        }
        setCurrencies(arr);
      } else {
        dispatch(
          setToast({
            type: "warning",
            message: "Please add atleast one currency.",
          }),
        );
        goBack();
      }
    } catch (error) {
      console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
    } finally {
      setIsCurrenciesLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleArrayInputChange = (e, id) => {
    const { name, value } = e.target;
    setCurrencies((prev) => {
      return prev.map((obj) => {
        if (obj.currency_id === id) {
          return { ...obj, [name]: value };
        }
        return obj;
      });
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      dispatch(handleLoader(true));
      let _url = venueID ? `superAdmin/venues/${venueID}` : "superAdmin/venues";
      let fd = { ...formData, currencies };
      if (venueID) {
        fd._method = "patch";
      }
      await ApiManager("post", _url, fd);
      showMessage(
        "success",
        `Venue ${!!venueID ? "updated" : "added"} successfully.`,
      );
      goBack();
    } catch (error) {
      if (error?.response?.status === 422) {
        setFormErrors(errorsSetter(error));
      } else {
        showMessage("error", error?.response?.data?.error?.message);
      }
    } finally {
      dispatch(handleLoader(false));
    }
  };

  const formInputs = () => {
    return (
      <Grid container columnSpacing={3} mt={2} rowSpacing={2}>
        <Grid item sm={4} xs={12}>
          <InputField
            label="Name"
            name="name"
            required
            error={formErrors?.name}
            value={formData?.name}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <InputField
            label="Seating Capacity"
            name="seating_capacity"
            type="number"
            required
            error={formErrors?.seating_capacity}
            value={formData?.seating_capacity}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" mt={2}>
            Currencies
          </Typography>
          {isCurrenciesLoading ? (
            <Typography variant="h6" mt={2}>
              ...Loading currencies records
            </Typography>
          ) : null}
        </Grid>
        {isCurrenciesLoading
          ? null
          : currencies.map((_item, index) => {
              return (
                <Fragment key={_item.id}>
                  <Grid item sm={4} xs={12}>
                    <Typography variant="h6" mb={1}>
                      {_item?.label}
                    </Typography>
                    <InputField
                      label="Price"
                      type="number"
                      name="price"
                      required
                      error={formErrors?.price}
                      value={_item?.price}
                      onChange={(e) =>
                        handleArrayInputChange(e, _item.currency_id)
                      }
                    />
                  </Grid>
                </Fragment>
              );
            })}
      </Grid>
    );
  };

  return (
    <Box m={{ sm: 2 }}>
      <Box
        component={Paper}
        elevation={6}
        sx={{
          p: 2,
        }}
      >
        <Box
          component="form"
          autoCapitalize="off"
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <Typography variant="h5">
            {venueID ? "Update" : "Add"} Venue
          </Typography>
          {formInputs()}
          <Box mt={2}>
            <Divider />
          </Box>
          <Stack direction="row" gap={2} mt={2}>
            <Button
              variant="contained"
              sx={{
                color: "white",
              }}
              type="submit"
            >
              Submit
            </Button>
            <Button variant="contained" color="error" onClick={goBack}>
              Cancel
            </Button>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default SpCreateVenue;
