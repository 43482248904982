import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Stack,
  TableCell,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  StyledTableCell,
  StyledTableRow,
  TablePagination,
  TableWrapper,
} from "../../../component";
import usePageTitle from "../../../hooks/use-page-title";
import ApiManager from "../../../services/api-manager";
import TableDataRow from "./table-data-row";

const TestCard = () => {
  usePageTitle("Test Cards");
  const { id } = useParams();
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState(null);
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.storeReducer);
  const _isAdmin = user?.role === "super_admin" ? true : false;
  const _role = user?.role === "super_admin" ? "superAdmin" : "organizer";

  useEffect(() => {
    getData();
  }, [page, rowsPerPage]);

  const getData = async () => {
    try {
      setIsLoading(true);
      let { data } = await ApiManager("get", `${_role}/get-test-cards/${id}`);
      setRecords(data);
    } catch (error) {
      console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const onChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const thLabels = [
    "Test card id",
    "Open id",
    "Total spent",
    "Total topup by card",
    "Total topup by cash",
    "Action",
  ];

  return (
    <div>
      <Box>
        <Typography variant="h4" color="initial" fontWeight={"bold"} m={2}>
          Test Cards
        </Typography>

        {!_isAdmin && (
          <Stack direction="row" m={2}>
            <Button
              startIcon={<ControlPointIcon />}
              onClick={() =>
                navigate(`/event/${id}/test-card/create-test-card`)
              }
            >
              Add Test Card
            </Button>
          </Stack>
        )}

        <TableWrapper
          pagination={
            <TablePagination
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              perPage={records?.per_page || 0}
              total={records?.total || 0}
            />
          }
          thContent={
            <>
              {thLabels.map((v) => (
                <TableCell key={v}>{v}</TableCell>
              ))}
            </>
          }
          spanTd={thLabels.length}
          isLoading={isLoading}
          isContent={records?.cards?.length || false}
        >
          {records?.cards?.map((item, i) => (
            <TableDataRow
              item={item}
              event={records?.event}
              key={item.open_id}
              isAdmin={_isAdmin}
            />
          ))}
        </TableWrapper>
      </Box>
    </div>
  );
};

export default TestCard;
