import React from "react";
import { useNavigate } from "react-router-dom";
import PrimaryLayout from "../../layout/primary-layout/primary-layout";
import SecondaryLayout from "../../layout/secondary-layout/secondary-layout";

function ProtectedRoute({ layoutType, element, isLogged, role, type }) {
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!isLogged) {
      return navigate("/login");
    }
  }, [isLogged]);

  if (!isLogged) {
    return <></>;
  }

  if (type !== "all" && role !== type) {
    alert("You are unauthorized.");
    return <></>;
  }

  if (layoutType === "primary") {
    return <PrimaryLayout children={element} />;
  }

  return <SecondaryLayout children={element} />;
}

export default ProtectedRoute;
