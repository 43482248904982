import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Stack,
  TableCell,
  Tooltip,
  IconButton,
  Grid,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import RefreshIcon from "@mui/icons-material/Refresh";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useDispatch, useSelector } from "react-redux";
import {
  ConfirmationModal,
  ExportBtn,
  InputField,
  SearchInput,
  StartAndEndDateFilter,
  StyledTableCell,
  StyledTableRow,
  TablePagination,
  TableWrapper,
} from "../../../../component";
import ApiManager from "../../../../services/api-manager";
import usePageTitle from "../../../../hooks/use-page-title";
import Utils from "../../../../utils/utils";
import moment from "moment/moment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { DeleteForever } from "@mui/icons-material";
import { handleLoader, setToast } from "../../../../store/reducer";

const RevenueReports = () => {
  usePageTitle("Revenue Report");
  const dispatch = useDispatch();
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [searchCalled, setSearchCalled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState(null);
  const [openDeleteModel, setOpenDeleteModel] = useState(null);
  const navigate = useNavigate();
  const { user, loggedEvent } = useSelector((state) => state.storeReducer);
  const _role = user?.role === "super_admin" ? "superAdmin" : "organizer";
  const { id } = useParams();
  const _symbol = loggedEvent.currencySymbol;
  const [columns, setColumns] = useState([]);
  const [filtersState, setFiltersState] = useState({
    start_date: "",
    end_date: "",
    search: "",
    start_time: "",
    end_time: "",
  });

  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));

  useEffect(() => {
    getData();
  }, [
    page,
    rowsPerPage,
    id,
    filtersState.start_date,
    filtersState.end_date,
    filtersState.start_time,
    filtersState.end_time,
    searchCalled,
  ]);

  const getData = async (_search = "") => {
    try {
      setIsLoading(true);
      let { data } = await ApiManager(
        "get",
        `${_role}/reports?revenue=1&event_id=${id}&page=${page}&per_page=${rowsPerPage}&start_date=${filtersState.start_date}&end_date=${filtersState.end_date}&search=${filtersState.search}&start_time=${filtersState?.start_time}&end_time=${filtersState?.end_time}&`,
      );
      setColumns(data?.columns);
      setRecords(data?.revenue);
    } catch (error) {
      console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const onChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteRecord = async () => {
    try {
      dispatch(handleLoader(true));
      await ApiManager("get", `organizer/delete-order/${openDeleteModel}`);
      showMessage("success", "Deleted successfully.");
      setOpenDeleteModel(null);
      setRecords((prev) => ({
        ...prev,
        data: prev?.data.filter((item) => item?.order_id !== openDeleteModel),
      }));
    } catch (error) {
      setOpenDeleteModel(null);
      showMessage("error", error?.response?.data?.error?.message);
    } finally {
      dispatch(handleLoader(false));
    }
  };

  return (
    <div>
      <Box>
        <Typography variant="h4" color="initial" fontWeight={"bold"} m={2}>
          Revenue Report
        </Typography>
        {/* <Stack
          direction={{ sm: "row", xs: "column" }}
          justifyContent={{ sm: "space-between", xs: "center" }}
          my={2}
        >
          <Stack
            direction={{ sm: "row", xs: "column" }}
            justifyContent={{ sm: "start", xs: "center" }}
          >
            <Button startIcon={<RefreshIcon />} onClick={() => getData()}>
              Refresh
            </Button>
            <ExportBtn
              data={columns}
              path={`reports-event-revenue-csv?event_id=${id}&`}
            />
          </Stack>
          <Stack direction="row" gap={2} alignItems={"center"}>
            <InputField
              label="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            {!!search && (
              <Button
                onClick={() => {
                  if (page === 1) {
                    getData(search);
                  } else {
                    setPage(1);
                  }
                  setSearchCalled(true);
                }}
                variant="contained"
                sx={{ color: "white" }}
              >
                Search
              </Button>
            )}
            {searchCalled && (
              <Button
                onClick={() => {
                  setSearch("");
                  if (page === 1) {
                    getData();
                  } else {
                    setPage(1);
                  }
                  setSearchCalled(false);
                }}
                variant="contained"
                color="error"
              >
                Clear
              </Button>
            )}
          </Stack>
        </Stack> */}

        <Grid container spacing={2} mb={2}>
          <Grid item xl={2} lg={3} md={12} xs={12}>
            <Stack direction="row">
              <Button startIcon={<RefreshIcon />} onClick={() => getData()}>
                Refresh
              </Button>
              <ExportBtn
                data={columns}
                path={`reports-event-revenue-csv?event_id=${id}&start_date=${filtersState.start_date}&end_date=${filtersState.end_date}&search=${filtersState.search}&start_time=${filtersState?.start_time}&end_time=${filtersState?.end_time}`}
              />
            </Stack>
          </Grid>
          <Grid item xl={3.5} lg={4} md={12} xs={12}>
            <SearchInput
              value={filtersState.search}
              onChange={(e) =>
                setFiltersState((prev) => ({ ...prev, search: e.target.value }))
              }
              onRequestSearch={() => {
                if (page === 1) {
                  setSearchCalled(!searchCalled);
                } else {
                  setPage(1);
                }
              }}
              onRequestClear={() => {
                setFiltersState({ ...filtersState, search: "" });
                if (page === 1) {
                  setSearchCalled(!searchCalled);
                } else {
                  setPage(1);
                }
              }}
            />
          </Grid>
          <Grid item xl={6.5} lg={5} md={12} xs={12}>
            <StartAndEndDateFilter
              onFilterChange={(filterState) => {
                setPage(1);
                setFiltersState((prev) => ({ ...prev, ...filterState }));
              }}
              onRequestClear={() =>
                setFiltersState((prev) => ({
                  ...prev,
                  start_date: "",
                  end_date: "",
                  start_time: "",
                  end_time: "",
                }))
              }
            />
          </Grid>
        </Grid>

        <TableWrapper
          pagination={
            <TablePagination
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              perPage={records?.per_page || 0}
              total={records?.total || 0}
            />
          }
          spanTd={thLabels.length}
          isLoading={isLoading}
          isContent={records?.data?.length || false}
          thContent={
            <>
              {thLabels.map((v) => (
                <TableCell key={v}>{v}</TableCell>
              ))}
            </>
          }
        >
          {records?.data?.map((item, i) => (
            <StyledTableRow key={i}>
              <StyledTableCell>{item?.order_id}</StyledTableCell>
              <StyledTableCell>{item?.code}</StyledTableCell>
              <StyledTableCell>
                {item?.device?.vendor_info?.name}
              </StyledTableCell>
              <StyledTableCell>
                {Utils.capitalize(item?.payment_status)}
              </StyledTableCell>
              <StyledTableCell>
                {moment(item?.created_at).format(
                  process.env.REACT_APP_DATE_FORMAT,
                )}
              </StyledTableCell>
              <StyledTableCell>
                {_symbol}
                {item?.tip}
              </StyledTableCell>
              <StyledTableCell>{item?.total_items}</StyledTableCell>
              <StyledTableCell>
                {_symbol}
                {item?.amount}
              </StyledTableCell>
              <StyledTableCell>
                {_symbol}
                {item?.refund_amount}
              </StyledTableCell>
              <StyledTableCell>
                {_symbol}
                {item?.actual_amount}
              </StyledTableCell>
              <StyledTableCell>
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <Tooltip title="View Details" placement="top">
                    <IconButton
                      color="info"
                      onClick={() =>
                        navigate(
                          `/event/${id}/management/lead-capture/revenue-report/${item?.order_id}`,
                        )
                      }
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete" placement="top">
                    <IconButton
                      color="error"
                      onClick={() => setOpenDeleteModel(item?.order_id)}
                    >
                      <DeleteForever />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableWrapper>
      </Box>
      <ConfirmationModal
        open={Boolean(openDeleteModel)}
        handleClose={() => setOpenDeleteModel(null)}
        callBack={deleteRecord}
      />
    </div>
  );
};

const thLabels = [
  "ID",
  "RFID Number",
  "Vendor",
  "Payment Status",
  "Created At",
  "Tip",
  "Items #",
  "Amount",
  "Refunded Amount",
  "Actual Amount",
  "Details",
];

export default RevenueReports;
