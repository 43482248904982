import { createSlice } from "@reduxjs/toolkit";

export const myReducer = createSlice({
  name: "storeReducer",
  initialState: {
    user: null,
    isLogged: false,
    openMenu: false,
    loader: false,
    toast: {
      duration: 3000,
      open: false,
      message: "",
      type: "success",
    },
    loggedEvent: {
      vouchers_allowed: true,
    },
    showOnBoardUI: false,
  },

  reducers: {
    toggleMenu: (state, { payload }) => {
      state.openMenu = payload;
    },
    handleEvent: (state, { payload }) => {
      state.loggedEvent = payload;
    },
    handleOnBoardUI: (state, { payload }) => {
      state.showOnBoardUI = payload;
    },
    setUser: (state, { payload }) => {
      state.isLogged = true;
      state.user = payload;
    },
    setLogged: (state) => {
      state.isLogged = true;
    },
    registerUser: (state, { payload }) => {
      state.user = payload;
      state.isLogged = true;
    },
    setToast: (state, { payload }) => {
      state.toast = { ...state.toast, ...payload, open: true };
    },
    closeToast: (state) => {
      state.toast = {
        duration: 3000,
        open: false,
        message: "",
        type: "success",
      };
    },
    handleLoader: (state, { payload }) => {
      state.loader = payload;
    },
    logoutUser: (state) => {
      state.user = null;
      state.isLogged = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  toggleMenu,
  registerUser,
  setToast,
  closeToast,
  handleLoader,
  setUser,
  setLogged,
  logoutUser,
  handleEvent,
  handleOnBoardUI,
} = myReducer.actions;

export default myReducer.reducer;
