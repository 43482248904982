import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Stack,
  TableCell,
  Tooltip,
  IconButton,
} from "@mui/material";
import CreateIcon from "@mui/icons-material/Create";
import QrCodeIcon from "@mui/icons-material/QrCode";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";

import { useNavigate, useParams } from "react-router-dom";
import {
  ConfirmationModal,
  StyledTableCell,
  StyledTableRow,
  TablePagination,
  TableWrapper,
} from "../../../../../component";
import ApiManager from "../../../../../services/api-manager";
import { useDispatch, useSelector } from "react-redux";
import { handleLoader, setToast } from "../../../../../store/reducer";
import usePageTitle from "../../../../../hooks/use-page-title";
import Utils from "../../../../../utils/utils";

const DiscountVouchers = () => {
  const { id } = useParams();
  usePageTitle("Vouchers");
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isVoucherAllowed, setIsVoucherAllowed] = useState(false);
  const [records, setRecords] = useState(null);
  const { user, loggedEvent } = useSelector((state) => state.storeReducer);
  const _isAdmin = user?.role === "super_admin" ? true : false;
  const _role = user?.role === "super_admin" ? "superAdmin" : "organizer";
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));

  const getData = async () => {
    setIsLoading(true);
    try {
      let { data } = await ApiManager(
        "get",
        `${_role}/vouchers?event_id=${id}&page=${page}&per_page=${rowsPerPage}`,
      );
      setRecords(data);
    } catch (error) {
      console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!loggedEvent.vouchers_allowed) {
      showMessage("info", "This event does not provide discount vouchers ");
    }
    getData();
    // eslint-disable-next-line
  }, [page, rowsPerPage, loggedEvent]);

  const onChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const onChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const thLabels = [
    "ID",
    "Promotion code",
    "Voucher redeem ",
    "Start date",
    "End date",
  ];

  if (!_isAdmin) {
    thLabels.push("Manage");
  }

  return (
    <div>
      <Box>
        <Typography variant="h4" color="initial" fontWeight={"bold"} m={2}>
          Discount Vouchers
        </Typography>

        {loggedEvent.vouchers_allowed && !_isAdmin && (
          <Stack direction="row" m={2}>
            <Button
              onClick={() =>
                navigate(
                  `/event/${id}/management/access-control/discount-vouchers/create-voucher`,
                )
              }
            >
              <CreateIcon />
              Add Vouchers
            </Button>
          </Stack>
        )}

        <TableWrapper
          pagination={
            <TablePagination
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              page={page || 0}
              rowsPerPage={rowsPerPage || 0}
              perPage={records?.per_page || 0}
              total={records?.total || 0}
            />
          }
          thContent={
            <>
              {thLabels.map((v) => (
                <TableCell key={v}>{v}</TableCell>
              ))}
            </>
          }
          spanTd={thLabels.length}
          isLoading={isLoading}
          isContent={records?.data?.length || false}
        >
          {records?.data.map((item, i) => (
            <StyledTableRow key={i}>
              <StyledTableCell>{item?.voucher_id}</StyledTableCell>
              <StyledTableCell>{item?.promotion_code}</StyledTableCell>
              <StyledTableCell>
                {item?.voucher_redeem_count} (Per Day)
              </StyledTableCell>

              <StyledTableCell>{item?.start_date}</StyledTableCell>
              <StyledTableCell>{item?.end_date}</StyledTableCell>

              {!_isAdmin && (
                <StyledTableCell>
                  <Stack direction="row" spacing={0.5} alignItems="center">
                    <Tooltip title="Edit" placement="top">
                      <IconButton
                        color="info"
                        onClick={() =>
                          navigate(
                            `/event/${id}/management/access-control/discount-vouchers/edit-voucher/${item?.voucher_id}`,
                          )
                        }
                      >
                        <CreateRoundedIcon />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </StyledTableCell>
              )}
            </StyledTableRow>
          ))}
        </TableWrapper>
      </Box>
    </div>
  );
};

export default DiscountVouchers;
