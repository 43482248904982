import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Stack, TableCell } from "@mui/material";
import { useParams } from "react-router-dom";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useSelector } from "react-redux";

import {
  InputField,
  StyledTableCell,
  StyledTableRow,
  TablePagination,
  TableWrapper,
} from "../../../../component";
import ApiManager from "../../../../services/api-manager";
import usePageTitle from "../../../../hooks/use-page-title";
import RefreshIcon from "@mui/icons-material/Refresh";

const AdmittedReports = () => {
  usePageTitle("Admitted Report");
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [searchCalled, setSearchCalled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState(null);
  const [search, setSearch] = useState("");
  const { id } = useParams();
  const { user } = useSelector((state) => state.storeReducer);
  const _role = user?.role === "super_admin" ? "superAdmin" : "organizer";

  useEffect(() => {
    getData();
  }, [page, rowsPerPage, id]);

  const getData = async (_search = "") => {
    try {
      setIsLoading(true);
      let { data } = await ApiManager(
        "get",
        `${_role}/reports?attendees=1&event_id=${id}&page=${page}&per_page=${rowsPerPage}&search=${_search}`,
      );
      setRecords(data);
    } catch (error) {
      console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const onChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <Box>
        <Typography variant="h4" color="initial" fontWeight={"bold"} m={2}>
          Admitted Report
        </Typography>

        <Stack
          direction={{ sm: "row", xs: "column" }}
          justifyContent={{ sm: "space-between", xs: "center" }}
          my={2}
        >
          <Box>
            <Button
              startIcon={<FileDownloadIcon />}
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_API_KEY}reports-csv?event_id=${id}&attendees=1`,
                  "_blank",
                );
              }}
            >
              Export to CSV
            </Button>
            <Button startIcon={<RefreshIcon />} onClick={() => getData()}>
              Refresh
            </Button>
          </Box>

          <Stack direction="row" gap={2} alignItems={"center"}>
            <InputField
              label="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            {!!search && (
              <Button
                onClick={() => {
                  if (page === 1) {
                    getData(search);
                  } else {
                    setPage(1);
                  }
                  setSearchCalled(true);
                }}
                variant="contained"
                sx={{ color: "white" }}
              >
                Search
              </Button>
            )}
            {searchCalled && (
              <Button
                onClick={() => {
                  setSearch("");
                  if (page === 1) {
                    getData();
                  } else {
                    setPage(1);
                  }
                  setSearchCalled(false);
                }}
                variant="contained"
                color="error"
              >
                Clear
              </Button>
            )}
          </Stack>
        </Stack>

        <TableWrapper
          pagination={
            <TablePagination
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              perPage={records?.per_page || 0}
              total={records?.total || 0}
            />
          }
          spanTd={thLabels.length}
          isLoading={isLoading}
          isContent={records?.data?.length || false}
          thContent={
            <>
              {thLabels.map((v) => (
                <TableCell key={v}>{v}</TableCell>
              ))}
            </>
          }
        >
          {records?.data?.map((item, i) => (
            <StyledTableRow key={i}>
              <StyledTableCell>{item?.user_id}</StyledTableCell>
              <StyledTableCell>{item?.first_name}</StyledTableCell>
              <StyledTableCell>{item?.last_name}</StyledTableCell>
              <StyledTableCell>{item?.phone_number}</StyledTableCell>
              <StyledTableCell>{item?.email}</StyledTableCell>
              <StyledTableCell>{item?.open_id}</StyledTableCell>
              <StyledTableCell>{item?.unique_id}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableWrapper>
      </Box>
    </div>
  );
};

const thLabels = [
  "ID",
  "First Name",
  "Last Name",
  "Phone Number",
  "Email",
  "Open Id",
  "Unique Id",
];

export default AdmittedReports;
