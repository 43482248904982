import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  Paper,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { InputField, FilePicker } from "../../../../component";
import usePageTitle from "../../../../hooks/use-page-title";
import { handleLoader, setToast } from "../../../../store/reducer";
import errorsSetter from "../../../../helpers/error-setter";
import ApiManager from "../../../../services/api-manager";
import Utils from "../../../../utils/utils";

function SpCreateMerchandise() {
  const id = useParams()?.id || null;
  usePageTitle(`${id ? "Update" : "Create"} Merchandise`);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const _path = `/admin/merchandise`;
  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));

  useEffect(() => {
    async function init() {
      if (id) {
        try {
          dispatch(handleLoader(true));
          let { data } = await ApiManager(
            "get",
            `superAdmin/merchandise/${id}`,
          );
          setFormData({ ...data });
        } catch (error) {
          showMessage("error", error?.response?.data?.error?.message);
        } finally {
          dispatch(handleLoader(false));
        }
      }
    }
    init();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const validate = () => {
    let errors = {};
    let flag = true;
    if (!!!formData?.image && !id) {
      errors.image = "File required.";
      flag = false;
    }

    if (formData?.price > 100000) {
      errors.price = "Price must be below 100k";
      flag = false;
    }

    setFormErrors((prev) => ({ ...prev, ...errors }));
    return flag;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        dispatch(handleLoader(true));
        setFormErrors({});
        let _url = id
          ? `superAdmin/merchandise/${id}`
          : "superAdmin/merchandise";
        let _fd = new FormData();
        for (const key in formData) {
          if (Object.hasOwnProperty.call(formData, key)) {
            _fd.append(key, formData[key]);
          }
        }
        if (id) {
          _fd.append("_method", "patch");
        }
        await ApiManager("post", _url, _fd, {
          "Content-Type": "multipart/form-data",
        });
        if (id) {
          showMessage("success", "Merchandise updated successfully.");
        } else {
          showMessage("success", "Merchandise Created successfully.");
        }
        navigate(_path);
      } catch (error) {
        if (error?.response?.status === 422) {
          setFormErrors(errorsSetter(error));
        } else {
          showMessage("error", error?.response?.data?.error?.message);
        }
      } finally {
        dispatch(handleLoader(false));
      }
    }
  };

  return (
    <Box
      component={Paper}
      elevation={6}
      sx={{
        p: 2,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">
            {id ? "Update" : "Create"} Merchandise
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box
            component="form"
            autoComplete="off"
            autoCapitalize="off"
            onSubmit={handleSubmit}
          >
            <Box mb={3}>
              <FilePicker
                onChange={(e) => {
                  const file = e.target.files[0];
                  setFormData((prev) => ({
                    ...prev,
                    image: file,
                    image_url: URL.createObjectURL(file),
                  }));
                }}
                labelTop="Image"
                error={formErrors?.image}
                imageUrl={formData?.image_url}
                helperText="Recommended file dimension[WxH] : 100x56"
                title="Pick a file"
              />
            </Box>
            <Grid container spacing={2}>
              <Grid item md={3} sm={4} xs={12}>
                <InputField
                  fullWidth
                  label="Name"
                  name="name"
                  required
                  error={formErrors?.name}
                  value={formData?.name}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item md={3} sm={4} xs={12}>
                <InputField
                  fullWidth
                  label="SKU"
                  name="sku"
                  required
                  error={formErrors?.sku}
                  value={formData?.sku}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item md={3} sm={4} xs={12}>
                <InputField
                  fullWidth
                  label="Price"
                  name="price"
                  required
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  error={formErrors?.price}
                  value={formData?.price}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item md={3} sm={4} xs={12}>
                <InputField
                  fullWidth
                  label="Tax"
                  name="product_tax"
                  required
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  error={formErrors?.product_tax}
                  value={formData?.product_tax}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item md={3} sm={4} xs={12}>
                <InputField
                  fullWidth
                  label="Quantity"
                  name="quantity"
                  required
                  type="number"
                  error={formErrors?.quantity}
                  value={formData?.quantity}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item md={3} sm={4} xs={12}>
                <InputField
                  fullWidth
                  label="Abbreviation"
                  name="abbreviation"
                  required
                  error={formErrors?.abbreviation}
                  value={formData?.abbreviation}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
            <Box mt={2}>
              <InputField
                label="Description"
                name="description"
                multiline
                rows={4}
                fullWidth
                error={formErrors?.description}
                value={formData?.description}
                onChange={handleInputChange}
              />
            </Box>
            {id && (
              <Box mt={2}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData?.status}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          status: e.target.checked,
                        }))
                      }
                    />
                  }
                  label="Status"
                />
              </Box>
            )}

            <Box my={2} children={<Divider />} />

            <Stack direction="row" gap={2} mt={2}>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  color: "white",
                }}
              >
                Save
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => navigate(_path)}
              >
                Cancel
              </Button>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default SpCreateMerchandise;
