import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Stack,
  Button,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";

import { InputField, SelectBox } from "../../../../../component";
import { handleLoader, setToast } from "../../../../../store/reducer";
import errorsSetter from "../../../../../helpers/error-setter";
import ApiManager from "../../../../../services/api-manager";
import usePageTitle from "../../../../../hooks/use-page-title";

const CreateTicketType = () => {
  const { id } = useParams();
  const params = useParams();
  const ticketID = params?.ticketID;
  usePageTitle(!!ticketID ? "Update Ticket Type" : "Create Ticket Type");
  const [formData, setFormData] = useState({
    access_type: "pass_through",
    pre_topup_amount: 0,
  });
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const _path = `/event/${id}/management/access-control/ticket-types`;

  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));

  useEffect(() => {
    async function init() {
      if (ticketID) {
        try {
          dispatch(handleLoader(true));
          let { data } = await ApiManager(
            "get",
            `organizer/ticket-types/${ticketID}`,
          );
          setFormData(data);
        } catch (error) {
          showMessage("error", error?.response?.data?.error?.message);
        } finally {
          dispatch(handleLoader(false));
        }
      } else {
        setFormData((prev) => ({
          ...prev,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        }));
      }
    }
    init();
  }, [ticketID]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      dispatch(handleLoader(true));
      setFormErrors({});
      let _url = ticketID
        ? `organizer/ticket-types/${ticketID}`
        : "organizer/ticket-types";
      let _fd = { ...formData, event: id };
      if (ticketID) {
        _fd._method = "patch";
      }
      await ApiManager("post", _url, _fd);
      if (ticketID) {
        showMessage("success", "Ticket type updated successfully.");
      } else {
        showMessage("success", "Ticket type created successfully.");
      }
      navigate(_path);
    } catch (error) {
      if (error?.response?.status === 422) {
        setFormErrors(errorsSetter(error));
      } else {
        showMessage("error", error?.response?.data?.error?.message);
      }
    } finally {
      dispatch(handleLoader(false));
    }
  };

  return (
    <Box m={{ sm: 2 }}>
      <Box component={Paper} elevation={6} p={2}>
        <Box
          component="form"
          autoComplete="off"
          autoCapitalize="off"
          onSubmit={handleSubmit}
        >
          <Typography variant="h5">Add Ticket Type</Typography>
          <Typography variant="body1">
            Add Ticket Type that corresponding to ticket you maybe selling in a
            third party system (such as ticket provider)
          </Typography>

          <Grid container columnSpacing={3} mt={2} rowSpacing={2}>
            <Grid item sm={4} xs={12}>
              <Box>
                <InputField
                  label="Name"
                  size="small"
                  name="name"
                  required
                  error={formErrors?.name}
                  value={formData?.name}
                  onChange={handleInputChange}
                />
              </Box>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Box>
                <InputField
                  label="External ID"
                  size="small"
                  name="external_id"
                  required
                  inputProps={{
                    maxLength: 3,
                  }}
                  error={formErrors?.external_id}
                  value={formData?.external_id}
                  onChange={handleInputChange}
                />
              </Box>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Box>
                <InputField
                  label="QR Code"
                  size="small"
                  name="qr_code"
                  required
                  error={formErrors?.qr_code}
                  value={formData?.qr_code}
                  onChange={handleInputChange}
                />
              </Box>
            </Grid>
            <Grid item sm={4} xs={12}>
              <SelectBox
                fullWidth
                items={[
                  // { label: "Trigger Check-in/Check-out)", value: "trigger" },
                  { label: "Pass Through", value: "pass_through" },
                  // { label: "Counter", value: "counter" },
                ]}
                label="Access Type"
                size="small"
                name="access_type"
                required
                error={formErrors?.access_type}
                value={formData?.access_type}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <Box>
                <InputField
                  label="Price"
                  size="small"
                  name="price"
                  required
                  type="number"
                  error={formErrors?.price}
                  value={formData?.price}
                  onChange={handleInputChange}
                />
              </Box>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Box>
                <InputField
                  label="Quantity"
                  size="small"
                  name="quantities"
                  required
                  error={formErrors?.quantities}
                  type="number"
                  value={formData?.quantities}
                  onChange={handleInputChange}
                />
              </Box>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Box>
                <InputField
                  label="Pre TopUp Amount"
                  size="small"
                  name="pre_topup_amount"
                  error={formErrors?.pre_topup_amount}
                  type="number"
                  value={formData?.pre_topup_amount}
                  onChange={handleInputChange}
                />
              </Box>
            </Grid>
            {formData.access_type === "counter" && (
              <Grid item sm={4} xs={12}>
                <Box>
                  <InputField
                    label="Default Counter Value"
                    size="small"
                    name="counter_value"
                    required
                    error={formErrors?.counter_value}
                    value={formData?.counter_value}
                    onChange={handleInputChange}
                    type="number"
                  />
                </Box>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography variant="h6">
                Additional Ticket Type Option
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    name="issue_alcohol_ban"
                    checked={!!formData?.issue_alcohol_ban}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.checked,
                      }))
                    }
                  />
                }
                label="Issue Alcohol Ban"
              />
            </Grid>
          </Grid>
          <Box sx={{ borderTop: 1, mt: 2, borderColor: "divider" }}>
            <Stack direction="row" gap={2} mt={2}>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  color: "white",
                }}
              >
                Save
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => navigate(_path)}
              >
                Cancel
              </Button>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateTicketType;
