import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Stack,
  TableCell,
  Tooltip,
  IconButton,
  Grid,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import moment from "moment/moment";
import { useSelector } from "react-redux";

import {
  ExportBtn,
  InputField,
  SearchInput,
  StartAndEndDateFilter,
  StyledTableCell,
  StyledTableRow,
  TablePagination,
  TableWrapper,
} from "../../../../component";
import ApiManager from "../../../../services/api-manager";
import usePageTitle from "../../../../hooks/use-page-title";
import Utils from "../../../../utils/utils";
import { ShoppingBag, Paid } from "@mui/icons-material";
import RefreshIcon from "@mui/icons-material/Refresh";

const AttendeeReports = () => {
  usePageTitle("Attendee Report");
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [searchCalled, setSearchCalled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState(null);
  const [filtersState, setFiltersState] = useState({
    start_date: "",
    end_date: "",
    start_time: "",
    end_time: "",
    search: "",
  });

  const { id } = useParams();
  const { user, loggedEvent } = useSelector((state) => state.storeReducer);
  const _role = user?.role === "super_admin" ? "superAdmin" : "organizer";
  const [columns, setColumns] = useState([]);
  const _symbol = loggedEvent.currencySymbol;

  useEffect(() => {
    getData();
  }, [
    page,
    rowsPerPage,
    id,
    filtersState.start_date,
    filtersState.end_date,
    filtersState.start_time,
    filtersState.end_time,
    searchCalled,
  ]);

  const getData = async () => {
    try {
      setIsLoading(true);
      let { data } = await ApiManager(
        "get",
        `${_role}/reports?attendees=1&event_id=${id}&page=${page}&per_page=${rowsPerPage}&start_date=${filtersState.start_date}&end_date=${filtersState.end_date}&search=${filtersState.search}&start_time=${filtersState?.start_time}&end_time=${filtersState?.end_time}&`,
      );
      setRecords(data?.attendees);
      setColumns(data?.columns);
    } catch (error) {
      console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const onChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <Box>
        <Typography variant="h4" color="initial" fontWeight={"bold"} m={2}>
          Attendees Report
        </Typography>

        {/* <Stack
          direction={{ sm: "row", xs: "column" }}
          justifyContent={{ sm: "space-between", xs: "center" }}
          my={2}
        >
          <Stack
            direction={{ sm: "row", xs: "column" }}
            justifyContent={{ sm: "start", xs: "center" }}
          >
            <Button startIcon={<RefreshIcon />} onClick={() => getData()}>
              Refresh
            </Button>
            <ExportBtn
              data={columns}
              path={`reports-event-attendees-csv?event_id=${id}&`}
            />
          </Stack>

          <Stack direction="row" gap={2} alignItems={"center"}>
            <InputField
              label="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            {!!search && (
              <Button
                onClick={() => {
                  if (page === 1) {
                    getData(search);
                  } else {
                    setPage(1);
                  }
                  setSearchCalled(true);
                }}
                variant="contained"
                sx={{ color: "white" }}
              >
                Search
              </Button>
            )}
            {searchCalled && (
              <Button
                onClick={() => {
                  setSearch("");
                  if (page === 1) {
                    getData();
                  } else {
                    setPage(1);
                  }
                  setSearchCalled(false);
                }}
                variant="contained"
                color="error"
              >
                Clear
              </Button>
            )}
          </Stack>
        </Stack> */}

        <Grid container spacing={2} mb={2}>
          <Grid item xl={2} lg={3} md={12} xs={12}>
            <Stack direction="row">
              <Button startIcon={<RefreshIcon />} onClick={() => getData()}>
                Refresh
              </Button>
              <ExportBtn
                data={columns}
                path={`reports-event-attendees-csv?event_id=${id}&start_date=${filtersState.start_date}&end_date=${filtersState.end_date}&search=${filtersState.search}&start_time=${filtersState?.start_time}&end_time=${filtersState?.end_time}`}
              />
            </Stack>
          </Grid>
          <Grid item xl={3.5} lg={4} md={12} xs={12}>
            <SearchInput
              value={filtersState.search}
              onChange={(e) =>
                setFiltersState((prev) => ({ ...prev, search: e.target.value }))
              }
              onRequestSearch={() => {
                if (page === 1) {
                  setSearchCalled(getData);
                } else {
                  setPage(1);
                }
              }}
              onRequestClear={() => {
                setFiltersState({ ...filtersState, search: "" });
                if (page === 1) {
                  setSearchCalled(!searchCalled);
                } else {
                  setPage(1);
                }
              }}
            />
          </Grid>
          <Grid item xl={6.5} lg={5} md={12} xs={12}>
            <StartAndEndDateFilter
              onFilterChange={(filterState) => {
                setPage(1);
                setFiltersState((prev) => ({ ...prev, ...filterState }));
              }}
              onRequestClear={() =>
                setFiltersState((prev) => ({
                  ...prev,
                  start_date: "",
                  end_date: "",
                  start_time: "",
                  end_time: "",
                }))
              }
            />
          </Grid>
        </Grid>

        <TableWrapper
          pagination={
            <TablePagination
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              perPage={records?.per_page || 0}
              total={records?.total || 0}
            />
          }
          spanTd={thLabels.length}
          isLoading={isLoading}
          isContent={records?.data?.length || false}
          thContent={
            <>
              {thLabels.map((v) => (
                <TableCell key={v}>{v}</TableCell>
              ))}
            </>
          }
        >
          {records?.data?.map((item, i) => (
            <StyledTableRow key={i}>
              <StyledTableCell>{item?.open_id || "-"}</StyledTableCell>
              <StyledTableCell>{item?.name || "-"}</StyledTableCell>
              <StyledTableCell>{item?.email || "-"}</StyledTableCell>
              <StyledTableCell>
                {_symbol}
                {Utils.formatWithSuffix(item?.total_topups) || "0"}
              </StyledTableCell>
              <StyledTableCell>
                {_symbol}
                {Utils.formatWithSuffix(item?.total_spendings) || "0"}
              </StyledTableCell>
              <StyledTableCell>
                {_symbol}
                {Utils.formatWithSuffix(item?.total_refunds || "0")}
              </StyledTableCell>
              <StyledTableCell>
                {Utils.capitalize(item?.status) || "-"}
              </StyledTableCell>
              <StyledTableCell>
                {moment(item?.created_at).format(
                  process.env.REACT_APP_DATE_FORMAT,
                )}
              </StyledTableCell>
              <StyledTableCell>
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <Tooltip title="View Top-ups" placement="top">
                    <IconButton
                      color="info"
                      onClick={() =>
                        navigate(
                          `/event/${id}/management/lead-capture/attendee-topup-report/${item?.ticket_id}`,
                        )
                      }
                    >
                      <Paid />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="View Spending" placement="top">
                    <IconButton
                      color="danger"
                      onClick={() =>
                        navigate(
                          `/event/${id}/management/lead-capture/attendee-spending-report/${item?.ticket_id}`,
                        )
                      }
                    >
                      <ShoppingBag />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableWrapper>
      </Box>
    </div>
  );
};

const thLabels = [
  "RFID Number",
  "Name",
  "Email",
  "Total topups",
  "Total spending",
  "Total refunds",
  "status",
  "Created At",
  "Details",
];

export default AttendeeReports;
