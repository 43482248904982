import React, { useEffect, useState } from "react";
import { Box, Typography, TableCell, Fab } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import {
  StyledTableCell,
  StyledTableRow,
  TableWrapper,
} from "../../../../component";
import ApiManager from "../../../../services/api-manager";
import usePageTitle from "../../../../hooks/use-page-title";
import Utils from "../../../../utils/utils";

// TODO: refund work remains
// TODO: Final Quantity and Final Amount work remains

const RevenueReportDetail = () => {
  usePageTitle("Revenue Report Details");
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState(null);
  const { id, orderID } = useParams();
  const navigate = useNavigate();
  const { user, loggedEvent } = useSelector((state) => state.storeReducer);
  const _role = user?.role === "super_admin" ? "superAdmin" : "organizer";
  const _symbol = loggedEvent.currencySymbol;

  useEffect(() => {
    getData();
  }, [id]);

  const getData = async (_search = "") => {
    try {
      setIsLoading(true);
      let { data } = await ApiManager(
        "get",
        `${_role}/order-details/${orderID}`,
      );
      console.log("showing data: ", data?.items);
      setRecords(data);
    } catch (error) {
      console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Box>
        <Fab
          size="small"
          onClick={() =>
            // navigate(`/event/${id}/management/lead-capture/revenue-report`)
            navigate(-1)
          }
        >
          <ArrowBackIcon />
        </Fab>
        <Typography variant="h4" color="initial" fontWeight="bold" m={2}>
          Vendor -{" "}
          {records?.device?.vendor_devices?.vendor?.name || "...Loading"}
        </Typography>
        <Typography variant="h6" color="initial" fontWeight="bold" m={2}>
          Revenue Report Details
        </Typography>

        <TableWrapper
          spanTd={thLabels.length}
          isLoading={isLoading}
          isContent={records?.items?.length || false}
          thContent={
            <>
              {thLabels.map((v) => (
                <TableCell key={v}>{v}</TableCell>
              ))}
            </>
          }
        >
          {records?.items?.map((item, i) => (
            <StyledTableRow key={i}>
              <StyledTableCell>
                {item?.product?.name || "Custom product"}
              </StyledTableCell>
              <StyledTableCell>
                {_symbol}&nbsp;
                {item?.price}
              </StyledTableCell>
              <StyledTableCell>{item?.quantity}</StyledTableCell>
              <StyledTableCell>
                {_symbol}&nbsp;
                {item?.sub_total}
              </StyledTableCell>
              <StyledTableCell>{item?.refund_quantity}</StyledTableCell>
              <StyledTableCell>
                {_symbol}&nbsp;
                {item?.refund_amount}
              </StyledTableCell>
              <StyledTableCell>
                {_symbol} &nbsp;
                {item?.price - item?.refund_amount}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableWrapper>
      </Box>
    </div>
  );
};

const thLabels = [
  "Product Name",
  "Price",
  "Quantity",
  "Amount",
  "Refunded Quantity",
  "Refunded Amount",
  "Sub Total",
];

export default RevenueReportDetail;
