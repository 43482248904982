import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Stack,
  TableCell,
  IconButton,
  Tooltip,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";
import { useDispatch } from "react-redux";
import EventIcon from "@mui/icons-material/Event";

import {
  StyledTableCell,
  StyledTableRow,
  TablePagination,
  TableWrapper,
  ExportBtn,
} from "../../../component";
import { handleLoader } from "../../../store/reducer";
import ApiManager from "../../../services/api-manager";
import usePageTitle from "../../../hooks/use-page-title";
import Utils from "../../../utils/utils";

const SpOrganizer = () => {
  usePageTitle("Organizer");
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState(null);
  const [columns, setColumns] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    getData();
  }, [page, rowsPerPage]);

  const getData = async () => {
    try {
      setIsLoading(true);
      let { data } = await ApiManager(
        "get",
        `superAdmin/get/organizers?role=organizer&page=${page}&per_page=${rowsPerPage}`,
      );
      setRecords(data?.users);
      setColumns(data?.columns);
    } catch (error) {
      console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const onChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleStatus = async (e, item) => {
    let updateRecord = records?.data;
    let _index = updateRecord?.findIndex((x) => x?.user_id === item?.user_id);
    if (_index >= 0) {
      updateRecord[_index].status =
        e.target.checked === true ? "active" : "inactive";
      setRecords({ ...records, data: updateRecord });
    }
    let _fd = {
      user_id: item?.user_id,
      status: e.target.checked === true ? "active" : "inactive",
    };
    try {
      dispatch(handleLoader(true));
      await ApiManager(
        "post",
        `superAdmin/update/organizer/${item?.user_id}`,
        _fd,
      );
    } catch (error) {
      // console.log(error);
    } finally {
      dispatch(handleLoader(false));
    }
  };

  const thLabels = [
    "ID",
    "First Name",
    "Last Name",
    "Email",
    "Account Type",
    "Status",
    "Actions",
  ];

  return (
    <div>
      <Box>
        <Typography variant="h4" color="initial" fontWeight={"bold"} m={2}>
          Organizers
        </Typography>
        <Stack direction="row" m={2}>
          <Button
            onClick={() => navigate("/admin/organizer/create")}
            startIcon={<AddIcon />}
          >
            Add organizer
          </Button>
          <ExportBtn path={`reports-admin-organizers-csv?`} data={columns} />
        </Stack>

        <TableWrapper
          pagination={
            <TablePagination
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              perPage={records?.per_page || 0}
              total={records?.total || 0}
            />
          }
          spanTd={thLabels.length}
          isLoading={isLoading}
          isContent={records?.data?.length || false}
          thContent={
            <>
              {thLabels.map((v) => (
                <TableCell key={v}>{v}</TableCell>
              ))}
            </>
          }
        >
          {records?.data.map((item, i) => (
            <StyledTableRow key={i}>
              <StyledTableCell>{item?.user_id}</StyledTableCell>
              <StyledTableCell>{item?.first_name}</StyledTableCell>
              <StyledTableCell>{item?.last_name}</StyledTableCell>
              <StyledTableCell>{item?.email}</StyledTableCell>
              <StyledTableCell>
                {Utils.capitalize(Utils.removeUnderscore(item?.role || ""))}
              </StyledTableCell>
              <StyledTableCell>
                <FormControlLabel
                  control={
                    <Switch
                      checked={item?.status === "active" ? true : false}
                      onChange={(e) => handleStatus(e, item)}
                    />
                  }
                />
              </StyledTableCell>
              <StyledTableCell>
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <Tooltip title="Edit" placement="top">
                    <IconButton
                      color="info"
                      onClick={() =>
                        navigate(`/admin/organizer/update/${item?.user_id}`)
                      }
                    >
                      <CreateRoundedIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="View Events" placement="top">
                    <IconButton
                      color="info"
                      onClick={() =>
                        navigate(`/admin/organizer/${item?.user_id}/events`)
                      }
                    >
                      <EventIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableWrapper>
      </Box>
    </div>
  );
};

export default SpOrganizer;
