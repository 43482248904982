import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Stack,
  Divider,
  Button,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";

import { InputField, SelectBox } from "../../../../../component";
import { handleLoader, setToast } from "../../../../../store/reducer";
import errorsSetter from "../../../../../helpers/error-setter";
import ApiManager from "../../../../../services/api-manager";
import usePageTitle from "../../../../../hooks/use-page-title";

const CreateAccessPoint = () => {
  const { id } = useParams();
  const params = useParams();
  const accessPointId = params?.accessPointId;
  usePageTitle("Create Access Control Point");
  const [formData, setFormData] = useState({
    name: "",
    status: "active",
  });
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const _path = `/event/${id}/management/access-control/access-control-points`;

  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));

  useEffect(() => {
    async function init() {
      if (accessPointId) {
        try {
          dispatch(handleLoader(true));
          let { data } = await ApiManager(
            "get",
            `organizer/access-control-points/${accessPointId}`,
          );
          setFormData(data);
        } catch (error) {
          showMessage("error", error?.response?.data?.error?.message);
        } finally {
          dispatch(handleLoader(false));
        }
      }
    }
    init();
  }, [accessPointId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      dispatch(handleLoader(true));
      setFormErrors({});
      let _url = accessPointId
        ? `organizer/access-control-points/${accessPointId}`
        : "organizer/access-control-points";
      let _fd = { ...formData, event_id: id };
      if (accessPointId) {
        _fd._method = "patch";
      }
      await ApiManager("post", _url, _fd);
      if (accessPointId) {
        showMessage("success", "Access control point updated successfully.");
      } else {
        showMessage("success", "Access control point created successfully.");
      }
      navigate(_path);
    } catch (error) {
      if (error?.response?.status === 422) {
        setFormErrors(errorsSetter(error));
      } else {
        showMessage("error", error?.response?.data?.error?.message);
      }
    } finally {
      dispatch(handleLoader(false));
    }
  };

  return (
    <Box m={{ sm: 2 }}>
      <Box component={Paper} elevation={6} p={2}>
        <Box
          component="form"
          autoComplete="off"
          autoCapitalize="off"
          onSubmit={handleSubmit}
        >
          <Typography variant="h5">
            {accessPointId ? "Update" : "Create"} Access Control Point
          </Typography>
          <Typography variant="body1">
            Create an access control point to manage attendee ingress/ egress
            into specific area
          </Typography>

          <Grid container columnSpacing={3} mt={2} rowSpacing={2}>
            <Grid item sm={6} xs={12}>
              <Box>
                <InputField
                  label="Name"
                  size="small"
                  name="name"
                  required
                  error={formErrors?.name}
                  value={formData?.name}
                  onChange={handleInputChange}
                />
              </Box>
            </Grid>
            <Grid item sm={6} xs={12}>
              <SelectBox
                fullWidth
                items={[
                  { label: "Active", value: "active" },
                  { label: "Disable", value: "inactive" },
                ]}
                label="Status"
                size="small"
                name="status"
                required
                error={formErrors?.status}
                value={formData?.status}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
          <Divider
            sx={{
              mt: 5,
            }}
          />
          <Stack direction="row" gap={2} mt={2}>
            <Button
              variant="contained"
              type="submit"
              sx={{
                color: "white",
              }}
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => navigate(_path)}
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateAccessPoint;
