import React, { useRef, useState } from "react";
import { StyledTableCell, StyledTableRow } from "../../../component";
import { MenuItem, Stack } from "@mui/material";
import DropDown from "../../../component/dropdown/dropdown";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate, useParams } from "react-router-dom";

const TableDataRow = ({ item, isAdmin, event }) => {
  const dropDownRef = useRef(null);

  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <>
      <StyledTableRow>
        <StyledTableCell>{item?.test_card_id}</StyledTableCell>
        <StyledTableCell>{item?.open_id}</StyledTableCell>
        <StyledTableCell>
          {event?.currency?.currency_symbol}
          {item?.total_spent}
        </StyledTableCell>
        <StyledTableCell>
          {event?.currency?.currency_symbol}
          {item?.total_topup_card}
        </StyledTableCell>
        <StyledTableCell>
          {event?.currency?.currency_symbol}
          {item?.total_topup_cash}
        </StyledTableCell>
        <StyledTableCell>
          <Stack direction="row" spacing={0.5} alignItems="center">
            <DropDown useIconBtn icon={<MoreVertIcon />} ref={dropDownRef}>
              <MenuItem
                onClick={() => {
                  navigate(
                    `/event/${id}/test-card/get-order-against-card/${item?.test_card_id}`,
                  );
                  dropDownRef.current.closeMenu();
                }}
              >
                Total spent
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate(
                    `/event/${id}/test-card/get-topup-against-card/${item?.test_card_id}?type=credit_card`,
                  );
                  dropDownRef.current.closeMenu();
                }}
              >
                Total top up by card
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate(
                    `/event/${id}/test-card/get-topup-against-card/${item?.test_card_id}?type=cash`,
                  );
                  dropDownRef.current.closeMenu();
                }}
              >
                Total top up by cash
              </MenuItem>
            </DropDown>
          </Stack>
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
};

export default TableDataRow;
