import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  CircularProgress,
  Stack,
  TableCell,
  Paper,
  Card,
  CardContent,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { BarChart } from "@mui/x-charts";
import _ from "lodash";

import {
  AdminHeader,
  ChartContainer,
  ModalWrapper,
  StyledTableCell,
  StyledTableRow,
  TablePagination,
  TableWrapper,
} from "../../component";
import Utils from "../../utils/utils";

const VendorDetails = () => {
  const { id } = useParams();
  const [records, setRecords] = useState({});
  const [orders, setOrders] = useState({});
  const [isOrdersLoading, setIsOrdersLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [orderDetails, setOrderDetails] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);

  // manage data for graph
  const revenueValuesForGraph = records?.revenue_graph?.length
    ? _?.map(records?.revenue_graph, "revenue")?.map((value) => Number(value))
    : [0];
  const datesForGraph = _?.map(records?.revenue_graph, "order_date");

  useEffect(() => {
    const init = async () => {
      try {
        setIsLoading(true);
        let { data } = await axios.post(
          `${process.env.REACT_APP_API_KEY}vendor-stats/${id}`,
          {},
        );
        delete data?.orders;
        setRecords(data);
      } catch (error) {
        console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
      } finally {
        setIsLoading(false);
      }
    };
    init();
  }, []);

  useEffect(() => {
    const getOrders = async () => {
      try {
        setIsOrdersLoading(true);
        let { data } = await axios.post(
          `${process.env.REACT_APP_API_KEY}vendor-stats/${id}?page=${page}&per_page=${rowsPerPage}`,
          {},
        );
        setOrders(data?.orders);
      } catch (error) {
        console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
      } finally {
        setIsOrdersLoading(false);
      }
    };
    getOrders();
  }, [page, rowsPerPage]);

  const onChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const curr_code = records?.event_details?.currency?.currency_code;

  return (
    <Box>
      <AdminHeader hideProfile />
      <Box
        sx={{
          width: "100%",
          display: "flex",
        }}
      >
        <main
          style={{
            width: "100%",
            padding: "16px",
          }}
        >
          <Container maxWidth="100%">
            <Grid mt={4} container spacing={2}>
              <Grid item lg={9} md={8} xs={12} sx={{ order: { md: 1, xs: 2 } }}>
                <Box sx={styles.bg}>
                  <Typography mb={2} sx={{ fontSize: 18, fontWeight: 600 }}>
                    Revenue
                  </Typography>
                  <Typography mb={2} sx={{ fontSize: 18, fontWeight: 600 }}>
                    Total Revenue {curr_code} {records?.revenue || 0}
                  </Typography>
                  <Box mt={2}>
                    {/* <ChartContainer
                      title="Revenue"
                      labels={
                        records?.revenue_graph &&
                        records?.revenue_graph.map((item) => item?.order_date)
                      }
                      values={
                        records?.revenue_graph &&
                        records?.revenue_graph.map((item) => item?.revenue)
                      }
                    /> */}

                    <BarChart
                      xAxis={[{ scaleType: "band", data: datesForGraph }]}
                      series={[
                        {
                          data: revenueValuesForGraph,
                          stack: "A",
                          label: "Revenue",
                        },
                      ]}
                      colors={["#0081C5"]}
                      slotProps={{ legend: { hidden: true } }}
                      height={400}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={3} md={4} xs={12} sx={{ order: 0 }}>
                <Box sx={styles.bg}>
                  <Typography mb={2} sx={{ fontSize: 18, fontWeight: 600 }}>
                    Event - {records?.event_details?.name || "...loading"}
                  </Typography>
                  <Card variant="outlined">
                    {isLoading ? (
                      <Stack alignItems="center" py={3}>
                        <CircularProgress size={26} />
                      </Stack>
                    ) : (
                      <CardContent>
                        <Box maxWidth={500}>
                          <Box component="table" width="100%">
                            <tr>
                              <td width="50%">
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  Currency Name
                                </Typography>
                              </td>
                              <td width="50%">
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  {
                                    records?.event_details?.currency
                                      ?.currency_name
                                  }
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td width="50%">
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  Currency Code
                                </Typography>
                              </td>
                              <td width="50%">
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  {
                                    records?.event_details?.currency
                                      ?.currency_code
                                  }
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td width="50%">
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  Event Start Date
                                </Typography>
                              </td>
                              <td width="50%">
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  {records?.event_details?.start_date}
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td width="50%">
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  Event End Date
                                </Typography>
                              </td>
                              <td width="50%">
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  {records?.event_details?.end_date}
                                </Typography>
                              </td>
                            </tr>
                          </Box>
                          <Typography
                            variant="body2"
                            color="text.primary"
                            sx={{ fontWeight: "bold", pt: 2, pb: 1 }}
                          >
                            Vendor Details
                          </Typography>
                          <Box component="table" width="100%">
                            <tr>
                              <td width="50%">
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  Name
                                </Typography>
                              </td>
                              <td width="50%">
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  {records?.vendor?.name}
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td width="50%">
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  Total SKU
                                </Typography>
                              </td>
                              <td width="50%">
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  {records?.vendor?.total_sku}
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td width="50%">
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  Sales
                                </Typography>
                              </td>
                              <td width="50%">
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  {curr_code} {records?.vendor?.sales}
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td width="50%">
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  Expense
                                </Typography>
                              </td>
                              <td width="50%">
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  {curr_code} {records?.vendor?.expense}
                                </Typography>
                              </td>
                            </tr>
                          </Box>
                        </Box>
                      </CardContent>
                    )}
                  </Card>
                </Box>
              </Grid>

              {/* <Grid item md={3} xs={12} sx={{ order: { md: 2, xs: 1 } }}>
                <Box sx={styles.bg}>
                  <Typography mb={2} sx={{ fontSize: 18, fontWeight: 600 }}>
                    Top Products
                  </Typography>
                  {isLoading ? (
                    <Stack alignItems="center">
                      <CircularProgress size={26} />
                    </Stack>
                  ) : (
                    <Box>
                      {records?.top_vendor_products?.length ? (
                        records?.top_vendor_products.map((item, index) => {
                          return (
                            <Stack
                              key={index}
                              direction="row"
                              spacing={1}
                              justifyContent="space-between"
                              py={1}
                            >
                              <Typography>{item?.name}</Typography>
                              <Typography sx={{ whiteSpace: "nowrap" }}>
                                {curr_code} {item?.total}
                              </Typography>
                            </Stack>
                          );
                        })
                      ) : (
                        <Typography sx={{ textAlign: "center" }}>
                          No Records to Show
                        </Typography>
                      )}
                    </Box>
                  )}
                </Box>
              </Grid> */}

              <Grid item xs={12} sx={{ order: 3 }}>
                <Box sx={styles.bg}>
                  <TableWrapper
                    pagination={
                      <TablePagination
                        onChangePage={onChangePage}
                        onChangeRowsPerPage={onChangeRowsPerPage}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        perPage={orders?.per_page || 0}
                        total={orders?.total || 0}
                      />
                    }
                    spanTd={thLabels.length}
                    isLoading={isOrdersLoading}
                    isContent={orders?.data?.length || false}
                    thContent={
                      <>
                        {thLabels.map((v) => (
                          <TableCell key={v}>{v}</TableCell>
                        ))}
                      </>
                    }
                  >
                    {orders?.data?.length &&
                      orders?.data?.map((item, i) => (
                        <StyledTableRow key={i}>
                          <StyledTableCell>{item?.order_id}</StyledTableCell>
                          <StyledTableCell>{item?.code}</StyledTableCell>
                          <StyledTableCell>
                            {Utils.capitalize(item?.order_status)}
                          </StyledTableCell>
                          <StyledTableCell>
                            {Utils.capitalize(item?.payment_status)}
                          </StyledTableCell>
                          <StyledTableCell>
                            {curr_code} {item?.tip}
                          </StyledTableCell>
                          <StyledTableCell>{item?.total_items}</StyledTableCell>
                          <StyledTableCell>
                            {curr_code} {item?.amount}
                          </StyledTableCell>
                          <StyledTableCell>
                            {curr_code} {item?.refund_amount}
                          </StyledTableCell>
                          <StyledTableCell>
                            {curr_code} {item?.actual_amount}
                          </StyledTableCell>
                          <StyledTableCell>
                            <Tooltip title="View" placement="top">
                              <IconButton
                                onClick={() => {
                                  setModalState(true);
                                  setOrderDetails(item);
                                }}
                              >
                                <VisibilityIcon color="primary" />
                              </IconButton>
                            </Tooltip>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableWrapper>
                </Box>
              </Grid>
            </Grid>
            <Stack>
              <Typography
                fontSize={"8px"}
                width={"30%"}
                mt={1}
                children="Disclaimer: Live transaction statistics are provided for informational purposes only. Please note that the displayed figures may be subject to inaccuracies until the final reports have been thoroughly extracted, verified, and approved by CXEvents and the event organizers."
              />
              <Typography
                fontSize={"8px"}
                width={"30%"}
                component={Link}
                to={"/terms-and-conditions"}
                target="blank"
                children="Please read the terms and conditions"
              />
            </Stack>
          </Container>
        </main>
      </Box>
      <ModalWrapper
        open={modalState}
        handleClose={() => setModalState(false)}
        title="Order Item Details"
        maxWidth="lg"
      >
        <TableWrapper
          spanTd={thLabels2.length}
          isContent={true}
          thContent={
            <>
              {thLabels2.map((v) => (
                <TableCell key={v}>{v}</TableCell>
              ))}
            </>
          }
        >
          {orderDetails?.items?.length &&
            orderDetails?.items?.map((item, i) => {
              return (
                <StyledTableRow key={i}>
                  <StyledTableCell>
                    {item?.product?.name || "Custom product"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {curr_code} {item?.price}
                  </StyledTableCell>
                  <StyledTableCell>{item?.quantity}</StyledTableCell>
                  <StyledTableCell>
                    {curr_code} {item?.product_tax}
                  </StyledTableCell>
                  <StyledTableCell>
                    {curr_code} {item?.sub_total}
                  </StyledTableCell>
                  <StyledTableCell>
                    {Utils.capitalize(item?.refunded) || "No"}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
        </TableWrapper>
      </ModalWrapper>
    </Box>
  );
};

const styles = {
  card: {
    bgcolor: "#fff",
    px: 2,
    py: 3,
    color: "#333",
    borderRadius: 2,
    boxShadow: "0 10px 10px #00000017",
    transition: "0.2s all ease-in-out",
    ":hover": {
      bgcolor: "primary.main",
      color: "#fff",
    },
  },
  bg: {
    bgcolor: "#fff",
    px: 2,
    py: 3,
    color: "#333",
    borderRadius: 2,
    boxShadow: "0 10px 10px #00000017",
    height: "100%",
  },
  title: {
    fontSize: { md: 24, xs: 18 },
    fontWeight: 500,
    fontFamily: "Work Sans",
  },
  numbers: {
    fontSize: { md: 32, xs: 22 },
    fontWeight: 600,
    textAlign: "end",
  },
};

const thLabels = [
  "Order ID",
  "RFID No",
  "Order Status",
  "Payment Status",
  "Tip",
  "Number of item",
  "Amount",
  "Refund Amount",
  "Actual Amount",
  "Details",
];
const thLabels2 = [
  "Product Name",
  "Price",
  "Quantity",
  "Tax",
  "Total Amount",
  "Refunded",
];

export default VendorDetails;
