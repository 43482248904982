import React from "react";
import {
  Box,
  Typography,
  Stack,
  IconButton,
  Modal,
  Paper,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { QRCodeSVG } from "qrcode.react";

const QrCodeModal = ({ open, close, ticketObj }) => {
  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Box sx={{ background: "#fff", width: 500 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          py={1}
          px={2}
          sx={{ backgroundColor: "primary.main" }}
        >
          <Typography color="#fff" fontWeight={600}>
            Add Device
          </Typography>
          <IconButton onClick={close}>
            <CloseIcon sx={{ color: "#fff" }} />
          </IconButton>
        </Stack>
        <Box p={2}>
          <Typography component="p" fontSize="12px" align="center">
            Scan the OR code below with the device camera in order to link it
            with the event
          </Typography>
          <Paper elevation={6} sx={{ p: 2, mt: 1 }}>
            <QRCodeSVG value={ticketObj} width="100%" height="auto" />
          </Paper>
        </Box>
      </Box>
    </Modal>
  );
};

export default QrCodeModal;
