import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Stack, TableCell } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import moment from "moment/moment";
import { useSelector } from "react-redux";

import {
  ExportBtn,
  InputField,
  StyledTableCell,
  StyledTableRow,
  TablePagination,
  TableWrapper,
} from "../../../../component";
import ApiManager from "../../../../services/api-manager";
import usePageTitle from "../../../../hooks/use-page-title";
import Utils from "../../../../utils/utils";
import RefreshIcon from "@mui/icons-material/Refresh";

const GetTotalAmount = () => {
  usePageTitle("In Person TopUp Report");
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const paymentType = params.get("type"); // bar
  const { deviceID } = useParams();
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState(null);
  const { id } = useParams();
  const { user, loggedEvent } = useSelector((state) => state.storeReducer);
  const _role = user?.role === "super_admin" ? "superAdmin" : "organizer";
  const _symbol = loggedEvent.currencySymbol;
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    getData();
  }, [page, rowsPerPage, id]);

  const getData = async (_search = "") => {
    try {
      setIsLoading(true);
      let { data } = await ApiManager(
        "get",
        `${_role}/device-amount-collected/${deviceID}/${paymentType}?page=${page}&per_page=${rowsPerPage}`,
      );
      setRecords(data);
      setColumns(data?.columns);
    } catch (error) {
      console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const onChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <Box>
        <Typography variant="h4" color="initial" fontWeight={"bold"} m={2}>
          {paymentType === "cash"
            ? " Total Cash Collected"
            : "Total Credit card Collected"}
        </Typography>
        <Typography variant="h6" color="initial" m={2}>
          Name: {records?.device?.name ? records?.device?.name : "Loading..."}{" "}
          <br />
          Email:{" "}
          {records?.device?.email ? records?.device?.email : "Loading..."}
        </Typography>

        <Stack
          direction={{ sm: "row", xs: "column" }}
          justifyContent={{ sm: "space-between", xs: "center" }}
          my={2}
        >
          <Stack
            direction={{ sm: "row", xs: "column" }}
            justifyContent={{ sm: "start", xs: "center" }}
          >
            <Button startIcon={<RefreshIcon />} onClick={() => getData()}>
              Refresh
            </Button>
            {/* <ExportBtn data={columns} path={`reports-event-topups-csv?event_id=${id}&`} /> */}
          </Stack>
        </Stack>

        <TableWrapper
          pagination={
            <TablePagination
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              perPage={records?.topups?.per_page || 0}
              total={records?.topups?.total || 0}
            />
          }
          spanTd={thLabels.length}
          isLoading={isLoading}
          isContent={records?.topups?.data?.length || false}
          thContent={
            <>
              {thLabels.map((v) => (
                <TableCell key={v}>{v}</TableCell>
              ))}
            </>
          }
        >
          {records?.topups?.data?.map((item, i) => (
            <StyledTableRow key={i}>
              <StyledTableCell>{item?.code}</StyledTableCell>

              <StyledTableCell>
                {_symbol}
                {Utils.formatWithSuffix(item?.amount)}
              </StyledTableCell>
              <StyledTableCell>
                {item?.payment_type === "credit_card" ? "Credit card" : "Cash"}
              </StyledTableCell>
              <StyledTableCell>
                {item?.status === "AUTHORIZED" ? "Success" : "Failed"}
              </StyledTableCell>
              <StyledTableCell>
                {moment(item?.created_at).format(
                  process.env.REACT_APP_DATE_FORMAT,
                )}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableWrapper>
      </Box>
    </div>
  );
};

const thLabels = [
  "RFID Number",
  "Amount",
  "Payment type",
  "Status",
  "Created At",
];

export default GetTotalAmount;
