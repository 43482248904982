import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
  TableCell,
  Tooltip,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";

import {
  StyledTableCell,
  StyledTableRow,
  TablePagination,
  TableWrapper,
} from "../../../../component";
import { handleLoader, setToast } from "../../../../store/reducer";
import ApiManager from "../../../../services/api-manager";
import usePageTitle from "../../../../hooks/use-page-title";

const Category = () => {
  const { id, vendorID } = useParams();
  usePageTitle("Category");
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loggedEvent, user } = useSelector((state) => state.storeReducer);
  const _symbol = loggedEvent.currencySymbol;
  const _role = user?.role === "super_admin" ? "superAdmin" : "organizer";
  const _isAdmin = user?.role === "super_admin" ? true : false;
  const [columns, setColumns] = useState([]);

  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));

  useEffect(() => {
    getData();
  }, [page, rowsPerPage]);

  const getData = async () => {
    try {
      setIsLoading(true);
      let { data } = await ApiManager(
        "get",
        `${_role}/product-categories?event_id=${id}`,
      );
      setRecords(data);
    } catch (error) {
      console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const onChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleStatus = async (e, item, index) => {
    setRecords((prev) => ({
      ...prev,
      data: prev?.data.map((obj) => {
        if (obj.category_id === item.category_id) {
          return { ...obj, active: e.target.checked };
        }
        return obj;
      }),
    }));

    try {
      dispatch(handleLoader(true));
      await ApiManager("get", `organizer/update-status/${item?.category_id}`);
      showMessage("success", "Status updated successfully.");
    } catch (error) {
      showMessage("error", error?.response?.data?.error?.message);
    } finally {
      dispatch(handleLoader(false));
    }
  };

  const thLabels = ["Id", "Name", "Status"];

  if (!_isAdmin) {
    thLabels.push("Manage");
  }

  return (
    <div>
      <Typography variant="h4" color="initial" fontWeight={"bold"} m={2}>
        Product Categories
      </Typography>
      <Stack direction={{ sm: "row", xs: "column" }} my={2}>
        {!_isAdmin && (
          <>
            <Button
              onClick={() =>
                navigate(`/event/${id}/management/cashless/category/create`)
              }
              startIcon={<AddIcon />}
            >
              Add Category
            </Button>
          </>
        )}
      </Stack>

      <TableWrapper
        pagination={
          <TablePagination
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            perPage={records?.per_page || 0}
            total={records?.total || 0}
          />
        }
        spanTd={thLabels.length}
        isLoading={isLoading}
        isContent={records?.data?.length || false}
        thContent={
          <>
            {thLabels.map((v) => (
              <TableCell key={v}>{v}</TableCell>
            ))}
          </>
        }
      >
        {records?.data.map((item, i) => (
          <StyledTableRow key={i}>
            <StyledTableCell>{item?.category_id}</StyledTableCell>

            <StyledTableCell>{item?.title}</StyledTableCell>
            <StyledTableCell>
              <FormControlLabel
                control={
                  <Switch
                    checked={item?.active}
                    disabled={_isAdmin}
                    onChange={(e) => handleStatus(e, item, i)}
                  />
                }
              />
            </StyledTableCell>
            {!_isAdmin && (
              <StyledTableCell>
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <Tooltip title="Edit" placement="top">
                    <IconButton
                      color="info"
                      onClick={() =>
                        navigate(
                          `/event/${id}/management/cashless/category/update/${item?.category_id}`,
                        )
                      }
                    >
                      <CreateRoundedIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </StyledTableCell>
            )}
          </StyledTableRow>
        ))}
      </TableWrapper>
    </div>
  );
};

export default Category;
