import React, { Fragment, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Stack,
  Button,
  IconButton,
  Fab,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { SelectBox } from "../../../../../component";
import { handleLoader, setToast } from "../../../../../store/reducer";
import errorsSetter from "../../../../../helpers/error-setter";
import ApiManager from "../../../../../services/api-manager";
import usePageTitle from "../../../../../hooks/use-page-title";
import { CancelRounded } from "@mui/icons-material";

// const initState=[{
//   ticket_type: "",
//   action: "",
//   status: "",
//   decrement_count: 0,
// }]

const AccessPointSetting = () => {
  usePageTitle("Access Control Rules");
  const { id } = useParams();
  const params = useParams();
  const acp_id = params?.accessPointId;
  const [formData, setFormData] = useState([]);
  const [ticketTypes, setTicketTypes] = useState([]);
  const [deleteRules, setDeleteRules] = useState([]);
  const [acpName, setAcpName] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.storeReducer);
  const _isAdmin = user?.role === "super_admin" ? true : false;
  const _role = user?.role === "super_admin" ? "superAdmin" : "organizer";
  const _path = `/event/${id}/management/access-control/access-control-points`;

  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));

  let filteredTypes = ticketTypes.filter(
    (ticketType) =>
      !formData.some(
        (formDataItem) => formDataItem?.ticket_type === ticketType?.value,
      ),
  );

  React.useEffect(() => {
    async function init() {
      getData();
      try {
        let { data } = await ApiManager(
          "get",
          `${_role}/ticket-types?event_id=${id}`,
        );
        if (data?.data?.length > 0) {
          let _arr = data?.data.map((x) => ({
            label: x?.name,
            value: x?.ticket_type_id,
          }));
          setTicketTypes(_arr);
        } else {
          showMessage("warning", "Please add minimum one ticket type.");
          navigate(`/event/${id}/management/access-control/ticket-types`);
        }
      } catch (error) {
        showMessage("error", error?.response?.data?.error?.message);
      }
    }
    init();
  }, [acp_id]);

  const getData = async () => {
    try {
      dispatch(handleLoader(true));
      let { data } = await ApiManager(
        "get",
        `${_role}/access-control-points/${acp_id}?event_id=${id}`,
      );
      setFormData(data?.rules);
      setAcpName(data?.name);
    } catch (error) {
      showMessage("error", error?.response?.data?.error?.message);
    } finally {
      dispatch(handleLoader(false));
    }
  };

  const handleInputChange = (e, i) => {
    const { name, value } = e.target;
    let _prev = [...formData];
    _prev[i][name] = value;
    setFormData(_prev);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      dispatch(handleLoader(true));
      let _fd = { acp_id };
      if (formData?.length) {
        _fd.rules = formData;
      }
      if (deleteRules?.length) {
        _fd.deleted_rules = deleteRules;
      }
      setFormErrors({});
      await ApiManager("post", "organizer/access-control-rules", _fd);
      showMessage("success", "Access control point updated successfully.");
    } catch (error) {
      if (error?.response?.status === 422) {
        setFormErrors(errorsSetter(error));
      } else {
        showMessage("error", error?.response?.data?.error?.message);
      }
    } finally {
      dispatch(handleLoader(false));
    }
  };

  const handleAdd = (e) => {
    setFormData((prev) => [
      ...prev,
      {
        ticket_type: "",
        action: "pass_through",
        status: "active",
        decrement_count: 0,
      },
    ]);
  };

  const handelRemove = (index) => {
    if (formData[index]?.rule_id) {
      setDeleteRules((prev) => [...prev, formData[index]?.rule_id]);
    }
    setFormData((prevState) => prevState.filter((_, i) => i !== index));
  };

  const fromInputs = (handelChange = (e, i) => {}, index) => {
    return (
      <Grid container columnSpacing={2} mt={2} rowSpacing={2}>
        <Grid item md={4} sm={3} xs={12}>
          <Box>
            {ticketTypes.length ? (
              <SelectBox
                label="Ticket Type"
                size="small"
                name="ticket_type"
                required
                fullWidth
                items={
                  formData[index]?.ticket_type === ""
                    ? filteredTypes
                    : filteredTypes.concat(
                        ticketTypes.filter(
                          (item) => item.value === formData[index]?.ticket_type,
                        ),
                      )
                }
                error={formErrors[index]?.ticket_type}
                value={formData[index]?.ticket_type}
                onChange={(e) => handelChange(e, index)}
              />
            ) : (
              <Typography>...loading ticket types</Typography>
            )}
          </Box>
        </Grid>
        <Grid item sm={3} xs={12}>
          <SelectBox
            fullWidth
            items={[{ label: "Pass Through ", value: "pass_through" }]}
            label="Action"
            size="small"
            name="action"
            required
            error={formErrors[index]?.action}
            value={formData[index]?.action}
            onChange={(e) => handelChange(e, index)}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <SelectBox
            fullWidth
            items={[
              { label: "Active ", value: "active" },
              { label: "Disable ", value: "inactive" },
            ]}
            label="Status"
            size="small"
            name="status"
            required
            error={formErrors[index]?.status}
            value={formData[index]?.status}
            onChange={(e) => handelChange(e, index)}
          />
        </Grid>
        {!_isAdmin && (
          <Grid item sm={1} xs={12}>
            <IconButton color="error" onClick={() => handelRemove(index)}>
              <CancelRounded />
            </IconButton>
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <Box m={{ sm: 2 }}>
      <Box component={Paper} elevation={5} p={2}>
        <Fab
          size="small"
          onClick={() =>
            navigate(
              `/event/${id}/management/access-control/access-control-points`,
            )
          }
        >
          <ArrowBackIcon />
        </Fab>
        <br />
        <br />
        <Box
          component="form"
          autoComplete="off"
          autoCapitalize="off"
          onSubmit={handleSubmit}
        >
          <Typography variant="h5">
            {acpName || "...Loading"} - Access Control Rules{" "}
          </Typography>
          <Typography variant="body1">
            Specify access control rule for ticket present on rfid that are
            shown or trunstile configured to this access control point
          </Typography>
          <Typography variant="body1">
            Ticket that rae not included in the following ruleset will be
            rejected by scanner the "count" value should be only specified when
            wishing to decrement the count of a counter ticket type
          </Typography>

          <Button
            variant="contained"
            onClick={handleAdd}
            sx={{
              color: "white",
              mt: 2,
            }}
            disabled={_isAdmin}
          >
            Add Access Control Rule
          </Button>
          {!formData.length && (
            <Box mt={2}>
              <Button
                variant="text"
                onClick={handleAdd}
                sx={{
                  p: 4,
                  width: "100%",
                }}
                disabled={_isAdmin}
              >
                <Typography>Click to add rule.</Typography>
              </Button>
            </Box>
          )}
          {formData.map((_, index) => (
            <Fragment key={index}>
              {" "}
              {fromInputs(handleInputChange, index)}
            </Fragment>
          ))}
          <Stack direction="row" gap={2} mt={3}>
            {!_isAdmin && (
              <Button
                variant="contained"
                type="submit"
                sx={{
                  color: "white",
                }}
              >
                Save
              </Button>
            )}
            <Button
              variant="contained"
              color="error"
              onClick={() => navigate(_path)}
            >
              Return
            </Button>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default AccessPointSetting;
