import React, { useState } from "react";
import { Box, Button, Stack, Typography, InputAdornment } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import PersonIcon from "@mui/icons-material/Person";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { InputField, PasswordFiled } from "../../component";
import AuthLayout from "./auth-layout";
import usePageTitle from "../../hooks/use-page-title";
import {
  handleLoader,
  handleOnBoardUI,
  setToast,
  setUser,
} from "../../store/reducer";
import ApiManager from "../../services/api-manager";
import errorsSetter from "../../helpers/error-setter";

function Login() {
  usePageTitle("Login");
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { email, password } = formData;
  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password.length < 6) {
      return setFormErrors((prev) => ({
        ...prev,
        password: "Should be at least 6 character long.",
      }));
    }
    try {
      dispatch(handleLoader(true));
      setFormErrors({});
      let { data } = await ApiManager("post", "login-super", formData);
      dispatch(setUser(data?.user));
      localStorage.setItem(process.env.REACT_APP_TOKEN, data?.access_token);
      let value = localStorage.getItem("@EVENTSIFY_ONBOARD_UI");
      // if (!value) {
      //   dispatch(handleOnBoardUI(true));
      // }
      navigate("/");
    } catch (error) {
      if (error?.response?.status === 422) {
        setFormErrors(errorsSetter(error));
      }
      if (error?.response?.status === 400) {
        showMessage("error", error?.response?.data);
      } else {
        showMessage("error", error?.response?.data?.error?.message);
      }
    } finally {
      dispatch(handleLoader(false));
    }
  };

  return (
    <AuthLayout>
      <Box
        component="form"
        autoComplete="off"
        autoCapitalize="off"
        onSubmit={handleSubmit}
      >
        <Stack spacing={2}>
          <Typography
            variant="h4"
            color="initial"
            fontWeight={"bold"}
            textAlign="center"
            mb={2}
          >
            Login
          </Typography>
          <InputField
            labelTop="Email"
            size="large"
            placeholder="john@example.com"
            required
            type="email"
            name="email"
            value={email}
            error={formErrors?.email}
            onChange={handleInputChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonIcon />
                </InputAdornment>
              ),
            }}
          />
          <PasswordFiled
            size="large"
            labelTop="Password"
            placeholder="xxxxxx"
            required
            name="password"
            error={formErrors?.password}
            value={password}
            onChange={handleInputChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ),
            }}
          />
          <Box
            sx={{
              mt: "5px !important",
              textAlign: "right",
              pb: 2,
            }}
          >
            <Box
              component={Link}
              to="/forgot-password"
              sx={{ color: "primary.main" }}
            >
              Forgot password?
            </Box>
          </Box>
          <Button
            variant="contained"
            type="submit"
            size="large"
            sx={{ color: "#fff" }}
          >
            Login
          </Button>
        </Stack>
      </Box>
    </AuthLayout>
  );
}

export default Login;
