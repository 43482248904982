import React, { useEffect, useState } from "react";
import { Box, Fab, MenuItem, Typography } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSelector } from "react-redux";

import ApiManager from "../../../../../services/api-manager";

const ManageLayout = ({ children }) => {
  const { id, vendorID } = useParams();
  const navigate = useNavigate();
  const [vendorRecord, setVendorRecord] = useState(null);
  const { user } = useSelector((state) => state.storeReducer);
  const _role = user?.role === "super_admin" ? "superAdmin" : "organizer";
  const location = useLocation();

  useEffect(() => {
    async function init() {
      try {
        let { data } = await ApiManager("get", `${_role}/vendors/${vendorID}`);
        setVendorRecord(data);
      } catch (error) {
        console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
      }
    }
    init();
  }, []);

  return (
    <Box>
      <Fab
        size="small"
        onClick={() => navigate(`/event/${id}/management/cashless/vendors`)}
      >
        <ArrowBackIcon />
      </Fab>
      <Typography variant="h4" color="initial" fontWeight={"bold"} m={2}>
        Products of Vendor -{" "}
        {!!vendorRecord ? vendorRecord?.name : "...Loading"}
      </Typography>
      <Box sx={{ borderBottom: "1px solid #ccc", mt: 1 }}>
        <Box
          className="mycustom_tabs"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
          component={"ul"}
        >
          {myTabs(id, vendorID).map((v, i) => {
            return (
              <MenuItem
                key={i}
                selected={location.pathname === v.url}
                onClick={() => navigate(v.url)}
              >
                {v.title}
              </MenuItem>
            );
          })}
        </Box>
      </Box>
      <Box sx={{ pt: "15px", pb: "15px" }}>{children}</Box>
    </Box>
  );
};

const myTabs = (id, vendorID) => {
  return [
    {
      title: "Product",
      url: `/event/${id}/management/cashless/vendors/manage/${vendorID}/product`,
    },
    {
      title: "Cost",
      url: `/event/${id}/management/cashless/vendors/manage/${vendorID}/cost`,
    },
  ];
};

export default ManageLayout;
