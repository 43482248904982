import React, { useEffect, useState } from "react";
import { Box, Grid, Paper, Typography, Stack, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";

import { InputField, SelectBox } from "../../../../../component";
import { handleLoader, setToast } from "../../../../../store/reducer";
import errorsSetter from "../../../../../helpers/error-setter";
import ApiManager from "../../../../../services/api-manager";
import usePageTitle from "../../../../../hooks/use-page-title";

const EditTicket = () => {
  const { id } = useParams();
  const ticketID = useParams()?.ticketID || null;
  usePageTitle(ticketID ? "Update" : "Create" + " Ticket");
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [ticketTypes, setTicketTypes] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));
  const goBack = () =>
    navigate(`/event/${id}/management/access-control/tickets`);

  useEffect(() => {
    async function init() {
      try {
        let { data } = await ApiManager(
          "get",
          `organizer/ticket-types?event_id=${id}`,
        );
        if (data?.data?.length > 0) {
          let _arr = data?.data.map((x) => ({
            label: x?.name,
            value: x?.ticket_type_id,
          }));
          setTicketTypes(_arr);
        } else {
          showMessage("warning", "Please add minimum one ticket type.");
          navigate(`/event/${id}/management/access-control/ticket-types`);
        }
      } catch (error) {
        showMessage("error", error?.response?.data?.error?.message);
      }
      if (ticketID) {
        try {
          dispatch(handleLoader(true));
          let { data } = await ApiManager(
            "get",
            `organizer/tickets/${ticketID}`,
          );
          setFormData({
            name: data?.name,
            email: data?.email,
            ticket_type: data?.ticket_type?.ticket_type_id,
            qrcode: data?.qrcode,
          });
        } catch (error) {
          showMessage("error", error?.response?.data?.error?.message);
        } finally {
          dispatch(handleLoader(false));
        }
      }
    }
    init();
  }, [ticketID]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const validate = () => {
    let errors = {};
    let flag = true;
    setFormErrors((prev) => ({ ...prev, ...errors }));
    return flag;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        dispatch(handleLoader(true));
        setFormErrors({});
        let _url = ticketID
          ? `organizer/tickets/${ticketID}`
          : "organizer/tickets";
        let _fd = { ...formData, event: id };
        if (ticketID) {
          _fd._method = "patch";
        }
        await ApiManager("post", _url, _fd);
        if (ticketID) {
          showMessage("success", "Ticket updated successfully.");
        } else {
          showMessage("success", "Ticket created successfully.");
        }
        goBack();
      } catch (error) {
        if (error?.response?.status === 422) {
          setFormErrors(errorsSetter(error));
        } else {
          showMessage("error", error?.response?.data?.error?.message);
        }
      } finally {
        dispatch(handleLoader(false));
      }
    }
  };

  return (
    <Box m={{ sm: 2 }}>
      <Box component={Paper} elevation={6} p={2}>
        <Box
          component="form"
          autoComplete="off"
          autoCapitalize="off"
          onSubmit={handleSubmit}
        >
          <Typography variant="h5">
            {ticketID ? "Edit" : "Add"} Ticket
          </Typography>
          <Typography variant="body1">
            Add a ticket for fulfillment, ticket maybe added to attendees
            directly
          </Typography>

          <Grid container columnSpacing={3} mt={2} rowSpacing={2}>
            <Grid item sm={4} xs={12}>
              <Box>
                <InputField
                  label="Name"
                  size="small"
                  name="name"
                  required
                  error={formErrors?.name}
                  value={formData?.name}
                  onChange={handleInputChange}
                />
              </Box>
            </Grid>
            <Grid item sm={4} xs={12}>
              <InputField
                label="Email"
                size="small"
                name="email"
                required
                error={formErrors?.email}
                value={formData?.email}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              {ticketTypes.length ? (
                <SelectBox
                  fullWidth
                  items={ticketTypes}
                  label="Ticket Type"
                  size="small"
                  name="ticket_type"
                  required
                  error={formErrors?.ticket_type}
                  value={formData?.ticket_type}
                  onChange={handleInputChange}
                />
              ) : (
                <Typography>...loading ticket types</Typography>
              )}
            </Grid>
            <Grid item sm={4} xs={12}>
              <InputField
                fullWidth
                label="QR code"
                size="small"
                name="qrcode"
                required
                error={formErrors?.qrcode}
                value={formData?.qrcode}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
          <Stack direction="row" gap={2} mt={2}>
            <Button
              variant="contained"
              type="submit"
              sx={{
                color: "white",
              }}
            >
              Save
            </Button>
            <Button variant="contained" color="error" onClick={goBack}>
              Cancel
            </Button>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default EditTicket;
