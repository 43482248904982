import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Stack,
  Button,
  Divider,
  IconButton,
} from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import { InputDate, InputField, SelectBox } from "../../../../../../component";
import { handleLoader, setToast } from "../../../../../../store/reducer";
import errorsSetter from "../../../../../../helpers/error-setter";
import ApiManager from "../../../../../../services/api-manager";
import usePageTitle from "../../../../../../hooks/use-page-title";
import Utils from "../../../../../../utils/utils";
import moment from "moment";

const initialState = {
  vendor_id: "",
  products: [],
  redeem_type: "once",
};

const CreateUser = () => {
  const params = useParams();
  const { id } = params;
  const { user } = useSelector((state) => state.storeReducer);

  const voucherID = params?.voucherID;
  const _role = user?.role === "super_admin" ? "superAdmin" : "organizer";
  usePageTitle(voucherID ? "Update Voucher" : "Create Voucher");
  const [formData, setFormData] = useState(initialState);
  const [vendors, setVendors] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [ticketTypes, setTicketTypes] = useState([]);
  const [vendorProducts, setVendorProducts] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const _path = `/event/${id}/management/access-control/discount-vouchers`;

  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));

  useEffect(() => {
    if (!voucherID) dispatch(handleLoader(true));
    setIsLoading(true);
    async function gettingVendors() {
      try {
        let { data } = await ApiManager(
          "get",
          `${_role}/get-events-ticket-types-and-vendors/${id}`,
        );
        setTicketTypes(
          selectDataCreator(data?.ticket_types, "name", "ticket_type_id"),
        );
        setVendors(selectDataCreator(data?.vendors, "name", "vendor_id"));
        gettingVendorsProducts();
      } catch (error) {
        console.log(
          "🚀 ~ file: create-discount-vouchers.js:52 ~ gettingVendors ~ error:",
          error,
        );
      } finally {
        dispatch(handleLoader(false));
      }
    }
    async function gettingVendorsProducts() {
      try {
        let { data } = await ApiManager(
          "get",
          `${_role}/vendor-products?page=1&per_page=99999999999999999&vendor_id=${formData?.vendor_id}`,
        );
        setVendorProducts(
          selectDataCreator(data?.vendorProducts?.data, "name", "product_id"),
        );
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      } catch (error) {
        console.log(
          "🚀 ~ file: create-discount-vouchers.js:52 ~ gettingVendorsProducts ~ error:",
          error,
        );
      } finally {
        dispatch(handleLoader(false));
      }
    }
    gettingVendors();
  }, [formData?.vendor_id]);

  useEffect(() => {
    async function gettingVendorsData() {
      dispatch(handleLoader(true));
      try {
        let { data } = await ApiManager(
          "get",
          `${_role}/vouchers/${voucherID}`,
        );
        console.log(
          "🚀 ~ file: create-discount-vouchers.js:82 ~ gettingVendorsData ~ data:",
          data,
        );
        let _obj = {
          ...data,
          vendor_id: data?.vendor_id,
          products: data?.voucher_products,
        };
        setFormData(_obj);
      } catch (error) {
        console.log(
          "🚀 ~ file: create-discount-vouchers.js:52 ~ gettingVendorsData ~ error:",
          error,
        );
      } finally {
        dispatch(handleLoader(false));
      }
    }
    if (voucherID) gettingVendorsData();
  }, [voucherID]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let str = value;
    if (name === "vendor_id") {
      setFormData((prev) => ({ ...prev, [name]: str }));
      setFormData((prev) => ({
        ...prev,
        products: [],
      }));
    }
    setFormData((prev) => ({ ...prev, [name]: str }));
  };

  const validate = () => {
    let errors = {};
    let flag = true;
    if (moment(formData?.start_date)?.isAfter(formData?.end_date)) {
      flag = false;
      showMessage("warning", "Start date cannot be after the end date");
    }
    if (!formData?.products.length) {
      flag = false;
      showMessage("warning", "Products is required");
    }
    if (moment(formData?.end_date)?.isBefore(formData?.start_date)) {
      flag = false;
      showMessage("warning", "End date cannot be before the start date.");
    }

    setFormErrors((prev) => ({ ...prev, ...errors }));
    return flag;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        dispatch(handleLoader(true));
        setFormErrors({});
        let _url = voucherID
          ? `${_role}/update-voucher/${voucherID}`
          : `${_role}/vouchers`;
        let _fd = {
          ...formData,
          event_id: id,
          start_date: moment(formData?.start_date).format("YYYY-MM-DD"),
          end_date: moment(formData?.end_date).format("YYYY-MM-DD"),
        };

        await ApiManager("post", _url, _fd);
        showMessage("success", "Discount Voucher created successfully.");
        navigate(_path);
      } catch (error) {
        console.log(
          "🚀 ~ file: create-discount-vouchers.js:134 ~ handleSubmit ~ error:",
          error,
        );
        if (error?.response?.status === 422) {
          setFormErrors(errorsSetter(error));
        } else {
          showMessage("error", error?.response?.data?.error?.message);
        }
      } finally {
        dispatch(handleLoader(false));
      }
    }
  };

  const renderFormInputs = () => {
    return (
      <Grid container columnSpacing={3} mt={2} rowSpacing={2}>
        <Grid item sm={4} xs={12}>
          <Box>
            <InputField
              label="Promotion code"
              name="promotion_code"
              required
              error={formErrors?.promotion_code}
              value={formData?.promotion_code}
              onChange={handleInputChange}
            />
          </Box>
        </Grid>
        <Grid item sm={4} xs={12}>
          <InputDate
            label={"Start Date"}
            error={formErrors?.start_date}
            value={
              formData?.start_date
                ? moment(formData?.start_date)
                : formData?.start_date || null
            }
            onChange={(val) =>
              setFormData((prev) => ({ ...prev, start_date: val }))
            }
            required
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <InputDate
            label={"End Date"}
            error={formErrors?.end_date}
            value={
              formData?.end_date
                ? moment(formData?.end_date)
                : formData?.end_date || null
            }
            onChange={(val) =>
              setFormData((prev) => ({ ...prev, end_date: val }))
            }
            required
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Box>
            <SelectBox
              items={vendors}
              label="Vendor"
              fullWidth
              name="vendor_id"
              required
              error={formErrors?.vendor_id}
              value={formData?.vendor_id || ""}
              onChange={handleInputChange}
            />
          </Box>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Box>
            <SelectBox
              items={ticketTypes}
              label="Ticket Type "
              fullWidth
              name="ticket_type_id"
              required
              error={formErrors?.ticket_type_id}
              value={formData?.ticket_type_id || ""}
              onChange={handleInputChange}
            />
          </Box>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Box>
            <InputField
              label="Voucher redeem (Per day)"
              name="voucher_redeem_count"
              required
              error={formErrors?.voucher_redeem_count}
              value={formData?.voucher_redeem_count}
              onChange={handleInputChange}
            />
          </Box>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Box>
            <SelectBox
              items={[
                { label: "Daily", value: "daily" },
                { label: "Once", value: "once" },
              ]}
              label="Redeem type"
              fullWidth
              name="redeem_type"
              required
              error={formErrors?.redeem_type}
              value={formData?.redeem_type || ""}
              onChange={handleInputChange}
            />
          </Box>
        </Grid>
      </Grid>
    );
  };

  const conditionalInputBox = () => {
    return (
      <Box>
        {/* {!formData.products.length && !vendorProducts.length && <Typography my={2}>No Product Found</Typography>} */}
        {!isLoading && formData.vendor_id && !vendorProducts.length ? (
          <Typography my={2}>No Product Found</Typography>
        ) : (
          ""
        )}
        {isLoading && <Typography my={2}>....Loading</Typography>}
        {!isLoading &&
          formData?.products?.map((_, i) => (
            <Grid container spacing={3} my={1} key={i}>
              <Grid item sm={5.25} xs={12}>
                <SelectBox
                  size="small"
                  items={vendorProducts}
                  fullWidth
                  required
                  value={formData?.products[i]?.product_id}
                  onChange={(e) =>
                    setFormData((prev) => {
                      const newProducts = [...prev.products]; // Create a new array of products
                      newProducts[i] = {
                        ...newProducts[i],
                        product_id: e.target.value,
                      }; // Update the quantity of the specific product at index i
                      return { ...prev, products: newProducts }; // Update the products array in the form data
                    })
                  }
                  label="Vendor Products"
                />
              </Grid>
              <Grid item sm={5.25} xs={10}>
                <InputField
                  size="small"
                  value={formData?.products[i]?.quantity}
                  required
                  onChange={(e) =>
                    setFormData((prev) => {
                      const newProducts = [...prev.products]; // Create a new array of products
                      newProducts[i] = {
                        ...newProducts[i],
                        quantity: e.target.value,
                      }; // Update the quantity of the specific product at index i
                      return { ...prev, products: newProducts }; // Update the products array in the form data
                    })
                  }
                  type="number"
                  fullWidth
                  label="Quantity"
                />
              </Grid>
              <Grid item sm={1.5} xs={2}>
                <IconButton
                  color="error"
                  sx={{ borderRadius: 10 }}
                  onClick={() =>
                    setFormData((prev) => ({
                      ...prev,
                      products: [
                        ...prev.products.filter((_V, index) => index !== i),
                      ],
                    }))
                  }
                >
                  <DeleteForeverIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
      </Box>
    );
  };

  return (
    <Box m={{ sm: 2 }}>
      <Box
        component={Paper}
        elevation={6}
        sx={{
          p: 2,
        }}
      >
        <Box
          component="form"
          autoCapitalize="off"
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <Typography variant="h5">
            {voucherID ? "Update" : "Create"} Discount Vouchers
          </Typography>
          {renderFormInputs()}
          <Divider
            sx={{
              mb: 2,
              mt: 3,
            }}
          />
          <Button
            variant="contained"
            sx={{ color: "white" }}
            disabled={isLoading || !vendorProducts?.length}
            onClick={() =>
              setFormData((prev) => ({
                ...prev,
                products: [
                  ...prev.products,
                  {
                    vendor_id: formData?.vendor_id,
                    product_id: "",
                    quantity: "",
                  },
                ],
              }))
            }
          >
            Add product
          </Button>
          {conditionalInputBox()}
          <Divider
            sx={{
              mb: 2,
              mt: 3,
            }}
          />
          <Stack direction="row" gap={2} mt={2}>
            <Button
              variant="contained"
              sx={{
                color: "white",
              }}
              type="submit"
            >
              Submit
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => navigate(_path)}
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

const selectDataCreator = (data, label, value) => {
  return data?.length > 0
    ? data?.map((item) => ({
        label: item[label],
        value: item[value],
      }))
    : [];
};

export default CreateUser;
