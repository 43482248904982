import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Stack,
  TableCell,
  IconButton,
  Tooltip,
  Button,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import {
  StyledTableCell,
  StyledTableRow,
  TableWrapper,
  TablePagination,
  ConfirmationModal,
  ExportBtn,
} from "../../../component";
import { handleLoader, setToast } from "../../../store/reducer";
import ApiManager from "../../../services/api-manager";
import usePageTitle from "../../../hooks/use-page-title";

const SpDemoRequest = () => {
  usePageTitle("Demo Request");
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [modalState, setModalState] = useState(false);
  const [recordID, setRecordID] = useState(null);
  // const [unRead, setUnRead] = useState(0);
  const [columns, setColumns] = useState([]);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));

  const getData = async () => {
    try {
      setIsLoading(true);
      let { data } = await ApiManager(
        "get",
        `superAdmin/request-demos?page=${page}&per_page=${rowsPerPage}&type=demo`,
      );
      setRecords(data);
    } catch (error) {
      console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [rowsPerPage, page]);

  const onChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const onChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openModal = (_id) => {
    setModalState(true);
    setRecordID(_id);
  };

  const closeModal = () => {
    setModalState(false);
    setRecordID(null);
  };

  const deleteRecord = async () => {
    try {
      dispatch(handleLoader(true));
      await ApiManager("delete", `superAdmin/blogs/${recordID}`);
      showMessage("success", "Blog Deleted successfully.");
      closeModal();
      setRecords((prev) => ({
        ...prev,
        data: prev?.data.filter((item) => item?.blog_id !== recordID),
      }));
    } catch (error) {
      showMessage("error", error?.response?.data?.error?.message);
    } finally {
      dispatch(handleLoader(false));
    }
  };

  const thLabels = [
    "First name",
    "Last name",
    "Email",
    "Company name",
    "Country",
    "Estimated attendees",
    "Status",
    "Actions",
  ];

  return (
    <div>
      <Box>
        <Typography variant="h4" color="initial" fontWeight={"bold"} m={2}>
          Demo Requests
        </Typography>
        {/* <Stack direction="row" my={2}>
          <Button component={Link} to="/admin/blogs/create-blog">
            Create Blog
          </Button>
        </Stack> */}
        <TableWrapper
          pagination={
            <TablePagination
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              perPage={records?.per_page || 0}
              total={records?.total || 0}
            />
          }
          spanTd={thLabels.length}
          isLoading={isLoading}
          isContent={records?.data?.length || false}
          thContent={
            <>
              {thLabels.map((v) => (
                <TableCell key={v}>{v}</TableCell>
              ))}
            </>
          }
        >
          {records?.data?.length &&
            records?.data.map((item, i) => (
              <StyledTableRow key={i}>
                <StyledTableCell>{item?.first_name}</StyledTableCell>
                <StyledTableCell>{item?.last_name}</StyledTableCell>
                <StyledTableCell>{item?.email}</StyledTableCell>
                <StyledTableCell>{item?.company_name}</StyledTableCell>
                <StyledTableCell>{item?.country}</StyledTableCell>
                <StyledTableCell>{item?.estimated_attendees}</StyledTableCell>
                <StyledTableCell>
                  {item?.read ? "Read" : "Un Read"}
                </StyledTableCell>
                <StyledTableCell>
                  <Stack direction="row" spacing={0.5} alignItems="center">
                    <Tooltip title="View" placement="top">
                      <IconButton
                        color="info"
                        onClick={() => navigate(`${item?.id}/view`)}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </Tooltip>
                    {/* <Tooltip title="Delete" placement="top">
                      <IconButton color="error" onClick={() => openModal(item?.contact_us_id)}>
                        <DeleteForeverIcon />
                      </IconButton>
                    </Tooltip> */}
                  </Stack>
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </TableWrapper>
      </Box>
      <ConfirmationModal
        callBack={deleteRecord}
        open={modalState}
        handleClose={closeModal}
      />
    </div>
  );
};

export default SpDemoRequest;
