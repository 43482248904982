import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  TableCell,
  TableContainer,
  TableRow,
  Table,
  TableBody,
  Fab,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import {
  StyledTableCell,
  StyledTableRow,
  TablePagination,
  TableWrapper,
} from "../../../../../component";
import ApiManager from "../../../../../services/api-manager";
import usePageTitle from "../../../../../hooks/use-page-title";
import moment from "moment";
import { setToast } from "../../../../../store/reducer";

const VendorLogs = () => {
  usePageTitle("Vendor Logs");
  const { id, vendorID } = useParams();
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState(null);
  const [vendorDetails, setVendorDetails] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.storeReducer);
  const _role = user?.role === "super_admin" ? "superAdmin" : "organizer";

  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));

  useEffect(() => {
    async function init() {
      if (vendorID) {
        try {
          let { data } = await ApiManager(
            "get",
            `${_role}/vendors/${vendorID}`,
          );
          setVendorDetails(data);
        } catch (error) {
          showMessage("error", error?.response?.data?.error?.message);
        }
      }
    }
    init();
  }, []);

  useEffect(() => {
    getData();
  }, [page, rowsPerPage, id]);

  const getData = async () => {
    try {
      setIsLoading(true);
      let { data } = await ApiManager(
        "get",
        `${_role}/vendor-logs/${vendorID}?page=${page}&per_page=${rowsPerPage}`,
      );
      setRecords(data);
    } catch (error) {
      console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const onChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <Box>
        <Fab
          size="small"
          onClick={() => navigate(`/event/${id}/management/cashless/vendors`)}
        >
          <ArrowBackIcon />
        </Fab>
        <Typography variant="h4" color="initial" fontWeight={"bold"} m={2}>
          Vendor Logs
        </Typography>

        <Box component={Paper} bgcolor="#fff" maxWidth="650px" p={2} mb={2}>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>
                    {!!vendorDetails ? vendorDetails?.name : "..loading"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Total Sku</TableCell>
                  <TableCell>
                    {!!vendorDetails ? vendorDetails?.total_sku : "..loading"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Sales</TableCell>
                  <TableCell>
                    {!!vendorDetails ? vendorDetails?.sales : "..loading"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <TableWrapper
          pagination={
            <TablePagination
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              perPage={records?.per_page || 0}
              total={records?.total || 0}
            />
          }
          spanTd={thLabels.length}
          isLoading={isLoading}
          isContent={records?.data?.length || false}
          thContent={
            <>
              {thLabels.map((v) => (
                <TableCell key={v}>{v}</TableCell>
              ))}
            </>
          }
        >
          {records?.data?.map((item, i) => (
            <StyledTableRow key={i}>
              <StyledTableCell>
                {moment(item?.created_at).format(
                  process.env.REACT_APP_DATETIME_FORMAT,
                )}
              </StyledTableCell>
              <StyledTableCell>
                {moment(item?.created_at).unix() ===
                moment(item?.updated_at).unix()
                  ? ""
                  : moment(item?.updated_at).format(
                      process.env.REACT_APP_DATETIME_FORMAT,
                    )}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableWrapper>
      </Box>
    </div>
  );
};

const thLabels = [
  "Log In (DD-MM-YYYY HH:MM A)",
  "Log Out (DD-MM-YYYY HH:MM A)",
];

export default VendorLogs;
