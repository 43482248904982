// import moment from "moment"

// const calculateTimeDiff = (createdAt) => {
//     let createdTime = moment(createdAt);
//     const currentDeviceTime = moment();
//     const hoursDifference = currentDeviceTime.diff(createdTime, 'hours');
//     return hoursDifference + " hours"
// }

// export default calculateTimeDiff

import moment from "moment";

const calculateTimeDiff = (createdAt) => {
  if (!createdAt) {
    return "-";
  }

  let createdTime = moment(createdAt);
  const currentDeviceTime = moment();
  const duration = moment.duration(currentDeviceTime.diff(createdTime));

  const hours = Math.floor(duration.asHours());
  const minutes = Math.floor(duration.asMinutes()) % 60;

  let result = "";

  if (hours > 0) {
    result += hours + " hours ";
  }
  if (minutes > 0) {
    result += minutes + " minutes ";
  }

  return result.trim();
};

export default calculateTimeDiff;
