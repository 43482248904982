import React from "react";
import { Slide, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Toast({
  open,
  type,
  message,
  handleClose = () => {},
  duration,
}) {
  return (
    <Snackbar
      open={open}
      autoHideDuration={duration}
      TransitionComponent={Slide}
      onClose={() => handleClose()}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
    >
      <Alert
        onClose={() => handleClose()}
        severity={type}
        sx={{ width: "100%" }}
      >
        {message || "Unknown Error Occurred!"}
      </Alert>
    </Snackbar>
  );
}
