import React from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { publicRoutes, protectedRoutes } from "./app-routes";
import { Page404, VendorDetails } from "../screens";
import { Loader, OnBoardUi, ProtectedRoute, Toast } from "../component";
import { closeToast, handleOnBoardUI } from "../store/reducer";

export default function Router() {
  const { loader, toast, isLogged, user, showOnBoardUI } = useSelector(
    (state) => state.storeReducer,
  );
  const dispatch = useDispatch();
  let value = localStorage.getItem("@EVENTSIFY_ONBOARD_UI");

  React.useEffect(() => {
    // if (isLogged && !value) {
    //   dispatch(handleOnBoardUI(true));
    // }
  }, []);

  if (showOnBoardUI) {
    return <OnBoardUi />;
  }

  return (
    <>
      <Routes>
        <Route
          path={"/vendor-details/:vendorID"}
          exact
          element={<VendorDetails />}
        />

        {publicRoutes.map((_v, _i) => {
          return (
            <Route
              key={_i}
              path={_v.path}
              exact
              element={
                <CheckUser
                  isLogged={isLogged}
                  afterAuthAllowed={_v.afterAuthAllowed}
                  element={_v.component}
                />
              }
            />
          );
        })}
        {protectedRoutes.map((_v, _i) => {
          return (
            <Route
              key={_i}
              path={_v.path}
              exact
              element={
                <ProtectedRoute
                  element={_v.component}
                  type={_v.type}
                  role={user?.role}
                  isLogged={isLogged}
                  layoutType={_v.layoutType}
                />
              }
            />
          );
        })}
        <Route path="/*" element={<Page404 />} />
      </Routes>
      <Loader open={loader} />
      {toast.open && (
        <Toast
          open={toast?.open}
          message={toast?.message}
          type={toast?.type}
          duration={toast?.duration}
          handleClose={() => dispatch(closeToast())}
        />
      )}
    </>
  );
}

const CheckUser = ({ isLogged, element, afterAuthAllowed }) => {
  const navigate = useNavigate();

  React.useEffect(() => {
    if (isLogged && !afterAuthAllowed) {
      navigate("/");
    }
  }, [isLogged]);

  if (isLogged && !afterAuthAllowed) {
    <></>;
  }
  return element;
};
