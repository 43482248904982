import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Fab,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSelector } from "react-redux";

import ApiManager from "../../../../../services/api-manager";

const TicketInfo = () => {
  const { ticketID, id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState(null);
  const { user } = useSelector((state) => state.storeReducer);
  const _role = user?.role === "super_admin" ? "superAdmin" : "organizer";
  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setIsLoading(true);
      let { data } = await ApiManager("get", `${_role}/tickets/${ticketID}`);
      setRecords(data);
    } catch (error) {
      console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box m={{ sm: 2 }}>
      <Fab
        size="small"
        onClick={() =>
          navigate(`/event/${id}/management/access-control/tickets`)
        }
      >
        <ArrowBackIcon />
      </Fab>
      <Typography variant="h5" mt={2} gutterBottom>
        Ticket Info
      </Typography>

      <Paper elevation={6} sx={{ p: 2 }}>
        {isLoading ? (
          <Stack alignItems="center">
            <CircularProgress size={28} />
          </Stack>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Typography variant="h6" fontWeight="bold" gutterBottom>
                Name On Ticket
              </Typography>
              <Typography variant="body1" gutterBottom>
                {records?.name || "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Typography variant="h6" fontWeight="bold" gutterBottom>
                Email
              </Typography>
              <Typography variant="body1" gutterBottom>
                {records?.email || "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Typography variant="h6" fontWeight="bold" gutterBottom>
                Ticket Id
              </Typography>
              <Typography variant="body1" gutterBottom>
                {records?.ticket_id || "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Typography variant="h6" fontWeight="bold" gutterBottom>
                Ticket Type
              </Typography>
              <Typography variant="body1" gutterBottom>
                {records?.ticket_type?.name || "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Typography variant="h6" fontWeight="bold" gutterBottom>
                Attendee Name
              </Typography>
              {records?.attendee_tickets.length
                ? records?.attendee_tickets?.map((_v, _i) => {
                    return (
                      <Typography variant="body1" gutterBottom key={_i}>
                        {_v?.name}
                      </Typography>
                    );
                  })
                : "-"}
            </Grid>
          </Grid>
        )}
      </Paper>
    </Box>
  );
};

export default TicketInfo;
