import React, { useEffect, useState } from "react";
import { Box, Typography, TableCell, Fab, Stack } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import {
  ExportBtn,
  StyledTableCell,
  StyledTableRow,
  TablePagination,
  TableWrapper,
} from "../../../../component";
import ApiManager from "../../../../services/api-manager";
import usePageTitle from "../../../../hooks/use-page-title";
import Utils from "../../../../utils/utils";
import moment from "moment";

// TODO: refund work remains
// TODO: Final Quantity and Final Amount work remains

const VouchersReportDetail = () => {
  usePageTitle("Revenue Report Details");
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState(null);
  const { id, voucherID } = useParams();
  const navigate = useNavigate();
  const { user, loggedEvent } = useSelector((state) => state.storeReducer);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [columns, setColumns] = useState([]);
  const _role = user?.role === "super_admin" ? "superAdmin" : "organizer";
  const _symbol = loggedEvent.currencySymbol;

  useEffect(() => {
    getData();
  }, [id, voucherID, page, rowsPerPage]);

  const getData = async (_search = "") => {
    try {
      setIsLoading(true);
      let { data } = await ApiManager(
        "get",
        `${_role}/single-voucher-reports/${voucherID}?page=${page}&per_page=${rowsPerPage}`,
      );
      console.log("showing data: ", data?.items);
      setColumns(data?.columns);
      setRecords(data);
    } catch (error) {
      console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const onChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <Box>
        <Fab
          size="small"
          onClick={() =>
            navigate(`/event/${id}/management/lead-capture/vouchers-report`)
          }
        >
          <ArrowBackIcon />
        </Fab>
        <Typography variant="h4" color="initial" fontWeight="bold" m={2}>
          {records?.voucher?.promotion_code || "...Loading"}
        </Typography>

        <Stack direction={{ sm: "row", xs: "column" }} my={2}>
          <ExportBtn
            path={`reports-event-single-voucher-csv?voucher_id=${voucherID}&`}
            data={columns}
          />
        </Stack>

        <TableWrapper
          spanTd={thLabels.length}
          isLoading={isLoading}
          isContent={records?.orders?.data?.length || false}
          pagination={
            <TablePagination
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              perPage={records?.orders?.per_page || 0}
              total={records?.orders?.total || 0}
            />
          }
          thContent={
            <>
              {thLabels.map((v) => (
                <TableCell key={v}>{v}</TableCell>
              ))}
            </>
          }
        >
          {records?.orders?.data?.map((item, i) => (
            <StyledTableRow key={i}>
              <StyledTableCell>{item?.order?.code}</StyledTableCell>
              <StyledTableCell>{item?.vendor?.name}</StyledTableCell>
              <StyledTableCell>{item?.product?.name}</StyledTableCell>
              <StyledTableCell>
                {_symbol}
                {item?.price}
              </StyledTableCell>
              <StyledTableCell>{item?.quantity}</StyledTableCell>
              <StyledTableCell>
                {_symbol}
                {item?.sub_total}
              </StyledTableCell>
              <StyledTableCell>{item?.redeem_quantity}</StyledTableCell>
              <StyledTableCell>
                {_symbol}
                {item?.redeem_amount}
              </StyledTableCell>
              <StyledTableCell>
                {moment(item?.created_at).format(
                  process.env.REACT_APP_DATE_FORMAT,
                )}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableWrapper>
      </Box>
    </div>
  );
};

const thLabels = [
  "RFID Number",
  "Vendor Name",
  "Product Name",
  "Price",
  "Quantity",
  "Amount",
  "Redeem Quantity",
  "Redeem Amount",
  "Created At",
];

export default VouchersReportDetail;
