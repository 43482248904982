import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Stack,
  TableCell,
  Fab,
  Tooltip,
  IconButton,
  Grid,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useSelector } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RefreshIcon from "@mui/icons-material/Refresh";

import {
  ExportBtn,
  InputField,
  SearchInput,
  StartAndEndDateFilter,
  StyledTableCell,
  StyledTableRow,
  TablePagination,
  TableWrapper,
} from "../../../../component";
import ApiManager from "../../../../services/api-manager";
import usePageTitle from "../../../../hooks/use-page-title";
import Utils from "../../../../utils/utils";

// TODO: order items screen missing copy it from revenue details screen

const VendorReportDetails = () => {
  usePageTitle("Vendor Report");
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [searchCalled, setSearchCalled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState(null);
  const [search, setSearch] = useState("");
  const { id, vendorID } = useParams();
  const navigate = useNavigate();
  const { user, loggedEvent } = useSelector((state) => state.storeReducer);
  const _role = user?.role === "super_admin" ? "superAdmin" : "organizer";
  const _symbol = loggedEvent.currencySymbol;
  const [columns, setColumns] = useState([]);
  const [vendor, setVendor] = useState(null);
  const [filtersState, setFiltersState] = useState({
    start_date: "",
    end_date: "",
    start_time: "",
    end_time: "",
    search: "",
  });

  useEffect(() => {
    getData();
  }, [
    page,
    rowsPerPage,
    id,
    filtersState.start_date,
    filtersState.start_time,
    filtersState.end_time,
    filtersState.end_date,
    searchCalled,
  ]);

  const getData = async (_search = "") => {
    try {
      setIsLoading(true);
      let { data } = await ApiManager(
        "get",
        // `${_role}/vendor-orders?event_id=${id}&vendor_id=${vendorID}&page=${page}&per_page=${rowsPerPage}&search=${filtersState.search}&start_time=${filtersState?.start_time}&end_time=${filtersState?.end_time}&`,
        `${_role}/reports?vendor_orders=1&event_id=${id}&page=${page}&per_page=${rowsPerPage}&vendor_id=${vendorID}&start_date=${filtersState.start_date}&end_date=${filtersState.end_date}&start_time=${filtersState?.start_time}&end_time=${filtersState?.end_time}&`,
      );
      setRecords(data?.orders);
      setColumns(data?.columns);
      setVendor(data?.vendor);
    } catch (error) {
      console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const onChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <Box>
        <Fab
          size="small"
          onClick={() =>
            navigate(`/event/${id}/management/lead-capture/vendor-report`)
          }
        >
          <ArrowBackIcon />
        </Fab>
        <Typography variant="h4" color="initial" fontWeight={"bold"} m={2}>
          Vendor - {vendor ? vendor?.name : "...Loading"}
        </Typography>
        <Typography variant="h6" color="initial" fontWeight={"bold"} m={2}>
          Report Details
        </Typography>

        {/* <Stack
          direction={{ sm: "row", xs: "column" }}
          justifyContent={{ sm: "space-between", xs: "center" }}
          my={2}
        >
          <ExportBtn
            data={columns}
            path={`reports-event-vendors-csv?event_id=${id}&vendor_id=${vendorID}&`}
          /> */}
        {/* <Stack direction="row" gap={2} alignItems={"center"}>
            <InputField
              label="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            {!!search && (
              <Button
                onClick={() => {
                  if (page === 1) {
                    getData(search);
                  } else {
                    setPage(1);
                  }
                  setSearchCalled(true);
                }}
                variant="contained"
                sx={{ color: "white" }}
              >
                Search
              </Button>
            )}
            {searchCalled && (
              <Button
                onClick={() => {
                  setSearch("");
                  if (page === 1) {
                    getData();
                  } else {
                    setPage(1);
                  }
                  setSearchCalled(false);
                }}
                variant="contained"
                color="error"
              >
                Clear
              </Button>
            )}
          </Stack> */}
        {/* </Stack> */}

        <Grid container margin="10px 0">
          <Grid item lg={4} xs={12} margin="10px 0">
            <Stack direction="row">
              <Button startIcon={<RefreshIcon />} onClick={() => getData()}>
                Refresh
              </Button>
              <ExportBtn
                data={columns}
                path={`vendor-orders-csv?event_id=${id}&vendor_id=${vendorID}&start_date=${filtersState.start_date}&end_date=${filtersState.end_date}&search=${filtersState.search}&`}
              />
            </Stack>
          </Grid>
          <Grid item lg={8} xs={12}>
            <StartAndEndDateFilter
              onFilterChange={(filterState) => {
                setPage(1);
                setFiltersState((prev) => ({ ...prev, ...filterState }));
              }}
              onRequestClear={() =>
                setFiltersState((prev) => ({
                  ...prev,
                  start_date: "",
                  end_date: "",
                  start_time: "",
                  end_time: "",
                }))
              }
            />
          </Grid>
        </Grid>

        {/* <Stack direction="row" flexWrap="wrap" margin="10px 0">
          <Stack direction="row" margin="10px 0">
            <Button startIcon={<RefreshIcon />} onClick={() => getData()}>
              Refresh
            </Button>
            <ExportBtn
              data={columns}
              // path={`reports-event-vendors-csv?event_id=${id}&vendor_id=${vendorID}&`}
              path={`reports-event-vendors-csv?event_id=${id}&vendor_id=${vendorID}&`}
            />
          </Stack>
          <StartAndEndDateFilter
            onFilterChange={(filterState) => {
              setPage(1);
              setFiltersState((prev) => ({ ...prev, ...filterState }));
            }}
            onRequestClear={() =>
              setFiltersState((prev) => ({
                ...prev,
                start_date: "",
                end_date: "",
                start_time: "",
                end_time: "",
              }))
            }
          />

        </Stack> */}

        <TableWrapper
          pagination={
            <TablePagination
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              perPage={records?.per_page || 0}
              total={records?.total || 0}
            />
          }
          spanTd={thLabels.length}
          isLoading={isLoading}
          isContent={records?.data?.length || false}
          thContent={
            <>
              {thLabels.map((v) => (
                <TableCell key={v}>{v}</TableCell>
              ))}
            </>
          }
        >
          {records?.data?.map((item, i) => (
            <StyledTableRow key={i}>
              <StyledTableCell>{item?.order_id}</StyledTableCell>
              <StyledTableCell>
                {Utils.capitalize(item?.order_status) || "-"}
              </StyledTableCell>
              <StyledTableCell>
                {Utils.capitalize(item?.payment_status) || "-"}
              </StyledTableCell>
              <StyledTableCell>
                {_symbol}
                {item?.tip}
              </StyledTableCell>
              <StyledTableCell>
                {_symbol}
                {Utils.formatWithSuffix(item?.actual_amount)}
              </StyledTableCell>
              <StyledTableCell>
                {_symbol}
                {Utils.formatWithSuffix(item?.refund_amount)}
              </StyledTableCell>
              <StyledTableCell>
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <Tooltip title="View Details" placement="top">
                    <IconButton
                      color="info"
                      onClick={() =>
                        navigate(
                          `/event/${id}/management/lead-capture/vendor-report/${vendorID}/${item?.order_id}`,
                        )
                      }
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableWrapper>
      </Box>
    </div>
  );
};

const thLabels = [
  "ID",
  "Order Status",
  "Payment Status",
  "Tip",
  "Amount",
  "Refund Amount",
  "Details",
];

export default VendorReportDetails;
