import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Stack,
  TableCell,
  IconButton,
  Tooltip,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";
import { useDispatch } from "react-redux";

import {
  StyledTableCell,
  StyledTableRow,
  TableWrapper,
  ExportBtn,
} from "../../../component";
import { handleLoader, setToast } from "../../../store/reducer";
import ApiManager from "../../../services/api-manager";
import usePageTitle from "../../../hooks/use-page-title";

const SpCurrency = () => {
  usePageTitle("Currency");
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [columns, setColumns] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setIsLoading(true);
      let { data } = await ApiManager("get", "superAdmin/currencies");
      if (!!data?.currencies && data?.currencies?.length > 0) {
        setRecords(data?.currencies);
      }
      setColumns(data?.columns);
    } catch (error) {
      console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleStatus = async (e, item, index) => {
    setRecords((prev) =>
      prev.map((obj) => {
        if (obj.currency_id === item.currency_id) {
          return { ...obj, status: e.target.checked };
        }
        return obj;
      }),
    );
    let _fd = {
      _method: "patch",
      currency_id: item?.currency_id,
      status: e.target.checked,
    };
    try {
      dispatch(handleLoader(true));
      await ApiManager(
        "post",
        `superAdmin/currencies/${item?.currency_id}`,
        _fd,
      );
      showMessage("success", "Record status updated successfully.");
    } catch (error) {
      showMessage("error", error?.response?.data?.error?.message);
    } finally {
      dispatch(handleLoader(false));
    }
  };

  const thLabels = [
    "ID",
    "Currency Name",
    "Currency Code",
    "Currency Symbol",
    "Status",
    "Actions",
  ];

  return (
    <div>
      <Box>
        <Typography variant="h4" color="initial" fontWeight={"bold"} m={2}>
          Currency
        </Typography>
        <Stack direction="row" m={2}>
          <Button
            onClick={() => navigate("/admin/currency/add")}
            startIcon={<AddIcon />}
          >
            Add Currency
          </Button>
          <ExportBtn path={`reports-admin-currencies-csv?`} data={columns} />
        </Stack>

        <TableWrapper
          spanTd={thLabels.length}
          isLoading={isLoading}
          isContent={records?.length || false}
          thContent={
            <>
              {thLabels.map((v) => (
                <TableCell key={v}>{v}</TableCell>
              ))}
            </>
          }
        >
          {records.map((item, i) => (
            <StyledTableRow key={i}>
              <StyledTableCell>{item?.currency_id}</StyledTableCell>
              <StyledTableCell>{item?.currency_name}</StyledTableCell>
              <StyledTableCell>{item?.currency_code}</StyledTableCell>
              <StyledTableCell>{item?.currency_symbol}</StyledTableCell>
              <StyledTableCell>
                <FormControlLabel
                  control={
                    <Switch
                      checked={item?.status}
                      onChange={(e) => handleStatus(e, item, i)}
                    />
                  }
                />
              </StyledTableCell>
              <StyledTableCell>
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <Tooltip title="Edit" placement="top">
                    <IconButton
                      color="info"
                      onClick={() =>
                        navigate(`/admin/currency/update/${item?.currency_id}`)
                      }
                    >
                      <CreateRoundedIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableWrapper>
      </Box>
    </div>
  );
};

export default SpCurrency;
