import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Stack,
  TableCell,
  Tooltip,
  IconButton,
} from "@mui/material";
import CreateIcon from "@mui/icons-material/Create";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";

import { useNavigate, useParams } from "react-router-dom";
import {
  ConfirmationModal,
  StyledTableCell,
  StyledTableRow,
  TablePagination,
  TableWrapper,
} from "../../../../component";
import ApiManager from "../../../../services/api-manager";
import { useDispatch, useSelector } from "react-redux";
import { handleLoader, setToast } from "../../../../store/reducer";
import usePageTitle from "../../../../hooks/use-page-title";
import { Settings } from "@mui/icons-material";
import Utils from "../../../../utils/utils";

const AccessControlPoint = () => {
  usePageTitle("Access Control Point");
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState(null);
  const [modalState, setModalState] = useState(false);
  const [recordID, setRecordID] = useState(null);
  const { user } = useSelector((state) => state.storeReducer);
  const _isAdmin = user?.role === "super_admin" ? true : false;
  const _role = user?.role === "super_admin" ? "superAdmin" : "organizer";

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [page, rowsPerPage]);

  const closeModal = () => {
    setModalState(false);
    setRecordID(null);
  };

  const openModal = (_id) => {
    setModalState(true);
    setRecordID(_id);
  };

  const getData = async () => {
    try {
      setIsLoading(true);
      let { data } = await ApiManager(
        "get",
        `${_role}/access-control-points?event_id=${id}&page=${page}&per_page=${rowsPerPage}`,
      );
      setRecords(data);
    } catch (error) {
      console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const onChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteRecord = async () => {
    try {
      dispatch(handleLoader(true));
      await ApiManager("delete", `organizer/access-control-points/${recordID}`);
      showMessage("success", "Access control point deleted successfully.");
      closeModal();
      getData();
    } catch (error) {
      console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
      showMessage("error", error?.response?.data?.error?.message);
    } finally {
      dispatch(handleLoader(false));
    }
  };

  const thLabels = ["ID", "Name", "Status", "Manage"];

  return (
    <div>
      <Box>
        <Typography variant="h4" color="initial" fontWeight={"bold"} m={2}>
          Access Control Point
        </Typography>

        {!_isAdmin && (
          <Stack direction="row" m={2}>
            <Button
              onClick={() =>
                navigate(
                  `/event/${id}/management/access-control/access-control-points/create`,
                )
              }
            >
              <CreateIcon />
              Add Access Control Point
            </Button>
          </Stack>
        )}

        <TableWrapper
          pagination={
            <TablePagination
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              perPage={records?.per_page || 0}
              total={records?.total || 0}
            />
          }
          thContent={
            <>
              {thLabels.map((v) => (
                <TableCell key={v}>{v}</TableCell>
              ))}
            </>
          }
          spanTd={thLabels.length}
          isLoading={isLoading}
          isContent={records?.data?.length || false}
        >
          {records?.data.map((item, i) => (
            <StyledTableRow key={i}>
              <StyledTableCell>{item?.acp_id}</StyledTableCell>
              <StyledTableCell>{item?.name}</StyledTableCell>
              <StyledTableCell>
                {Utils.capitalize(item?.status)}
              </StyledTableCell>
              <StyledTableCell>
                <Stack direction="row" spacing={0.5} alignItems="center">
                  {!_isAdmin && (
                    <>
                      <Tooltip title="Edit" placement="top">
                        <IconButton
                          color="info"
                          onClick={() =>
                            navigate(
                              `/event/${id}/management/access-control/access-control-points/update/${item?.acp_id}`,
                            )
                          }
                        >
                          <CreateRoundedIcon />
                        </IconButton>
                      </Tooltip>
                      {/* <Tooltip title="Delete" placement="top">
                        <IconButton
                          type="button"
                          color="error"
                          onClick={() => openModal(item?.acp_id)}
                        >
                          <DeleteForeverIcon />
                        </IconButton>
                      </Tooltip> */}
                    </>
                  )}
                  <Tooltip title="Setting" placement="top">
                    <IconButton
                      type="button"
                      color="info"
                      onClick={() =>
                        navigate(
                          `/event/${id}/management/access-control/access-control-points/setting/${item?.acp_id}`,
                        )
                      }
                    >
                      <Settings />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableWrapper>
      </Box>
      <ConfirmationModal
        callBack={deleteRecord}
        open={modalState}
        handleClose={closeModal}
      />
    </div>
  );
};

export default AccessControlPoint;
