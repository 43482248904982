import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Stack,
  Button,
  Divider,
  InputAdornment,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

import {
  InputDate,
  InputField,
  SelectBox,
} from "../../../../../../../component";
import usePageTitle from "../../../../../../../hooks/use-page-title";
import { handleLoader, setToast } from "../../../../../../../store/reducer";
import ApiManager from "../../../../../../../services/api-manager";
import errorsSetter from "../../../../../../../helpers/error-setter";

const dateFormat = "YYYY-MM-DD";

const AddProducts = () => {
  const { id, vendorID, productID = null } = useParams();
  usePageTitle(`${productID ? "Update" : "Add"} Products`);
  const [vendorRecord, setVendorRecord] = useState(null);
  const [formData, setFormData] = useState({ open_id: "" });
  const [category, setCategory] = useState([]);
  const [formErrors, setFormErrors] = useState({
    allow_manual_items: false,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loggedEvent } = useSelector((state) => state.storeReducer);
  const _symbol = loggedEvent.currencySymbol;

  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));
  const _path = `/event/${id}/management/cashless/vendors/manage/${vendorID}/product`;

  useEffect(() => {
    async function init() {
      try {
        let { data } = await ApiManager("get", `organizer/vendors/${vendorID}`);
        setVendorRecord(data);
      } catch (error) {
        console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
      }
    }
    init();
  }, []);

  useEffect(() => {
    async function init() {
      if (productID) {
        try {
          dispatch(handleLoader(true));
          let { data } = await ApiManager(
            "get",
            `organizer/vendor-products/${productID}`,
          );
          setFormData({
            ...data,
            hh_start_date: moment(data?.hh_start_date),
            hh_end_date: moment(data?.hh_end_date),
          });
        } catch (error) {
          showMessage("error", error?.response?.data?.error?.message);
        } finally {
          dispatch(handleLoader(false));
        }
      }
    }
    init();
  }, [productID]);

  useEffect(() => {
    async function init() {
      try {
        dispatch(handleLoader(true));
        let { data } = await ApiManager(
          "get",
          `organizer/get-categories?event_id=${id}`,
        );
        setCategory(
          data
            ? data.map((each) => ({
                label: each.title,
                value: each.category_id,
              }))
            : [],
        );
      } catch (error) {
        showMessage("error", error?.response?.data?.error?.message);
      } finally {
        dispatch(handleLoader(false));
      }
    }
    init();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  function validateTime(startTime, endTime) {
    const start = moment(startTime, "HH:mm");
    const end = moment(endTime, "HH:mm");

    if (start.isSameOrAfter(end)) {
      return true;
    }

    return false;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      formData?.hh_start_date &&
      moment(formData?.hh_start_date).isAfter(formData?.hh_end_date)
    ) {
      showMessage("warning", "Start date cannot be after the end date");
      return;
    }
    if (
      formData?.hh_end_date &&
      moment(formData?.hh_end_date).isBefore(formData?.hh_start_date)
    ) {
      showMessage("warning", "End date cannot be before the start date.");
      return;
    }

    if (validateTime(formData?.hh_start_time, formData?.hh_end_time)) {
      showMessage("warning", "End time must be after start time");
      return;
    }

    if (formData?.abbreviation?.length > formData?.name?.length) {
      setFormErrors((prev) => ({
        ...prev,
        abbreviation: "Abbreviation must not bigger than name",
      }));
      return;
    }

    try {
      dispatch(handleLoader(true));
      setFormErrors({});
      let _url = productID
        ? `organizer/vendor-products/${productID}`
        : "organizer/vendor-products";
      let _fd = {
        ...formData,
        vendor: vendorID,
        event_id: id,
        hh_start_date: moment(formData?.hh_start_date).format(dateFormat),
        hh_end_date: moment(formData?.hh_end_date).format(dateFormat),
        hh_start_time: moment(formData?.hh_start_time, "HH:mm").format(
          "HH:mm:ss",
        ),
        hh_end_time: moment(formData?.hh_end_time, "HH:mm").format("HH:mm:ss"),
      };
      if (productID) {
        _fd._method = "patch";
      }
      await ApiManager("post", _url, _fd);
      if (productID) {
        showMessage("success", "Product updated successfully.");
      } else {
        showMessage("success", "Product created successfully.");
      }
      navigate(_path);
    } catch (error) {
      if (error?.response?.status === 422) {
        setFormErrors(errorsSetter(error));
      } else {
        showMessage("error", error?.response?.data?.error?.message);
      }
    } finally {
      dispatch(handleLoader(false));
    }
  };

  return (
    <Box m={{ sm: 2 }}>
      <Box component={Paper} elevation={6} p={2}>
        <Box
          component="form"
          autoComplete="off"
          onSubmit={handleSubmit}
          autoCapitalize="off"
        >
          <Typography variant="h5">
            Add Product to Vendor -{" "}
            {!!vendorRecord ? vendorRecord?.name : "...Loading"}
          </Typography>
          <Typography variant="body1">
            Discount is for your cashless sale note this is a single vendor
            discount and will apply only to this vendor{" "}
          </Typography>

          <Grid container columnSpacing={3} mt={2} rowSpacing={2}>
            <Grid item lg={3} sm={6} xs={12}>
              <Box>
                <InputField
                  label="Name"
                  size="small"
                  name="name"
                  required
                  error={formErrors?.name}
                  value={formData?.name}
                  onChange={handleInputChange}
                />
              </Box>
            </Grid>
            <Grid item lg={3} sm={6} xs={12}>
              <InputField
                label="SKU"
                size="small"
                name="sku"
                required
                error={formErrors?.sku}
                value={formData?.sku}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item lg={3} sm={6} xs={12}>
              <InputField
                label="Price"
                size="small"
                name="price"
                required
                error={formErrors?.price}
                value={formData?.price}
                onChange={handleInputChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{_symbol}</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item lg={3} sm={6} xs={12}>
              <InputField
                label="Abbreviation"
                size="small"
                name="abbreviation"
                required
                error={formErrors?.abbreviation}
                value={formData?.abbreviation}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item lg={3} sm={6} xs={12}>
              <InputField
                label="Description"
                size="small"
                name="description"
                required
                error={formErrors?.description}
                value={formData?.description}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item lg={3} sm={6} xs={12}>
              <InputField
                label="Quantity"
                size="small"
                type="number"
                name="quantity"
                required
                error={formErrors?.quantity}
                value={formData?.quantity}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item lg={3} sm={6} xs={12}>
              <InputField
                label="Order #"
                size="small"
                type="number"
                name="order_number"
                required
                error={formErrors?.order_number}
                value={formData?.order_number}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item lg={3} sm={6} xs={12}>
              <SelectBox
                label="Category"
                size="small"
                name="category_id"
                items={category}
                required
                error={formErrors?.category_id}
                value={formData?.category_id}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} container>
              <Grid item lg={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData?.include_alcohol || false}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          include_alcohol: e.target.checked,
                        }))
                      }
                    />
                  }
                  label="Includes Alcohol"
                />
              </Grid>
              <Grid item lg={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData?.happy_hour || false}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          happy_hour: e.target.checked,
                        }))
                      }
                    />
                  }
                  label="Happy Hour"
                />
              </Grid>
            </Grid>

            {formData?.happy_hour && (
              <>
                <Grid item sm={6} xs={12}>
                  <InputDate
                    labelTop="Start Date"
                    error={formErrors?.hh_start_date}
                    value={formData?.hh_start_date || null}
                    onChange={(val) =>
                      setFormData((prev) => ({ ...prev, hh_start_date: val }))
                    }
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <InputField
                    labelTop="Start Time"
                    size="small"
                    type="time"
                    name="hh_start_time"
                    required
                    inputProps={{ min: "00:00", max: "23:59" }}
                    error={formErrors?.hh_start_time}
                    value={formData?.hh_start_time}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <InputDate
                    labelTop="End Date"
                    error={formErrors?.hh_end_date}
                    value={formData?.hh_end_date || null}
                    onChange={(val) =>
                      setFormData((prev) => ({ ...prev, hh_end_date: val }))
                    }
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <InputField
                    labelTop="End Time"
                    size="small"
                    type="time"
                    name="hh_end_time"
                    required
                    error={formErrors?.hh_end_time}
                    value={formData?.hh_end_time}
                    onChange={handleInputChange}
                    inputProps={{ min: "00:00", max: "23:59" }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <InputField
                    label="Discount Rate"
                    size="small"
                    type="number"
                    name="hh_discounted_percentage"
                    required={formData?.happy_hour || false}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">%</InputAdornment>
                      ),
                    }}
                    inputProps={{ min: "1", max: "99" }}
                    error={formErrors?.hh_discounted_percentage}
                    value={formData?.hh_discounted_percentage}
                    onChange={handleInputChange}
                  />
                </Grid>
              </>
            )}
          </Grid>
          <Divider
            sx={{
              my: 2,
            }}
          />
          <Stack direction="row" gap={2} mt={2}>
            <Button
              variant="contained"
              type="submit"
              sx={{
                color: "white",
              }}
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => navigate(_path)}
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default AddProducts;
