import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Checkbox,
  Stack,
  Divider,
  TextField,
  Button,
  Autocomplete,
} from "@mui/material";
import { useTimezoneSelect, allTimezones } from "react-timezone-select";
import { useDispatch } from "react-redux";
import moment from "moment/moment";
import { useNavigate, useParams } from "react-router";
import dayjs from "dayjs";

import {
  FilePicker,
  InputDate,
  InputField,
  SelectBox,
} from "../../../component";
import { handleLoader, setToast } from "../../../store/reducer";
import errorsSetter from "../../../helpers/error-setter";
import ApiManager from "../../../services/api-manager";
import usePageTitle from "../../../hooks/use-page-title";

const labelStyle = "original";
const dateFormat = "YYYY-MM-DD";
const timezones = { ...allTimezones };
const initialValue = {
  card_layout: "One K All",
  card_type: "Mifare Classic",
  attendee_policy: "always",
  allowed_ticket_types: "single",
  attendee_tag: "wristband",
  staff_tag: "wristband",
  vouchers_allowed: 1,
  allow_online_topups: 1,
  allow_refund_at_the_end_of_event: 1,
};

const CreateEvent = () => {
  const params = useParams();
  const eventID = params?.id;
  usePageTitle(eventID ? "Update Event" : "Create Event");
  const [currenciesData, setCurrenciesData] = useState({
    isLoading: true,
    message: "Loading currencies data from server...",
    data: [],
  });
  const [venuesData, setVenuesData] = useState({
    isLoading: true,
    message: "Loading venues from server...",
    data: [],
  });
  const [formData, setFormData] = useState({ ...initialValue });
  const [formErrors, setFormErrors] = useState({});
  const { options } = useTimezoneSelect({
    labelStyle,
    timezones,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));

  useEffect(() => {
    async function init() {
      let { data: _d } = await ApiManager("get", "organizer/currencies");
      setCurrenciesData({
        isLoading: false,
        message:
          _d?.currencies?.length === 0 ? "Currencies data not found" : "",
        data: _d?.currencies.map((item) => ({
          label: item.currency_name + ", " + item.currency_symbol,
          value: item?.currency_id,
        })),
      });
      let { data } = await ApiManager("get", "organizer/get-active-venues");
      setVenuesData({
        isLoading: false,
        message: data.length === 0 ? "Currencies data not found" : "",
        data: data.map((item) => ({
          label: `${item.name} , capacity(${item.seating_capacity}) `,
          value: item?.venue_id,
        })),
      });
    }
    init();
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    async function init() {
      if (eventID) {
        try {
          dispatch(handleLoader(true));
          let { data } = await ApiManager("get", `organizer/events/${eventID}`);
          setFormData({
            ...data,
            start_date: moment(data?.start_date),
            end_date: moment(data?.end_date),
            currency_id: data?.currency?.currency_id || "",
            allow_online_topups: data?.allow_online_topups ? 1 : 0,
            allow_refund_at_the_end_of_event:
              data?.allow_refund_at_the_end_of_event ? 1 : 0,
            vouchers_allowed: data?.vouchers_allowed ? 1 : 0,
          });
        } catch (error) {
          showMessage("error", error?.response?.data?.error?.message);
        } finally {
          dispatch(handleLoader(false));
        }
      } else {
        setFormData((prev) => ({
          ...prev,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        }));
      }
    }
    init();
  }, [eventID]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const validate = () => {
    let errors = {};
    let flag = true;
    if (!!!formData?.image && !eventID) {
      errors.image = "File required.";
      flag = false;
    }
    if (formData?.start_date?.isAfter(formData?.end_date)) {
      flag = false;
      showMessage("warning", "Start date cannot be after the end date");
    }
    if (formData?.end_date?.isBefore(formData?.start_date)) {
      flag = false;
      showMessage("warning", "End date cannot be before the start date.");
    }

    setFormErrors((prev) => ({ ...prev, ...errors }));
    return flag;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        dispatch(handleLoader(true));
        setFormErrors({});
        let _url = eventID ? `organizer/events/${eventID}` : "organizer/events";
        let _Obj = {
          ...formData,
          start_date: moment(formData?.start_date).format(dateFormat),
          end_date: moment(formData?.end_date).format(dateFormat),
        };
        if (eventID) {
          _Obj._method = "patch";
        }
        if (!!_Obj?.image && typeof _Obj?.image !== "object") {
          delete _Obj.image;
        }
        let _fd = new FormData();
        for (const key in _Obj) {
          if (Object.hasOwnProperty.call(_Obj, key)) {
            _fd.append(key, _Obj[key]);
          }
        }
        await ApiManager("post", _url, _fd, {
          "Content-Type": "multipart/form-data",
        });
        if (eventID) {
          showMessage("success", "Event updated successfully.");
        } else {
          showMessage("success", "Event created successfully.");
        }
        navigate("/events");
      } catch (error) {
        if (error?.response?.status === 422) {
          setFormErrors(errorsSetter(error));
        }
        if (error?.response?.status === 400) {
          showMessage("error", error?.response?.data);
        } else {
          showMessage("error", error?.response?.data?.error?.message);
        }
      } finally {
        dispatch(handleLoader(false));
      }
    }
  };

  return (
    <Box m={{ sm: 2 }}>
      <Box component={Paper} elevation={6} p={2}>
        <Box
          component="form"
          autoComplete="off"
          autoCapitalize="off"
          onSubmit={handleSubmit}
        >
          <Typography variant="h5">
            {!!eventID ? "Update" : "Create"} Event
          </Typography>
          <Typography variant="body1">
            Create an Event to start using
          </Typography>

          <Grid container columnSpacing={3} mt={2} rowSpacing={2}>
            <Grid item xs={12}>
              <FilePicker
                onChange={(e) => {
                  const file = e.target.files[0];
                  setFormData((prev) => ({
                    ...prev,
                    image: file,
                    image_url: URL.createObjectURL(file),
                  }));
                }}
                labelTop="Event Logo"
                error={formErrors?.image}
                imageUrl={formData?.image_url}
                helperText="Recommended file dimension[WxH] : 100x56"
                title="Pick a file"
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <Box>
                <InputField
                  label="Name"
                  size="small"
                  name="name"
                  required
                  error={formErrors?.name}
                  value={formData?.name}
                  onChange={handleInputChange}
                />
              </Box>
            </Grid>
            <Grid item sm={4} xs={12}>
              <InputField
                label="Event Portal Url"
                size="small"
                name="portal_url"
                required
                error={formErrors?.portal_url}
                value={formData?.portal_url}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <SelectBox
                required
                fullWidth
                items={[
                  { label: "Always", value: "always" },
                  {
                    label: "If Wristband Matched",
                    value: "if_wristband_matched",
                  },
                  { label: "On Fullfill", value: "on_fulfill" },
                ]}
                label="Attendee Creation Policy"
                name="attendee_policy"
                error={formErrors?.attendee_policy}
                value={formData?.attendee_policy}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
          <Grid container columnSpacing={3} mt={2} rowSpacing={2}>
            <Grid item sm={4} xs={12}>
              <SelectBox
                fullWidth
                items={[{ label: "Mifare Classic", value: "Mifare Classic" }]}
                label="Chip Type"
                size="small"
                name="card_type"
                required
                error={formErrors?.card_type}
                value={formData?.card_type}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <SelectBox
                fullWidth
                items={[{ label: "One K All", value: "One K All" }]}
                label="Card Layout"
                name="card_layout"
                required
                error={formErrors?.card_layout}
                value={formData?.card_layout}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <SelectBox
                fullWidth
                items={[
                  { label: "Single", value: "single" },
                  { label: "Multiple", value: "multiple" },
                ]}
                label="Allowed Ticket Types"
                name="allowed_ticket_types"
                required
                error={formErrors?.allowed_ticket_types}
                value={formData?.allowed_ticket_types}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={options}
                fullWidth
                size="small"
                value={formData?.timezone}
                key={formData?.timezone}
                renderInput={(params) => (
                  <TextField {...params} label="Timezone" />
                )}
                onChange={(e, val) =>
                  setFormData((prev) => ({ ...prev, timezone: val?.value }))
                }
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <SelectBox
                fullWidth
                items={[
                  { label: "Yes", value: 1 },
                  { label: "No", value: 0 },
                ]}
                label="Vouchers allowed"
                name="vouchers_allowed"
                required
                error={formErrors?.vouchers_allowed}
                value={formData?.vouchers_allowed ? 1 : 0}
                onChange={handleInputChange}
                helperText={
                  formData?.vouchers_allowed
                    ? "This option is functional when the device is connected to the internet."
                    : ""
                }
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <SelectBox
                fullWidth
                items={[
                  { label: "Yes", value: 1 },
                  { label: "No", value: 0 },
                ]}
                label="Allow refund at the end of event"
                name="allow_refund_at_the_end_of_event"
                required
                error={formErrors?.allow_refund_at_the_end_of_event}
                value={formData?.allow_refund_at_the_end_of_event ? 1 : 0}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <SelectBox
                fullWidth
                items={[
                  { label: "Yes", value: 1 },
                  { label: "No", value: 0 },
                ]}
                label="Allow online topups"
                name="allow_online_topups"
                required
                error={formErrors?.allow_online_topups}
                value={formData?.allow_online_topups ? 1 : 0}
                onChange={handleInputChange}
                helperText={
                  formData?.allow_online_topups
                    ? "This option is functional when the device is connected to the internet."
                    : ""
                }
              />
            </Grid>
          </Grid>
          <Stack direction={"row"} alignItems="center" mt={2}>
            <Stack direction={"row"} alignItems="center">
              <Checkbox
                value={formData?.card_memory}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    card_memory: e.target.checked,
                  }))
                }
              />
              <Typography>Use card memory</Typography>
            </Stack>
            <Stack direction={"row"} alignItems="center">
              <Checkbox
                value={formData?.qr_to_attendee}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    qr_to_attendee: e.target.checked,
                  }))
                }
              />
              <Typography>Show QR to attendee</Typography>
            </Stack>
          </Stack>

          <Divider
            sx={{
              my: 3,
            }}
          />
          <Box my={2}>
            <Typography variant="h5">Event Currency </Typography>

            <Grid container columnSpacing={3} mt={0.2} rowSpacing={2}>
              <Grid item sm={4} xs={12}>
                {currenciesData?.isLoading ? (
                  <Typography variant="span">
                    {currenciesData?.message}
                  </Typography>
                ) : (
                  <SelectBox
                    fullWidth
                    items={currenciesData?.data}
                    label="Currency"
                    name="currency_id"
                    required
                    error={formErrors?.currency_id}
                    value={formData?.currency_id}
                    onChange={handleInputChange}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
          <Divider
            sx={{
              my: 3,
            }}
          />
          <Box my={2}>
            <Typography variant="h5">Tag For Factor Setting </Typography>

            <Grid container columnSpacing={3} mt={0.2} rowSpacing={2}>
              <Grid item sm={4} xs={12}>
                <SelectBox
                  fullWidth
                  items={[
                    { label: "Wristband", value: "wristband" },
                    { label: "Card", value: "card" },
                    { label: "Badge", value: "badge" },
                  ]}
                  label="Attendee Tag Form Factor"
                  name="attendee_tag"
                  required
                  error={formErrors?.attendee_tag}
                  value={formData?.attendee_tag}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <SelectBox
                  fullWidth
                  items={[
                    { label: "Wristband", value: "wristband" },
                    { label: "Card", value: "card" },
                    { label: "Badge", value: "badge" },
                  ]}
                  label="Staff Tag Form Factor"
                  size="small"
                  name="staff_tag"
                  required
                  error={formErrors?.staff_tag}
                  value={formData?.staff_tag}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
          </Box>
          <Divider
            sx={{
              my: 1,
            }}
          />
          <Box my={2}>
            <Typography variant="h5">Event Time Settings </Typography>

            <Grid container columnSpacing={3} mt={0.2} rowSpacing={2}>
              <Grid item sm={4} xs={12}>
                <InputDate
                  labelTop="Start Date"
                  label={'"month", "day" and "year"'}
                  error={formErrors?.start_date}
                  value={moment(formData?.start_date)}
                  onChange={(val) =>
                    setFormData((prev) => ({ ...prev, start_date: val }))
                  }
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <InputDate
                  labelTop="End Date"
                  label={'"month", "day" and "year"'}
                  error={formErrors?.end_date}
                  value={moment(formData?.end_date)}
                  onChange={(val) =>
                    setFormData((prev) => ({ ...prev, end_date: val }))
                  }
                />
              </Grid>
            </Grid>
          </Box>
          <Divider
            sx={{
              my: 1,
            }}
          />
          <Box mt={2} mb={4}>
            <Typography variant="h5">Event Venue </Typography>

            <Grid container columnSpacing={3} mt={0.2} rowSpacing={2}>
              <Grid item sm={4} xs={12}>
                {venuesData?.isLoading ? (
                  <Typography variant="span">{venuesData?.message}</Typography>
                ) : (
                  <SelectBox
                    fullWidth
                    items={venuesData?.data}
                    label="Venue"
                    name="venue_id"
                    required
                    error={formErrors?.venue_id}
                    value={formData?.venue_id}
                    onChange={handleInputChange}
                  />
                )}
              </Grid>
            </Grid>
          </Box>

          <Stack direction="row" gap={2} mt={2}>
            <Button
              variant="contained"
              type="submit"
              sx={{
                color: "white",
              }}
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => navigate("/events")}
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateEvent;
