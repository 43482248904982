import React, { useState, useEffect, Fragment } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Stack,
  Button,
  Divider,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import { convertFromHTML, convertToHTML } from "draft-convert";

import { FilePicker, InputField } from "../../../../component";
import { handleLoader, setToast } from "../../../../store/reducer";
import errorsSetter from "../../../../helpers/error-setter";
import ApiManager from "../../../../services/api-manager";
import usePageTitle from "../../../../hooks/use-page-title";
import DOMPurify from "dompurify";

const CreateBlog = () => {
  const { blogId } = useParams();
  usePageTitle(blogId ? "Update" : "Create" + "Blog");
  const [formData, setFormData] = useState({
    display_on_home_page: 1,
  });
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(),
  );
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const goBack = () => navigate("/admin/blogs");
  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    async function init() {
      if (blogId) {
        try {
          dispatch(handleLoader(true));
          let { data } = await ApiManager("get", `blogs/${blogId}`);
          setFormData({
            banner_image_url: data?.banner_image_url,
            display_on_home_page: data?.display_on_home_page ? 1 : 0,
            feature_image_url: data?.feature_image_url,
            tagline: data?.tagline,
            title: data?.title,
          });
          const editorState = EditorState.createWithContent(
            convertFromHTML(data.description),
          );
          setEditorState(editorState);
        } catch (error) {
          console.log("🚀 ~ file: create-blog.js:42 ~ init ~ error:", error);
          showMessage("error", error?.response?.data?.error?.message);
        } finally {
          dispatch(handleLoader(false));
        }
      }
    }
    init();
  }, [blogId]);

  useEffect(() => {
    let html = convertToHTML(editorState.getCurrentContent());
    setFormData((prev) => ({
      ...prev,
      description: replaceEmptyPTagWithBrTa(html),
    }));
  }, [editorState]);

  // function createMarkup(html) {
  //   return {
  //     __html: DOMPurify.sanitize(html),
  //   };
  // }

  const validate = () => {
    let errors = {};
    let flag = true;
    if (!blogId && !formData?.banner_image) {
      errors.banner_image = "Banner image is required.";
      flag = false;
    }
    if (!blogId && !formData?.feature_image) {
      errors.feature_image = "Feature image is required.";
      flag = false;
    }
    if (!formData?.tagline) {
      errors.tagline = "Tagline is required";
      flag = false;
    }
    if (!formData?.title) {
      errors.tagline = "Title is required";
      flag = false;
    }
    if (formData?.description === "<br/>") {
      console.log(
        "🚀 ~ file: create-blog.js:91 ~ validate ~ formData?.description:",
        formErrors,
      );
      errors.description = "Description is required";
      flag = false;
    }
    setFormErrors((prev) => ({ ...prev, ...errors }));
    return flag;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormErrors({});
    if (!validate()) {
      return;
    }
    try {
      dispatch(handleLoader(true));
      let _url = blogId ? `blogs/${blogId}` : "blogs";
      let _fd = new FormData();
      for (const key in formData) {
        if (Object.hasOwnProperty.call(formData, key)) {
          _fd.append(key, formData[key]);
        }
      }

      if (blogId) {
        _fd.append("_method", "patch");
      }

      await ApiManager("post", _url, _fd, {
        "Content-Type": "multipart/form-data",
      });
      showMessage(
        "success",
        `Blog ${!!blogId ? "updated" : "added"} successfully.`,
      );
      goBack();
    } catch (error) {
      if (error?.response?.status === 422) {
        setFormErrors(errorsSetter(error));
      } else {
        showMessage("error", error?.response?.data?.error?.message);
      }
    } finally {
      dispatch(handleLoader(false));
    }
  };

  const formInputs = () => {
    return (
      <Grid container columnSpacing={3} mt={2} rowSpacing={2}>
        <Grid item sm={4} xs={12}>
          <InputField
            label="Title"
            name="title"
            required
            error={formErrors?.title}
            value={formData?.title}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <InputField
            label="Tagline"
            name="tagline"
            required
            error={formErrors?.tagline}
            value={formData?.tagline}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <FormControlLabel
            control={<Switch name="display_on_home_page" />}
            onChange={(e) => {
              setFormData((prev) => ({
                ...prev,
                display_on_home_page: e.target.checked ? 1 : 0,
              }));
            }}
            checked={Boolean(formData?.display_on_home_page)}
            label="Display on home page"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography mb={0.5}>Description</Typography>
          <Editor
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            editorState={editorState}
            onEditorStateChange={setEditorState}
          />
          {formErrors.description ? (
            <Typography fontSize={12} color="red">
              {formErrors.description}
            </Typography>
          ) : (
            ""
          )}
        </Grid>
        <Grid item xs={12}>
          <Box width={"100%"}>
            <FilePicker
              labelTop="Banner image"
              name="banner_image"
              _id="banner_image"
              onChange={(e) => {
                const file = e.target.files[0];
                setFormData((prev) => ({
                  ...prev,
                  banner_image: file,
                  banner_image_url: file && URL?.createObjectURL(file),
                }));
              }}
              error={formErrors?.banner_image}
              imageUrl={formData?.banner_image_url}
              boxWidth="100%"
              boxHeight="100%"
              boxMinHeight={{ sm: "600px", xs: "300px" }}
              boxMaxHeight="1000px"
              helperText="Recommended size 1920/1080px"
            />
          </Box>
        </Grid>{" "}
        <Grid item xs={12}>
          <Box
            width={"100%"}
            sx={{ minWidth: { sm: "300px" }, maxWidth: "350px" }}
          >
            <FilePicker
              labelTop="Feature image"
              name="feature_image"
              _id="feature_image"
              onChange={(e) => {
                const file = e.target.files[0];
                setFormData((prev) => ({
                  ...prev,
                  feature_image: file,
                  feature_image_url: file && URL?.createObjectURL(file),
                }));
              }}
              error={formErrors?.feature_image}
              imageUrl={formData?.feature_image_url}
              boxWidth="210px"
              boxHeight="160px"
              helperText="Recommended size 210/160px"
            />
          </Box>
        </Grid>
      </Grid>
    );
  };

  return (
    <Box m={{ sm: 2 }}>
      <Box
        component={Paper}
        elevation={6}
        sx={{
          p: 2,
        }}
      >
        <Box
          component="form"
          autoCapitalize="off"
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <Typography variant="h5">{blogId ? "Update" : "Add"} Blog</Typography>
          {formInputs()}

          <Box mt={2}>
            <Divider />
          </Box>
          <Stack direction="row" gap={2} mt={2}>
            <Button
              variant="contained"
              sx={{
                color: "white",
              }}
              type="submit"
            >
              Submit
            </Button>
            <Button variant="contained" color="error" onClick={goBack}>
              Cancel
            </Button>
          </Stack>
        </Box>
      </Box>

      {/* <div className="preview" dangerouslySetInnerHTML={createMarkup(convertedContent)}></div> */}
    </Box>
  );
};

function replaceEmptyPTagWithBrTa(htmlString) {
  return htmlString.replace(/<p><\/p>/gi, "<br/>");
}

export default CreateBlog;
