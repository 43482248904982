import React from "react";
import { Box, Paper, Typography } from "@mui/material";

const About = () => {
  return (
    <Box m={{ sm: 2 }}>
      <Paper elevation={6}>
        <Typography
          variant="h5"
          color="initial"
          sx={{
            p: 3,
          }}
        >
          Current Application Version:
          <Box
            component="span"
            sx={{
              fontWeight: "bold",
            }}
          >
            2.0.23
          </Box>
        </Typography>
      </Paper>
    </Box>
  );
};

export default About;
