import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Stack, TableCell, Fab } from "@mui/material";
import { Link, useLocation, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import moment from "moment/moment";
import { useSelector } from "react-redux";

import {
  ExportBtn,
  InputField,
  StyledTableCell,
  StyledTableRow,
  TablePagination,
  TableWrapper,
} from "../../../../component";
import ApiManager from "../../../../services/api-manager";
import usePageTitle from "../../../../hooks/use-page-title";
import Utils from "../../../../utils/utils";
import RefreshIcon from "@mui/icons-material/Refresh";

const GetTopUpAgainstCard = () => {
  usePageTitle("Get TopUp Against Card");
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const paymentType = params.get("type"); // bar
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [searchCalled, setSearchCalled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState(null);
  const [search, setSearch] = useState("");
  const { id, orderId } = useParams();
  const { user, loggedEvent } = useSelector((state) => state.storeReducer);
  const _role = user?.role === "super_admin" ? "superAdmin" : "organizer";
  const _symbol = loggedEvent.currencySymbol;
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    getData();
  }, [page, rowsPerPage, id]);

  const getData = async (_search = "") => {
    try {
      setIsLoading(true);
      let { data } = await ApiManager(
        "get",
        `${_role}/topups-test-card/${orderId}?payment_type=${paymentType}&search=${search}&page=${page}&per_page=${rowsPerPage}`,
      );
      setRecords(data);
      // setColumns(data?.columns);
    } catch (error) {
      console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const onChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <Box>
        <Stack direction={"row"} alignItems={"center"}>
          <Fab component={Link} to={`/event/${id}/test-card`} size="small">
            <ArrowBackIcon />
          </Fab>
          <Typography variant="h4" color="initial" fontWeight={"bold"} m={2}>
            Get top up against {paymentType === "cash" ? "cash" : "card"}
          </Typography>
        </Stack>
        <Stack
          direction={{ sm: "row", xs: "column" }}
          justifyContent={{ sm: "space-between", xs: "center" }}
          my={2}
        >
          <Stack
            direction={{ sm: "row", xs: "column" }}
            justifyContent={{ sm: "start", xs: "center" }}
          >
            <Button startIcon={<RefreshIcon />} onClick={() => getData()}>
              Refresh
            </Button>
            {/* <ExportBtn data={columns} path={`reports-event-topups-csv?event_id=${id}&`} /> */}
          </Stack>

          <Stack direction="row" gap={2} alignItems={"center"}>
            <InputField
              label="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            {!!search && (
              <Button
                onClick={() => {
                  if (page === 1) {
                    getData(search);
                  } else {
                    setPage(1);
                  }
                  setSearchCalled(true);
                }}
                variant="contained"
                sx={{ color: "white" }}
              >
                Search
              </Button>
            )}
            {searchCalled && (
              <Button
                onClick={() => {
                  setSearch("");
                  if (page === 1) {
                    getData();
                  } else {
                    setPage(1);
                  }
                  setSearchCalled(false);
                }}
                variant="contained"
                color="error"
              >
                Clear
              </Button>
            )}
          </Stack>
        </Stack>

        <TableWrapper
          pagination={
            <TablePagination
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              perPage={records?.per_page || 0}
              total={records?.total || 0}
            />
          }
          spanTd={thLabels.length}
          isLoading={isLoading}
          isContent={records?.length || false}
          thContent={
            <>
              {thLabels.map((v) => (
                <TableCell key={v}>{v}</TableCell>
              ))}
            </>
          }
        >
          {records?.map((item, i) => (
            <StyledTableRow key={i}>
              <StyledTableCell>{item?.code}</StyledTableCell>
              <StyledTableCell>
                {_symbol}
                {Utils.formatWithSuffix(item?.amount)}
              </StyledTableCell>
              <StyledTableCell>
                {item?.payment_type === "credit_card" ? "Credit card" : "Cash"}
              </StyledTableCell>
              <StyledTableCell>
                {item?.status === "AUTHORIZED" ? "Success" : "Failed"}
              </StyledTableCell>
              <StyledTableCell>
                {moment(item?.created_at).format(
                  process.env.REACT_APP_DATE_FORMAT,
                )}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableWrapper>
      </Box>
    </div>
  );
};

const thLabels = [
  "RFID Number",
  "Amount",
  "Payment type",
  "Status",
  "Created At",
];

export default GetTopUpAgainstCard;
