import React, { useEffect } from "react";
import { Box, Paper, Fab, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { handleLoader, setToast } from "../../../../store/reducer";
import ApiManager from "../../../../services/api-manager";
import usePageTitle from "../../../../hooks/use-page-title";

const SpViewEmail = () => {
  usePageTitle("View Email");
  const { id } = useParams();
  const [record, setRecord] = React.useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));

  useEffect(() => {
    async function init() {
      if (id) {
        try {
          dispatch(handleLoader(true));
          let { data } = await ApiManager(
            "get",
            `superAdmin/get-contact-us/${id}`,
          );
          setRecord(data);
          if (!data?.read) {
            await ApiManager("post", `superAdmin/update-contact-us/${id}`, {});
          }
        } catch (error) {
          showMessage("error", error?.response?.data?.error?.message);
        } finally {
          dispatch(handleLoader(false));
        }
      }
    }
    init();
  }, [id]);

  return (
    <Box m={{ sm: 2 }}>
      <Box
        component={Paper}
        elevation={6}
        sx={{
          p: 2,
        }}
      >
        <Fab onClick={() => navigate("/admin/contact")} size="small">
          <ArrowBackIcon />
        </Fab>
        <br />
        <br />
        <Box>
          <Typography sx={styles.hd}>Full Name</Typography>
          <Typography sx={styles.text}>
            {record?.first_name
              ? record?.first_name + " " + record?.last_name
              : "[Name]"}
          </Typography>
          <Typography sx={styles.hd}>Email</Typography>
          <Typography sx={styles.text}>{record?.email || "[Email]"}</Typography>
          <Typography sx={styles.hd}>Message</Typography>
          <Typography sx={styles.text}>
            {record?.message || "[message]"}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default SpViewEmail;

const styles = {
  hd: {
    fontSize: 18,
    fontWeight: "bold",
    mb: 0.5,
  },
  text: {
    fontSize: 16,
    mb: 2,
  },
};
