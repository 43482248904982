import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Stack,
  TableCell,
  Tooltip,
  IconButton,
  Fab,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import RefreshIcon from "@mui/icons-material/Refresh";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { useSelector } from "react-redux";
import {
  ExportBtn,
  InputField,
  StyledTableCell,
  StyledTableRow,
  TablePagination,
  TableWrapper,
} from "../../../../component";
import ApiManager from "../../../../services/api-manager";
import usePageTitle from "../../../../hooks/use-page-title";
import Utils from "../../../../utils/utils";
import moment from "moment/moment";
import VisibilityIcon from "@mui/icons-material/Visibility";

const GetOrdersAgainstCard = () => {
  usePageTitle("Get Orders Against Card");
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [searchCalled, setSearchCalled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState(null);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const { user, loggedEvent } = useSelector((state) => state.storeReducer);
  const _role = user?.role === "super_admin" ? "superAdmin" : "organizer";
  const { id, orderId } = useParams();
  const _symbol = loggedEvent.currencySymbol;
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    getData();
  }, [page, rowsPerPage, id]);

  const getData = async (_search = "") => {
    try {
      setIsLoading(true);
      //   let { data } = await ApiManager("get", `${_role}/reports?revenue=1&event_id=${id}&page=${page}&per_page=${rowsPerPage}&search=${_search}`);
      let { data } = await ApiManager(
        "get",
        `${_role}/orders-test-card/${orderId}`,
      );
      // setColumns(data?.columns);
      console.log(
        "🚀 ~ file: get-orders-against-card.js:39 ~ getData ~ data:",
        data,
      );
      setRecords(data);
    } catch (error) {
      console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const onChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <Box>
        <Stack direction={"row"} alignItems={"center"}>
          <Fab component={Link} to={`/event/${id}/test-card`} size="small">
            <ArrowBackIcon />
          </Fab>
          <Typography variant="h4" color="initial" fontWeight={"bold"} m={2}>
            Get orders against card
          </Typography>
        </Stack>
        <Stack
          direction={{ sm: "row", xs: "column" }}
          justifyContent={{ sm: "space-between", xs: "center" }}
          my={2}
        >
          <Stack
            direction={{ sm: "row", xs: "column" }}
            justifyContent={{ sm: "start", xs: "center" }}
          >
            <Button startIcon={<RefreshIcon />} onClick={() => getData()}>
              Refresh
            </Button>
            {/* <ExportBtn data={columns} path={`reports-event-revenue-csv?event_id=${id}&`} /> */}
          </Stack>
          <Stack direction="row" gap={2} alignItems={"center"}>
            <InputField
              label="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            {!!search && (
              <Button
                onClick={() => {
                  if (page === 1) {
                    getData(search);
                  } else {
                    setPage(1);
                  }
                  setSearchCalled(true);
                }}
                variant="contained"
                sx={{ color: "white" }}
              >
                Search
              </Button>
            )}
            {searchCalled && (
              <Button
                onClick={() => {
                  setSearch("");
                  if (page === 1) {
                    getData();
                  } else {
                    setPage(1);
                  }
                  setSearchCalled(false);
                }}
                variant="contained"
                color="error"
              >
                Clear
              </Button>
            )}
          </Stack>
        </Stack>
        <TableWrapper
          pagination={
            <TablePagination
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              perPage={records?.per_page || 0}
              total={records?.total || 0}
            />
          }
          spanTd={thLabels.length}
          isLoading={isLoading}
          isContent={records?.length || false}
          thContent={
            <>
              {thLabels.map((v) => (
                <TableCell key={v}>{v}</TableCell>
              ))}
            </>
          }
        >
          {records?.map((item, i) => (
            <StyledTableRow key={i}>
              <StyledTableCell>{item?.order_id}</StyledTableCell>
              <StyledTableCell>{item?.code}</StyledTableCell>
              {/* <StyledTableCell>{item?.device?.vendor_info?.name}</StyledTableCell> */}
              <StyledTableCell>
                {Utils.capitalize(item?.payment_status)}
              </StyledTableCell>
              <StyledTableCell>
                {moment(item?.created_at).format(
                  process.env.REACT_APP_DATE_FORMAT,
                )}
              </StyledTableCell>
              <StyledTableCell>
                {_symbol}
                {item?.tip}
              </StyledTableCell>
              <StyledTableCell>{item?.total_items}</StyledTableCell>
              <StyledTableCell>
                {_symbol}
                {item?.amount}
              </StyledTableCell>
              <StyledTableCell>
                {_symbol}
                {item?.refund_amount}
              </StyledTableCell>
              <StyledTableCell>
                {_symbol}
                {item?.actual_amount}
              </StyledTableCell>
              <StyledTableCell>
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <Tooltip title="View Details" placement="top">
                    <IconButton
                      color="info"
                      onClick={() =>
                        navigate(
                          `/event/${id}/test-card/get-order-against-card/${item?.order_id}/order-details`,
                        )
                      }
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableWrapper>
      </Box>
    </div>
  );
};

const thLabels = [
  "ID",
  "RFID Number",
  // "Vendor",
  "Payment Status",
  "Created At",
  "Tip",
  "Items #",
  "Amount",
  "Refunded Amount",
  "Actual Amount",
  "Details",
];

export default GetOrdersAgainstCard;
