import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Stack,
  Button,
  Divider,
  InputAdornment,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { InputField } from "../../../../../../../component";
import usePageTitle from "../../../../../../../hooks/use-page-title";
import { handleLoader, setToast } from "../../../../../../../store/reducer";
import ApiManager from "../../../../../../../services/api-manager";
import errorsSetter from "../../../../../../../helpers/error-setter";

const AddCost = () => {
  const { id, vendorID, costID = null } = useParams();
  usePageTitle(`${costID ? "Update" : "Add"} Cost`);
  const [formData, setFormData] = useState();
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loggedEvent } = useSelector((state) => state.storeReducer);
  const _symbol = loggedEvent.currencySymbol;

  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));
  const _path = `/event/${id}/management/cashless/vendors/manage/${vendorID}/cost`;

  useEffect(() => {
    async function init() {
      if (costID) {
        try {
          dispatch(handleLoader(true));
          let { data } = await ApiManager(
            "get",
            `organizer/vendor-cost/${costID}`,
          );
          setFormData({ amount: data?.amount, reason: data?.reason });
        } catch (error) {
          showMessage("error", error?.response?.data?.error?.message);
        } finally {
          dispatch(handleLoader(false));
        }
      }
    }
    init();
  }, [costID]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      dispatch(handleLoader(true));
      let _url = costID
        ? `organizer/vendor-cost/${costID}`
        : "organizer/vendor-cost";
      let _fd = { ...formData, vendor_id: vendorID, event_id: id };
      if (costID) {
        _fd._method = "patch";
      }
      await ApiManager("post", _url, _fd);
      if (costID) {
        showMessage("success", "Cost updated successfully.");
      } else {
        showMessage("success", "Cost created successfully.");
      }
      navigate(_path);
    } catch (error) {
      if (error?.response?.status === 422) {
        setFormErrors(errorsSetter(error));
      } else {
        showMessage("error", error?.response?.data?.error?.message);
      }
    } finally {
      dispatch(handleLoader(false));
    }
  };

  return (
    <Box m={{ sm: 2 }}>
      <Box component={Paper} elevation={6} p={2}>
        <Box
          component="form"
          autoComplete="off"
          onSubmit={handleSubmit}
          autoCapitalize="off"
        >
          <Typography variant="h5">{costID ? "Update" : "Add"} Cost</Typography>

          <Grid container columnSpacing={3} mt={2} rowSpacing={2}>
            <Grid item sm={7} xs={12}>
              <Box>
                <InputField
                  label="Amount"
                  size="small"
                  name="amount"
                  required
                  error={formErrors?.amount}
                  value={formData?.amount}
                  onChange={handleInputChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">{_symbol}</InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Grid>
            <Grid item sm={7} xs={12}>
              <InputField
                label="Reason"
                size="small"
                name="reason"
                type="number"
                required
                multiline
                rows={4}
                error={formErrors?.reason}
                value={formData?.reason}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
          <Divider
            sx={{
              my: 2,
            }}
          />
          <Stack direction="row" gap={2} mt={2}>
            <Button
              variant="contained"
              type="submit"
              sx={{
                color: "white",
              }}
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => navigate(_path)}
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default AddCost;
