import React, { useEffect, useState } from "react";
import ManageLayout from "../manage-layout";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, IconButton, Stack, TableCell, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import {
  ConfirmationModal,
  StyledTableCell,
  StyledTableRow,
  TablePagination,
  TableWrapper,
} from "../../../../../../component";
import { handleLoader, setToast } from "../../../../../../store/reducer";
import ApiManager from "../../../../../../services/api-manager";
import usePageTitle from "../../../../../../hooks/use-page-title";

const Cost = () => {
  const { id, vendorID } = useParams();
  usePageTitle("Cost");
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState(null);
  const [recordID, setRecordID] = useState(null);
  const [modalState, setModalState] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loggedEvent, user } = useSelector((state) => state.storeReducer);
  const _symbol = loggedEvent.currencySymbol;
  const _role = user?.role === "super_admin" ? "superAdmin" : "organizer";
  const _isAdmin = user?.role === "super_admin" ? true : false;

  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));

  useEffect(() => {
    getData();
  }, [page, rowsPerPage]);

  const getData = async () => {
    try {
      setIsLoading(true);
      let { data } = await ApiManager(
        "get",
        `${_role}/vendor-cost?page=${page}&per_page=${rowsPerPage}&vendor_id=${vendorID}`,
      );
      setRecords(data);
    } catch (error) {
      console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const onChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openModal = (_id) => {
    setModalState(true);
    setRecordID(_id);
  };

  const closeModal = () => {
    setModalState(false);
    setRecordID(null);
  };

  const deleteRecord = async () => {
    try {
      dispatch(handleLoader(true));
      await ApiManager("delete", `${_role}/vendor-cost/${recordID}`);
      showMessage("success", "Vendor Cost deleted successfully.");
      closeModal();
      getData();
    } catch (error) {
      showMessage("error", error?.response?.data?.error?.message);
    } finally {
      dispatch(handleLoader(false));
    }
  };

  const thLabels = ["Amount", "Reason"];

  if (!_isAdmin) {
    thLabels.push("Manage");
  }

  return (
    <div>
      <ManageLayout>
        <Stack direction={{ sm: "row", xs: "column" }} my={2}>
          {!_isAdmin && (
            <>
              <Button
                onClick={() =>
                  navigate(
                    `/event/${id}/management/cashless/vendors/manage/${vendorID}/cost/create`,
                  )
                }
                startIcon={<AddIcon />}
              >
                Add Cost
              </Button>
            </>
          )}
        </Stack>

        <TableWrapper
          pagination={
            <TablePagination
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              perPage={records?.per_page || 0}
              total={records?.total || 0}
            />
          }
          spanTd={thLabels.length}
          isLoading={isLoading}
          isContent={records?.data?.length || false}
          thContent={
            <>
              {thLabels.map((v) => (
                <TableCell key={v}>{v}</TableCell>
              ))}
            </>
          }
        >
          {records?.data.map((item, i) => (
            <StyledTableRow key={i}>
              <StyledTableCell>
                {_symbol}
                {Number(item?.amount).toLocaleString()}
              </StyledTableCell>
              <StyledTableCell>{item?.reason}</StyledTableCell>
              {!_isAdmin && (
                <StyledTableCell>
                  <Stack direction="row" spacing={0.5} alignItems="center">
                    <Tooltip title="Edit" placement="top">
                      <IconButton
                        color="info"
                        onClick={() =>
                          navigate(
                            `/event/${id}/management/cashless/vendors/manage/${vendorID}/cost/update/${item?.vendor_cost_id}`,
                          )
                        }
                      >
                        <CreateRoundedIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete" placement="top">
                      <IconButton
                        color="error"
                        onClick={() => openModal(item?.vendor_cost_id)}
                      >
                        <DeleteForeverIcon />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </StyledTableCell>
              )}
            </StyledTableRow>
          ))}
        </TableWrapper>
      </ManageLayout>
      <ConfirmationModal
        callBack={deleteRecord}
        open={modalState}
        handleClose={closeModal}
      />
    </div>
  );
};

export default Cost;
