import React from "react";
import { Box, Container, Typography, Link } from "@mui/material";

import { AdminHeader } from "../../component";

const TermsAndConditions = () => {
  return (
    <Box>
      <AdminHeader hideProfile />

      <Container sx={{ my: 5 }}>
        <Typography variant="h4" gutterBottom>
          CXEvents B.V. Software Terms and Conditions
        </Typography>

        <ol>
          <li>
            <Typography variant="body1" paragraph>
              <strong>Acceptance of Terms:</strong> By accessing or using the
              CXEvents B.V. software ("the Software"), you agree to comply with
              and be bound by the following terms and conditions. If you do not
              agree with these terms, please refrain from using the Software.
            </Typography>
          </li>

          <li>
            <Typography variant="body1" paragraph>
              <strong>Use of the Software:</strong>
              <ul>
                <li>
                  <strong>Authorized Use:</strong> The Software is licensed for
                  use in conjunction with CXEvents B.V. events only. Any
                  unauthorized use is strictly prohibited.
                </li>
                <li>
                  <strong>Responsibility:</strong> Users are responsible for
                  maintaining the confidentiality of their login information and
                  ensuring the security of their accounts.
                </li>
              </ul>
            </Typography>
          </li>

          <li>
            <Typography variant="body1" paragraph>
              <strong>Limitation of Liability:</strong>
              <ul>
                <li>
                  DDoS Attacks: Any disruption caused by Distributed Denial of
                  Service (DDoS) attacks, including temporary unavailability or
                  slowdown of the Software.
                </li>
                <li>
                  Incorrect Reporting: Inaccuracies or discrepancies in live
                  reporting data. Users acknowledge that live statistics are
                  provisional and subject to final verification.
                </li>
                <li>
                  Damaged Devices: Any damage to hardware or software resulting
                  from the use of the Software.
                </li>
                <li>
                  Networking Issues: Disruptions or errors caused by network
                  packet loss, latency, or any other networking issues.
                </li>
              </ul>
            </Typography>
          </li>

          <li>
            <Typography variant="body1">
              <strong>Indemnification:</strong> Users agree to indemnify and
              hold CXEvents B.V., its affiliates, employees, and partners
              harmless from any claims, damages, or liabilities arising out of
              their use of the Software.
            </Typography>
          </li>

          <li>
            <Typography variant="body1" paragraph>
              <strong>Termination of Services:</strong> CXEvents B.V. reserves
              the right to terminate or suspend services at any time, with or
              without cause, including but not limited to violation of these
              terms.
            </Typography>
          </li>

          <li>
            <Typography variant="body1" paragraph>
              <strong>Privacy and Data Security:</strong>
              <ul>
                <li>
                  <strong>Data Collection:</strong> CXEvents B.V. may collect
                  and process user data as outlined in the Privacy Policy.
                </li>
                <li>
                  <strong>Security:</strong> While CXEvents B.V. takes
                  reasonable measures to protect data, users acknowledge the
                  inherent risks of data transmission over the internet.
                </li>
              </ul>
            </Typography>
          </li>

          <li>
            <Typography variant="body1">
              <strong>Updates and Modifications:</strong> CXEvents B.V. reserves
              the right to update, modify, or discontinue the Software, with or
              without notice.
            </Typography>
          </li>

          <li>
            <Typography variant="body1">
              <strong>Governing Law:</strong> These terms and conditions are
              governed by and construed in accordance with the laws of Aruba,
              and users submit to the exclusive jurisdiction of the courts in
              Aruba.
            </Typography>
          </li>

          <li>
            <Typography variant="body1">
              <strong>Changes to Terms:</strong> CXEvents B.V. reserves the
              right to revise these terms at any time. Users are responsible for
              reviewing the terms periodically.
            </Typography>
          </li>

          <li>
            <Typography variant="body1" paragraph>
              <strong>Fraudulent Data:</strong> CXEvents B.V. retains the right
              to modify data in reports if found to be fraudulent. In such
              cases, a detailed case report outlining the changes made will be
              provided to the user.
            </Typography>
          </li>

          <li>
            <Typography variant="body1" paragraph>
              <strong>Dispute Resolution:</strong> In the event of any dispute
              or claim arising out of or in connection with the Software, users
              agree to first attempt to resolve the matter through arbitration
              or mediation before pursuing legal action.
            </Typography>
          </li>

          <li>
            <Typography variant="body1" paragraph>
              <strong>Contact Information:</strong> For any inquiries regarding
              these terms and conditions, please contact CXEvents B.V. at{" "}
              <Link href="mailto:director@cxpay.events">
                director@cxpay.events
              </Link>{" "}
              or <Link href="tel:+2976416370">+2976416370</Link>.
            </Typography>
          </li>
        </ol>

        <Typography variant="body1" paragraph>
          By using the Software, you acknowledge that you have read, understood,
          and agree to these terms and conditions.
        </Typography>
      </Container>
    </Box>
  );
};

export default TermsAndConditions;
