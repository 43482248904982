import Utils from "../../utils/utils";
import { onBoardUI1, onBoardUI2 } from "../../assets";

export const onBoardSteps = [
  {
    id: Utils.generateId(),
    imageUrl: onBoardUI1,
    color: "primary.main",
    title: "Welcome",
    description: "lorem ipsum dolor sit amet",
  },
  {
    id: Utils.generateId(),
    imageUrl: onBoardUI2,
    color: "secondary.main",
    title: "Currency",
    description: "lorem ipsum dolor sit amet",
  },
];
