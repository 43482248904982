import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Stack,
  Button,
  Divider,
} from "@mui/material";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";

import { SelectBox } from "../../../component";
import { handleLoader, setToast } from "../../../store/reducer";
import errorsSetter from "../../../helpers/error-setter";
import ApiManager from "../../../services/api-manager";
import usePageTitle from "../../../hooks/use-page-title";

const SpBtaConfig = () => {
  const currencyID = useParams()?.id;
  usePageTitle(currencyID ? "Update" : "Add" + " Currency");
  const [event, setEvent] = useState({});
  const [apiData, setApiData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const dispatch = useDispatch();
  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));

  useEffect(() => {
    async function init() {
      try {
        dispatch(handleLoader(true));
        let { data } = await ApiManager(
          "get",
          `superAdmin/beach-tennis-app-event`,
        );
        const newData = { ...data };
        newData.events = newData.events.map((each) => ({
          label: each.name,
          value: each.event_id,
        }));
        setApiData(newData);
        setEvent(data?.setting?.value || "");
      } catch (error) {
        console.log("🚀 ~ file: sp-bta-config.js:34 ~ init ~ error:", error);
        showMessage("error", error?.response?.data?.error?.message);
      } finally {
        dispatch(handleLoader(false));
      }
    }
    init();
  }, []);

  const handleInputChange = (e) => {
    const { value } = e.target;
    setEvent(value);
  };

  const validator = () => {
    let flag = false;
    let error = {};
    if (!event) {
      error.event = "Event id required";
      flag = true;
    }
    setFormErrors((prev) => ({ ...prev, ...error }));
    return flag;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validator()) return;
    try {
      dispatch(handleLoader(true));
      await ApiManager("post", "superAdmin/beach-tennis-app-event", {
        event_id: event,
      });
      showMessage("success", `Save successfully.`);
    } catch (error) {
      if (error?.response?.status === 422) {
        setFormErrors(errorsSetter(error));
      } else {
        showMessage("error", error?.response?.data?.error?.message);
      }
    } finally {
      dispatch(handleLoader(false));
    }
  };

  const formInputs = () => {
    return (
      <Grid container columnSpacing={3} mt={2} rowSpacing={2}>
        <Grid item sm={4} xs={12}>
          <SelectBox
            label="Event"
            name="event"
            required
            error={formErrors?.event}
            value={event}
            onChange={handleInputChange}
            items={apiData?.events || []}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Box m={{ sm: 2 }}>
      <Box
        component={Paper}
        elevation={6}
        sx={{
          p: 2,
        }}
      >
        <Box
          component="form"
          autoCapitalize="off"
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <Typography variant="h5">Beach tennis app configuration</Typography>
          {formInputs()}
          <Box mt={2}>
            <Divider />
          </Box>
          <Stack direction="row" gap={2} mt={2}>
            <Button
              variant="contained"
              sx={{
                color: "white",
              }}
              type="submit"
            >
              Submit
            </Button>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default SpBtaConfig;
