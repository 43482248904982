import React from "react";
import { Line } from "react-chartjs-2";
import { ChartMarker } from "../../assets";
import { Box, CircularProgress } from "@mui/material";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

const options = {
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
    axis: "x",
  },
  plugins: {
    tooltip: {
      enabled: true,
    },
    legend: false,
  },
  scales: {
    y: {
      beginAtZero: true,
      grid: {
        drawOnChartArea: false,
        drawBorder: false,
      },
      ticks: {
        color: "#929292",
      },
    },
    x: {
      grid: {
        color: "#dddfe5",
        drawBorder: false,
        borderDash: [6],
        border: false,
      },
      ticks: {
        font: {
          family: "'Mulish', sans-serif",
          size: "16px",
        },
        color: "#929292",
      },
    },
  },
};

const ChartContainer = ({
  labels = [],
  values = [],
  title = "Title",
  loading,
}) => {
  const image = new Image();
  image.src = ChartMarker;

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: title,
        borderColor: "black",
        fill: false,
        data: values,
        lineTension: 0.4,
        pointBackgroundColor: "#5DC4FF",
        pointStyle: image,
      },
    ],
  };

  if (loading)
    return (
      <Box sx={{ textAlign: "center" }} pt={5}>
        <CircularProgress />
      </Box>
    );

  return <Line data={chartData} options={options} />;
};

export default ChartContainer;
