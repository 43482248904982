import EventIcon from "@mui/icons-material/Event";
import ElectricalServicesIcon from "@mui/icons-material/ElectricalServices";
import HomeIcon from "@mui/icons-material/Home";
import CategoryIcon from "@mui/icons-material/Category";
import Groups2Icon from "@mui/icons-material/Groups2";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import FestivalIcon from "@mui/icons-material/Festival";
import EmailIcon from "@mui/icons-material/Email";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import SettingsIcon from "@mui/icons-material/Settings";

export const adminRoutes = [
  {
    label: "Dashboard",
    url: "/",
    routeName: "/",
    icon: <HomeIcon />,
    children: [],
  },
  {
    label: "Merchandise",
    url: "/admin/merchandise",
    routeName: "/admin/merchandise",
    icon: <CategoryIcon />,
    children: [],
  },
  {
    label: "Organizer",
    url: "/admin/organizer",
    routeName: "/admin/organizer",
    icon: <Groups2Icon />,
    children: [],
  },
  {
    label: "Currency",
    url: "/admin/currency",
    routeName: "/admin/currency",
    icon: <LocalAtmIcon />,
    children: [],
  },
  {
    label: "Venue",
    url: "/admin/venue",
    routeName: "/admin/venue",
    icon: <FestivalIcon />,
    children: [],
  },
  {
    label: "Contact Queries",
    url: "/admin/contact",
    routeName: "/admin/contact",
    icon: <EmailIcon />,
    children: [],
  },
  {
    label: "Blogs",
    url: "/admin/blogs",
    routeName: "/admin/blogs",
    icon: <DynamicFeedIcon />,
    children: [],
  },
  {
    label: "Demo Requests",
    url: "/admin/demo-requests",
    routeName: "/admin/demo-requests",
    icon: <AvTimerIcon />,
    children: [],
  },
  {
    label: "Download Presentation Requests",
    url: "/admin/download-presentation-requests",
    routeName: "/admin/download-presentation-requests",
    icon: <SlideshowIcon />,
    children: [],
  },
  {
    label: "BTA configuration",
    url: "/admin/bta-configuration",
    routeName: "/admin/bta-configuration",
    icon: <SettingsIcon />,
    children: [],
  },
];

export const organizerRoutes = [
  // {
  //   label: "Dashboard",
  //   url: "/",
  //   routeName: "/",
  //   icon: <HomeIcon />,
  //   children: [],
  // },
  {
    label: "Events",
    url: "/events",
    routeName: "/events",
    icon: <EventIcon />,
    children: [],
  },
  // {
  //   label: "User Management",
  //   url: "/user-management",
  //   routeName: "/admin/organizations",
  //   icon: <ManageAccountsIcon />,
  //   children: [],
  // },

  // {
  //   label: "Customers",
  //   url: "/customers",
  //   routeName: "/customers",
  //   icon: <Groups3Icon />,
  //   children: [],
  // },
  {
    label: "About",
    url: "/about",
    routeName: "/",
    icon: <ElectricalServicesIcon />,
    children: [],
  },
];
