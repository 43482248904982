import React from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Tabs, Typography } from "@mui/material";
import { BarChart } from "@mui/x-charts";
import _ from "lodash";

import Utils from "../../../utils/utils";
import { ChartContainer } from "../../../component";

const ChartPanel = ({ data }) => {
  const [value, setValue] = React.useState("1");

  const salesValuesForGraph = data?.sales?.length
    ? _?.map(data?.sales, "amount")?.map((value) => Number(value))
    : [0];

  const tipValuesForGraph = data?.tips?.length
    ? _?.map(data?.tips, "tip")?.map((value) => Number(value))
    : [0];

  const topupsValuesForGraph = data?.top_ups?.length
    ? _?.map(data?.top_ups, "total")?.map((value) => Number(value))
    : [0];

  const dateForGraph = _?.map(data?.sales, "date");

  const onChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <TabContext value={value}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            maxWidth: { xs: 280, sm: "100%" },
          }}
        >
          <Tabs
            onChange={onChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            value={value}
          >
            {tabsData(
              data?.sales_count,
              data?.top_ups_count,
              data?.tips_count,
            ).map((item) => (
              <Tab
                key={item.value}
                label={
                  <React.Fragment>
                    <Typography
                      variant="subtitle2"
                      fontWeight="semiBold"
                      textTransform="none"
                    >
                      {item.label}
                    </Typography>
                    <Typography
                      variant="h5"
                      fontWeight="Bold"
                      textTransform="none"
                    >
                      {Utils.formatWithSuffix(item.amount)}
                    </Typography>
                  </React.Fragment>
                }
                value={item.value.toString()}
              />
            ))}
          </Tabs>
        </Box>
        <TabPanel value="1">
          <Box
            p={0.5}
            sx={{
              width: { xs: "90vw", sm: "100%" },
            }}
          >
            {/* <ChartContainer
              title="Sales"
              labels={data?.sales && data?.sales.map((item) => item?.date)}
              values={data?.sales && data?.sales.map((item) => item?.amount)}
            /> */}

            <BarChart
              xAxis={[{ scaleType: "band", data: dateForGraph }]}
              series={[
                {
                  data: salesValuesForGraph,
                  stack: "A",
                  label: "Sales",
                },
              ]}
              height={400}
              colors={["#0081C5"]}
              slotProps={{ legend: { hidden: true } }}
            />
          </Box>
        </TabPanel>
        <TabPanel value="2">
          <Box
            p={0.5}
            sx={{
              width: { xs: "90vw", sm: "100%" },
            }}
          >
            {/* <ChartContainer
              title="Top Ups"
              labels={data?.top_ups && data?.top_ups.map((item) => item?.date)}
              values={data?.top_ups && data?.top_ups.map((item) => item?.total)}
            /> */}
            <BarChart
              xAxis={[{ scaleType: "band", data: dateForGraph }]}
              series={[
                {
                  data: topupsValuesForGraph,
                  stack: "A",
                  label: "TopUps",
                },
              ]}
              height={400}
              colors={["#0081C5"]}
              slotProps={{ legend: { hidden: true } }}
            />
          </Box>
        </TabPanel>
        <TabPanel value="3">
          <Box
            p={0.5}
            sx={{
              width: { xs: "90vw", sm: "100%" },
            }}
          >
            {/* <ChartContainer
              title="Tips"
              labels={data?.tips && data?.tips.map((item) => item?.date)}
              values={data?.tips && data?.tips.map((item) => item?.tip)}
            /> */}

            <BarChart
              xAxis={[{ scaleType: "band", data: dateForGraph }]}
              series={[
                {
                  data: tipValuesForGraph,
                  stack: "A",
                  label: "Tips",
                },
              ]}
              colors={["#0081C5"]}
              slotProps={{ legend: { hidden: true } }}
              height={400}
            />
          </Box>
        </TabPanel>
      </TabContext>
    </div>
  );
};

const tabsData = (sales, top, tips) => {
  return [
    {
      label: "Sales",
      amount: sales || 0,
      value: 1,
    },
    {
      label: "TopUps",
      amount: top || 0,
      value: 2,
    },
    {
      label: "Tips",
      amount: tips || 0,
      value: 3,
    },
  ];
};

export default ChartPanel;
