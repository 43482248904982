import React, { useState, useImperativeHandle } from "react";
import { Button, IconButton, Menu, MenuList, MenuItem } from "@mui/material";

const DropDown = React.forwardRef(
  ({ btnText, children, icon, useIconBtn = false, menuStyles }, ref) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const closeMenu = () => {
      setAnchorEl(null);
    };

    const openMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };

    useImperativeHandle(ref, () => ({
      closeMenu,
    }));

    return (
      <div>
        {useIconBtn ? (
          <IconButton
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : "false"}
            onClick={openMenu}
          >
            {icon}
          </IconButton>
        ) : (
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : "false"}
            onClick={openMenu}
          >
            {btnText}
          </Button>
        )}
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={closeMenu}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          sx={menuStyles}
        >
          <MenuList dense>{children}</MenuList>
        </Menu>
      </div>
    );
  },
);

export default DropDown;
