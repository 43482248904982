import styled from "@emotion/styled";
import { TableRow } from "@mui/material";
import React from "react";

const StyledTableRow = ({ children }) => {
  const StyledTableRowMy = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    // "&:last-child td, &:last-child th": {
    //   border: 0,
    // },
  }));

  return <StyledTableRowMy>{children}</StyledTableRowMy>;
};

export default StyledTableRow;
