import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Stack,
  TableCell,
  IconButton,
  Tooltip,
  FormControlLabel,
  Switch,
  Grid,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";

import {
  ExportBtn,
  SearchInput,
  StartAndEndDateFilter,
  StyledTableCell,
  StyledTableRow,
  TablePagination,
  TableWrapper,
} from "../../../../component";
import ApiManager from "../../../../services/api-manager";
import usePageTitle from "../../../../hooks/use-page-title";
import { handleLoader, setToast } from "../../../../store/reducer";
import Utils from "../../../../utils/utils";
import moment from "moment";
import { Refresh } from "@mui/icons-material";

const VouchersReports = () => {
  usePageTitle("VouchersReports");
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loggedEvent, user } = useSelector((state) => state.storeReducer);
  const [searchCalled, setSearchCalled] = useState(false);
  const _isAdmin = user?.role === "super_admin" ? true : false;
  const _role = user?.role === "super_admin" ? "superAdmin" : "organizer";
  const { id } = useParams();
  const _symbol = loggedEvent?.currencySymbol;
  const [columns, setColumns] = useState([]);

  const [filtersState, setFiltersState] = useState({
    start_date: "",
    end_date: "",
    start_time: "",
    end_time: "",
    search: "",
  });

  const getData = async () => {
    try {
      setIsLoading(true);
      let { data } = await ApiManager(
        "get",
        `${_role}/vouchers-reports/${id}?start_date=${filtersState.start_date}&end_date=${filtersState.end_date}&search=${filtersState.search}&start_time=${filtersState?.start_time}&end_time=${filtersState?.end_time}&`,
      );
      setRecords(data?.vouchers);
      console.log("🚀 ~ file: vouchers-reports.js:33 ~ getData ~ data:", data);
      setColumns(data?.columns);
    } catch (error) {
      console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [id, filtersState.start_date, filtersState.end_date, searchCalled]);

  const onChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const onChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleStatus = async (e, item) => {
    let updateRecord = records?.data;
    let _index = updateRecord?.findIndex(
      (x) => x?.vendor_id === item?.vendor_id,
    );
    if (_index >= 0) {
      updateRecord[_index].status = e.target.checked;
      setRecords({ ...records, data: updateRecord });
    }
    let _fd = {
      ...item,
      status: e.target.checked === true ? "true" : "false",
      event: id,
      _method: "patch",
    };
    try {
      dispatch(handleLoader(true));
      await ApiManager("post", `organizer / vendors / ${item?.vendor_id}`, _fd);
    } catch (error) {
      dispatch(
        setToast({
          type: "error",
          message: error?.response?.data?.error?.message,
        }),
      );
    } finally {
      dispatch(handleLoader(false));
    }
  };

  const handleCsvUpload = async (event) => {
    if (!event.target.files.length) {
      return;
    }
    let ext = event.target.files[0].name.split(".");
    if (ext[1] !== "csv") {
      return dispatch(
        setToast({
          type: "error",
          message: "Please select only .csv extension file.",
        }),
      );
    }
    try {
      dispatch(handleLoader(true));
      let _fd = new FormData();
      _fd.append("file", event.target.files[0]);
      let { data } = await ApiManager(
        "post",
        `organizer/vendor/upload-csv?event_id=${id}`,
        _fd,
        { "Content-Type": "multipart/form-data" },
      );
      dispatch(setToast({ type: "success", message: data }));
      if (page === 1) {
        getData();
      } else {
        setPage(1);
      }
    } catch (error) {
      dispatch(
        setToast({
          type: "error",
          message: error?.response?.data?.error?.message,
        }),
      );
    } finally {
      dispatch(handleLoader(false));
    }
  };

  const sendEmail = async (id) => {
    try {
      dispatch(handleLoader(true));
      await ApiManager("get", `organizer/send-follow-up-email/${id}`);
      dispatch(setToast({ type: "success", message: "Email has been send." }));
    } catch (error) {
      dispatch(
        setToast({
          type: "error",
          message:
            error?.response?.data?.error?.message ||
            error?.response?.data?.error?.message,
        }),
      );
    } finally {
      dispatch(handleLoader(false));
    }
  };

  return (
    <div>
      <Box>
        <Typography variant="h4" color="initial" fontWeight={"bold"} m={2}>
          Vouchers Report{" "}
        </Typography>

        {/* <Stack direction={{ sm: "row", xs: "column" }} my={2}>
          <ExportBtn
            path={`reports - event - vouchers - csv ? event_id = ${ id } & `}
            data={columns}
          />
        </Stack> */}
        <Grid container spacing={2} mb={2}>
          <Grid item xl={2} lg={3} md={12} xs={12}>
            <Stack direction="row">
              <Button startIcon={<Refresh />} onClick={() => getData()}>
                Refresh
              </Button>
              <ExportBtn
                path={`reports-event-vouchers-csv?event_id=${id}&start_date=${filtersState.start_date}&end_date=${filtersState.end_date}&search=${filtersState.search}&start_time=${filtersState?.start_time}&end_time=${filtersState?.end_time}`}
                data={columns}
              />
            </Stack>
          </Grid>

          <Grid item xl={3.5} lg={4} md={12} xs={12}>
            <SearchInput
              value={filtersState.search}
              onChange={(e) =>
                setFiltersState((prev) => ({ ...prev, search: e.target.value }))
              }
              onRequestSearch={() => {
                if (page === 1) {
                  setSearchCalled(!searchCalled);
                } else {
                  setPage(1);
                }
              }}
              onRequestClear={() => {
                setFiltersState({ ...filtersState, search: "" });
                if (page === 1) {
                  setSearchCalled(!searchCalled);
                } else {
                  setPage(1);
                }
              }}
            />
          </Grid>

          <Grid item xl={6.5} lg={5} md={12} xs={12}>
            <StartAndEndDateFilter
              onFilterChange={(filterState) => {
                setPage(1);
                setFiltersState((prev) => ({ ...prev, ...filterState }));
              }}
              onRequestClear={() =>
                setFiltersState((prev) => ({
                  ...prev,
                  start_date: "",
                  end_date: "",
                  start_time: "",
                  end_time: "",
                }))
              }
            />
          </Grid>
        </Grid>

        <TableWrapper
          spanTd={thLabels.length}
          isLoading={isLoading}
          isContent={records?.length || false}
          thContent={
            <>
              {thLabels.map((v) => (
                <TableCell key={v}>{v}</TableCell>
              ))}
            </>
          }
        >
          {records?.map((item, i) => (
            <StyledTableRow key={i}>
              <StyledTableCell>{item?.ticket_type_info?.name}</StyledTableCell>
              <StyledTableCell>{item?.promotion_code}</StyledTableCell>
              <StyledTableCell>{item?.redeem_type}</StyledTableCell>
              <StyledTableCell>{item?.start_date}</StyledTableCell>
              <StyledTableCell>{item?.end_date}</StyledTableCell>
              <StyledTableCell>
                {item?.total_orders
                  ? Utils.formatWithSuffix(item?.total_orders)
                  : 0}
              </StyledTableCell>
              <StyledTableCell>
                {item?.total_quantities
                  ? Utils.formatWithSuffix(item?.total_quantities)
                  : 0}
              </StyledTableCell>
              <StyledTableCell>
                {" "}
                {moment(item?.created_at).format(
                  process.env.REACT_APP_DATE_FORMAT,
                )}
              </StyledTableCell>
              <StyledTableCell>
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <Tooltip title="View Details" placement="top">
                    <IconButton
                      color="info"
                      onClick={() =>
                        navigate(
                          `/ event / ${id} / management / lead - capture / vouchers - report / ${item?.voucher_id}`,
                        )
                      }
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableWrapper>
      </Box>
    </div>
  );
};

const thLabels = [
  "Ticket Type Name",
  "Promotion code",
  "Redeem type",
  "Start date",
  "End date",
  "Total orders",
  "Total quantities",
  "Created At",
  "Manage",
];

export default VouchersReports;
