import React, { useState } from "react";
import { StyledTableCell, StyledTableRow } from "../../../component";
import { IconButton, MenuItem, Stack, Tooltip } from "@mui/material";
import DropDown from "../../../component/dropdown/dropdown";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Utils from "../../../utils/utils";
import EditIcon from "@mui/icons-material/Edit";
import calculateTimeDiff from "../../../hooks/calculate-time-diff";

const TableDataRow = ({ item, isAdmin, event }) => {
  const { loggedEvent } = useSelector((state) => state.storeReducer);

  const _symbol = loggedEvent.currencySymbol;

  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <>
      <StyledTableRow>
        <StyledTableCell sticky>{item?.name}</StyledTableCell>
        <StyledTableCell>{item?.email}</StyledTableCell>
        <StyledTableCell>{item?.open_id}</StyledTableCell>
        <StyledTableCell>
          {calculateTimeDiff(item?.last_time_synced)}
        </StyledTableCell>
        <StyledTableCell>
          {Utils.formatDate(item?.last_time_synced) +
            " " +
            Utils.formatDateInHours(item?.last_time_synced)}
        </StyledTableCell>
        <StyledTableCell>{Utils.capitalize(item?.status)}</StyledTableCell>
        <StyledTableCell>
          {_symbol}
          {item?.total_cash_collected}
        </StyledTableCell>
        <StyledTableCell>
          {_symbol}
          {item?.total_credit_cash_collected}
        </StyledTableCell>
        <StyledTableCell>
          {_symbol}
          {item?.total_cash_reported}
        </StyledTableCell>
        <StyledTableCell>
          {_symbol}
          {item?.total_credit_cash_reported}
        </StyledTableCell>
        <StyledTableCell>
          <Stack direction="row" spacing={0.5} alignItems="center">
            {!isAdmin && (
              <Tooltip title="Edit" placement="top">
                <IconButton
                  color="info"
                  onClick={() =>
                    navigate(
                      `/event/${id}/management/user-management/update-user/${item.device_id}`,
                    )
                  }
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}

            <DropDown useIconBtn icon={<MoreVertIcon />}>
              <MenuItem
                onClick={() => {
                  navigate(
                    `/event/${id}/management/user-management/get-total-amount/${item?.device_id}?type=cash`,
                  );
                }}
              >
                Total cash collected
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate(
                    `/event/${id}/management/user-management/get-total-amount/${item?.device_id}?type=credit_card`,
                  );
                }}
              >
                Total credit card collected
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate(
                    `/event/${id}/management/user-management/get-reports-amount/${item?.device_id}`,
                  );
                }}
              >
                Total cash/credit card reported
              </MenuItem>
            </DropDown>
          </Stack>
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
};

export default TableDataRow;
