import React, { useEffect, useState } from "react";
import { Box, Typography, TableCell, Fab } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import {
  StyledTableCell,
  StyledTableRow,
  TableWrapper,
} from "../../../../component";
import ApiManager from "../../../../services/api-manager";
import usePageTitle from "../../../../hooks/use-page-title";
import Utils from "../../../../utils/utils";

const MerchandiseOrderDetail = () => {
  usePageTitle("Merchandise Details");
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState(null);
  const { id, orderID } = useParams();
  console.log(orderID);
  const navigate = useNavigate();
  const { user, loggedEvent } = useSelector((state) => state.storeReducer);
  const _role = user?.role === "super_admin" ? "superAdmin" : "organizer";
  const _symbol = loggedEvent.currencySymbol;

  useEffect(() => {
    getData();
  }, [id]);

  const getData = async (_search = "") => {
    try {
      setIsLoading(true);
      let { data } = await ApiManager(
        "get",
        `${_role}/merchandise-order/${orderID}`,
      );
      setRecords(data);
    } catch (error) {
      console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const renderRow = (item, index) => (
    <StyledTableRow key={item?.item_id}>
      <StyledTableCell>{index + 1}</StyledTableCell>
      <StyledTableCell>
        <img
          src={item?.product?.image_url}
          width="45px"
          height="45px"
          alt="product.png"
        />
      </StyledTableCell>
      <StyledTableCell>{item?.product?.name}</StyledTableCell>
      <StyledTableCell>{item?.product?.sku}</StyledTableCell>
      <StyledTableCell>
        {_symbol}
        {item?.price}
      </StyledTableCell>
      <StyledTableCell>{item?.quantity}</StyledTableCell>
      <StyledTableCell>
        {_symbol}
        {item?.product_tax}
      </StyledTableCell>
      <StyledTableCell>
        {_symbol}
        {item?.sub_total}
      </StyledTableCell>
    </StyledTableRow>
  );

  return (
    <div>
      <Box>
        <Fab
          size="small"
          onClick={() =>
            navigate(`/event/${id}/management/lead-capture/merchandise-report`)
          }
        >
          <ArrowBackIcon />
        </Fab>
        <Typography variant="h4" color="initial" fontWeight="bold" m={2}>
          Merchandise Order Details
        </Typography>

        <TableWrapper
          spanTd={thLabels.length}
          isLoading={isLoading}
          isContent={records?.items?.length || false}
          thContent={
            <>
              {thLabels.map((v) => (
                <TableCell key={v}>{v}</TableCell>
              ))}
            </>
          }
        >
          {records?.items.length &&
            records?.items?.map((item, i) => renderRow(item, i))}
        </TableWrapper>
      </Box>
    </div>
  );
};

const thLabels = [
  "S.No",
  "Image",
  "Name",
  "Sku",
  "Price",
  "Quantity",
  "Tax",
  "Amount",
];

export default MerchandiseOrderDetail;
